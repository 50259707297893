import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItems } from '@core/menu';
import { AbsComponentWithEnvironmentData } from '@base/abs';

@Component({
  selector: 'ec-web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebFooterComponent extends AbsComponentWithEnvironmentData {
  @Input() menuItems!: MenuItems | null;
}
