import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { noDataValue } from '@utils/helpers/date.helper';

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  transform(value: any, hasTime = false, todayAsString = false): string {
    if (!value) {
      return noDataValue;
    }
    const format = hasTime ? 'MMM DD, yyyy hh:mm A' : 'MMM DD, yyyy';

    if (moment(value, 'MM-DD-YYYY', true).isValid()) {
      return moment(value, 'MM-DD-YYYY').format(format);
    }

    if (hasTime) {
      return moment.utc(value).local().format(format);
    }

    if (todayAsString) {
      if (moment(value, 'YYYY-MM-DD').diff(new Date(), 'day') === 0) {
        return 'Today';
      }
    }

    return moment(value, 'YYYY-MM-DD').format(format);
  }
}
