import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LearnRoutingModule } from './learn-routing.module';
import { LearnOverviewComponent, LearnPage } from './pages';
import { ContentSectionsModule } from '@widgets/content-sections';
import { RecentUpdatesWModule } from '@widgets/recent-updates';
import {
  LearnRecentUpdatesComponent,
  LearnFeaturedStoriesComponent,
  LearnCategoriesWrapperComponent,
  LearnCategoryItemsComponent,
} from './components';
import { ContentWModule } from '@widgets/content';
import { GridModule } from '@widgets/grid';
import { SpinnerModule } from '@widgets/spinner';
import { PageModule } from '@widgets/page';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DividersModule } from '@widgets/dividers';
import { CallToActionModule } from '@widgets/call-to-action';
import { RestrictionMessagesModule } from '@widgets/restriction-messages';
import { HomeModule } from '../home';

@NgModule({
  declarations: [
    LearnPage,
    LearnRecentUpdatesComponent,
    LearnFeaturedStoriesComponent,
    LearnCategoriesWrapperComponent,
    LearnCategoryItemsComponent,
    LearnOverviewComponent,
  ],
  imports: [
    CommonModule,
    LearnRoutingModule,
    ContentSectionsModule,
    RecentUpdatesWModule,
    ContentWModule,
    GridModule,
    SpinnerModule,
    PageModule,
    InfiniteScrollModule,
    DividersModule,
    CallToActionModule,
    RestrictionMessagesModule,
    HomeModule,
  ],
})
export class LearnModule {}
