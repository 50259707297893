import { Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { ecCreateLogger } from '@utils/logger';
import { ColorPalette, Sizes } from '@core/branding';
import { IStylingDirective } from '@base/interfaces';

const log = ecCreateLogger('core:branding::buttons');

@Directive({
  selector: 'button[ecButton], a[ecButton]',
})
export class ButtonDirective implements OnInit, IStylingDirective {
  @HostBinding('class') classes = 'ec-button';

  @Input() color: ColorPalette = 'primary';

  @Input() size: Sizes = 'md';

  @Input() isLoadingBtn = false;

  logger = { log };

  constructor(private element: ElementRef<HTMLButtonElement>) {}

  ngOnInit(): void {
    this.generateStyles();
  }

  generateStyles(): void {
    let classNames: string[] = ['btn'];

    const colorsStyles: string[] = this.generateColors();

    const sizeStyles: string[] = this.generateSizes();

    classNames = [...classNames, ...colorsStyles, ...sizeStyles];

    this.logger.log(`${ButtonDirective.name} - classNames = ${classNames}`);
    this.logger.log(this.element.nativeElement);

    this.element.nativeElement.classList.add(...classNames);
  }

  private generateSizes(): string[] {
    this.logger.log(`${ButtonDirective.name} - size = ${this.size}`);

    const classNames = [];
    switch (this.size) {
      case 'std':
        classNames.push('btn-std');
        break;
      case 'lg':
        classNames.push('btn-lg');
        break;
      case 'sm':
        classNames.push('btn-sm');
        break;
    }
    return classNames;
  }

  private generateColors(): string[] {
    this.logger.log(`${ButtonDirective.name} - color = ${this.color}`);

    const classNames = [];
    switch (this.color) {
      case 'primary':
        classNames.push('btn-primary');
        break;
      case 'accent':
        classNames.push('btn-accent');
        break;
      case 'white':
        classNames.push('btn-white');
        break;
    }
    return classNames;
  }
}
