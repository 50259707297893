import { WithID } from '@base/interfaces';
import { IAttachedFile } from '@services/learn';

interface IContentBody extends WithID {
  attach_file: IAttachedFile;
  file_name: string;
  body: string;
}

export { IContentBody };
