<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <ng-container *ngIf="(responsiveService.isTouch$ | async) === false; else mobileCarusel">
    <div class="carousel-indicators">
      <button
        *ngFor="let item of items; let i = index"
        class="carousel-indicator"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        [attr.data-bs-slide-to]="i"
        [attr.aria-label]="item.title"
        [ngClass]="{ active: item.active }"
      ></button>
    </div>
    <div class="carousel-inner">
      <div
        class="carousel-item"
        *ngFor="let item of items; let i = index"
        [attr.data-slide]="i"
        [ngClass]="{ active: item.active }"
      >
        <img
          [src]="item.image"
          loading="lazy"
          class="carousel-background d-block w-100"
          [alt]="item.title"
        />

        <div class="carousel-overlay"></div>

        <div class="carousel-caption">
          <h1 class="lg">{{ item.title }}</h1>
          <ec-text class="mb-4 mb-xl-5" size="xl" color="white" *ngIf="item.sub_title">
            {{ item.sub_title }}
          </ec-text>
          <div *ngIf="isVideo(item)" class="mb-4 mb-xl-5">
            <ec-watch-video [content]="item"></ec-watch-video>
          </div>
          <div class="actions">
            <button
              (click)="handleAction(item.primary_button)"
              *ngIf="item.primary_button"
              ec-button
              color="primary"
              size="lg"
            >
              {{ item.primary_button.name }}
            </button>
            <button
              (click)="handleAction(item.secondary_button)"
              *ngIf="item.secondary_button"
              ec-button
              color="white"
              size="lg"
            >
              {{ item.secondary_button.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control carousel-control-prev"
      type="button"
      [attr.data-bs-slide]="arrowAction.prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control carousel-control-next"
      type="button"
      [attr.data-bs-slide]="arrowAction.next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </ng-container>
  <ng-template #mobileCarusel>
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let item of items; let i = index">
        <ng-template carouselSlide [id]="'slide' + i">
          <div class="carousel-item mobile-carousel">
            <img
              [src]="item.image"
              loading="lazy"
              class="carousel-background d-block w-100"
              [alt]="item.title"
            />

            <div class="carousel-overlay"></div>

            <div class="carousel-caption">
              <h1 class="lg">{{ item.title }}</h1>
              <ec-text class="mb-4 mb-xl-5" size="xl" color="white" *ngIf="item.sub_title">
                {{ item.sub_title }}
              </ec-text>
              <div *ngIf="isVideo(item)" class="mb-4 mb-xl-5">
                <ec-watch-video [content]="item"></ec-watch-video>
              </div>
              <div class="actions">
                <button
                  (click)="handleAction(item.primary_button)"
                  *ngIf="item.primary_button"
                  ec-button
                  color="primary"
                  size="lg"
                >
                  {{ item.primary_button.name }}
                </button>
                <button
                  (click)="handleAction(item.secondary_button)"
                  *ngIf="item.secondary_button"
                  ec-button
                  color="white"
                  size="lg"
                >
                  {{ item.secondary_button.name }}
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </ng-template>
</div>
