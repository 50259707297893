<div class="modal-wrapper">
  <div ec-modal-header heading="Complete Registration"></div>
  <div class="modal-body">
    <ec-text size="lg" touchSize="lg" color="primary">
      To complete your registration select continue.
    </ec-text>
  </div>
  <div class="modal-footer">
    <button ec-button color="primary" (click)="activeModal.close()">Continue</button>
  </div>
</div>
