import { BaseEmailDto } from './base-email.dto';

class LoginCredentialsDto extends BaseEmailDto implements API.ClientLogin {
  password: string;

  constructor({ email, password }: LoginCredentialsDto) {
    super({ email });

    this.password = password;
  }
}

export { LoginCredentialsDto };
