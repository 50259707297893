import { WithVisibility } from '@base/interfaces';
import { ContentVisibility } from '@services/learn';
import { AccreditationStatus, IAccount } from '@services/account';

class PermissionsUtility {
  public static isPublic({ visibility }: Pick<WithVisibility, 'visibility'>): boolean {
    return visibility === ContentVisibility.public;
  }

  public static isRestricted({
    visibility,
    accreditation_status,
    accreditation_expired,
  }: Pick<WithVisibility, 'visibility'> &
    Pick<IAccount, 'accreditation_status'> &
    Pick<IAccount, 'accreditation_expired'>): boolean {
    return (
      (visibility === ContentVisibility.accredited &&
        accreditation_status !== AccreditationStatus.accredited) ||
      (visibility === ContentVisibility.accredited && accreditation_expired === true)
    );
  }

  public static isRestrictedByVisibility({ visibility }: Pick<WithVisibility, 'visibility'>) {
    return visibility === ContentVisibility.accredited;
  }
}

export { PermissionsUtility };
