import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CategoriesSectionComponent,
  CategoryCardContentComponent,
  CategoriesMobileComponent,
  CategoriesViewWrapperComponent,
  CategoryCardOverlayComponent,
} from './components';
import { CategoryPropPipe } from './pipes';
import { CardsModule } from '../cards';
import { ContentSectionsModule } from '../content-sections';
import { TypographyModule } from '@widgets/typography';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SpinnerModule } from '@widgets/spinner';
import { MobileModule } from '@widgets/mobile';
import { RestrictionMessagesModule } from '@widgets/restriction-messages';
import { ContentWModule } from '@widgets/content';
import { DropdownModule } from '@widgets/dropdown/dropdown.module';

@NgModule({
  declarations: [
    CategoriesSectionComponent,
    CategoryPropPipe,
    CategoryCardContentComponent,
    CategoriesMobileComponent,
    CategoriesViewWrapperComponent,
    CategoryCardOverlayComponent,
  ],
  imports: [
    CommonModule,
    CardsModule,
    ContentSectionsModule,
    TypographyModule,
    InfiniteScrollModule,
    SpinnerModule,
    MobileModule,
    RestrictionMessagesModule,
    ContentWModule,
    DropdownModule,
  ],
  exports: [CategoriesSectionComponent, CategoriesViewWrapperComponent],
})
export class CategoriesWModule {}
