<div class="navigation-items">
  <a
    ec-navigation-item
    *ngFor="let menuItem of menuItems; trackBy: trackMenus"
    [menuItem]="$any(menuItem)"
  >
    <ng-container *ngIf="!menuItem.externalLink" routerLabel>
      {{ menuItem.label }}
    </ng-container>
    <ng-container *ngIf="menuItem.externalLink" externalRouterLabel>
      {{ menuItem.label }}
    </ng-container>
  </a>
</div>
