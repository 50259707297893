import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMenuItem } from '@core/menu';

@Component({
  selector: 'a[ec-navigation-item]',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent {
  @Input() menuItem!: Pick<IMenuItem, 'route' | 'child' | 'externalLink'>;
}
