import { Directive, ElementRef } from '@angular/core';
import { CarouselService } from '../services';
import { ArrowAction } from '@widgets/carousel/enums';

@Directive({
  selector: '.carousel-control',
})
export class CarouselArrowDirective {
  constructor(
    public readonly element: ElementRef<HTMLButtonElement>,
    private readonly carouselService: CarouselService,
  ) {
    this.element.nativeElement.addEventListener('click', this.handleClick.bind(this));
  }

  private handleClick(): void {
    const action: ArrowAction = this.getAttribute();
    this.carouselService.navigateTo$.next(action);
  }

  private getAttribute(): ArrowAction {
    return (
      (this.element.nativeElement.getAttribute('data-bs-slide') as ArrowAction) ?? ArrowAction.next
    );
  }
}
