import { Component, Input } from '@angular/core';

@Component({
  selector: 'ec-footer-partner-box',
  templateUrl: './footer-partner-box.component.html',
  styleUrls: ['./footer-partner-box.component.scss'],
})
export class FooterPartnerBoxComponent {
  @Input() img!: string;

  @Input() alt!: string;

  @Input() linkTo!: string;
}
