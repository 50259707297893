import { RegisterFormDto } from './register-form.dto';

class InvitationalRegisterDto extends RegisterFormDto {
  token: string;

  constructor({ token, ...registerDto }: InvitationalRegisterDto) {
    super({ ...registerDto });
    this.token = token;
  }
}

export { InvitationalRegisterDto };
