import { fromEvent, Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export const media$ = (query: string): Observable<boolean> => {
  const mediaQuery = window.matchMedia(query);
  return fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
    startWith(mediaQuery),
    map((list: MediaQueryList) => list.matches),
  );
};
