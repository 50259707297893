import { WithContentVisibility } from '@base/interfaces';
import { ContentType } from '@services/learn';

class ContentTypeUtility {
  public static isArticle({ type }: Pick<WithContentVisibility, 'type'>): boolean {
    return type === ContentType.article;
  }

  public static isPodcast({ type }: Pick<WithContentVisibility, 'type'>): boolean {
    return type === ContentType.podcast;
  }

  public static isVideo({ type }: Pick<WithContentVisibility, 'type'>): boolean {
    return type === ContentType.video;
  }

  public static isWebinar({ type }: Pick<WithContentVisibility, 'type'>): boolean {
    return type === ContentType.webinar;
  }

  public static isPlayable({ type }: Pick<WithContentVisibility, 'type'>): boolean {
    const playableTypes: ContentType[] = [
      ContentType.webinar,
      ContentType.podcast,
      ContentType.video,
    ];
    return playableTypes.includes(type);
  }
}

export { ContentTypeUtility };
