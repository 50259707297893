import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { SliderService } from '@services/slider';
import { ID } from '@datorama/akita';
import { ArrowAction } from '../enums';
import { environment } from '@env/environment';

@Injectable()
export class CarouselService {
  public slideTo$: Subject<ID> = new Subject<ID>();

  public slideAction$: Subject<unknown> = new Subject();

  public navigateTo$: Subject<ArrowAction> = new Subject<ArrowAction>();

  private reset$: Subject<null> = new Subject<null>();

  constructor(private readonly sliderService: SliderService) {}

  get nextSlideDelay(): number {
    return environment.nextSlideDelay;
  }

  public changeActiveItem(activeIndex: number): void {
    this.sliderService.changeActiveItem(activeIndex);
  }

  public resetTimer(): void {
    this.reset$.next(null);
  }

  public startTimer(): void {
    this.reset$
      .pipe(
        startWith(0),
        switchMap(() => timer(0, 1000)),
      )
      .subscribe((second: number) => {
        if (second === this.nextSlideDelay) {
          this.slideAction$.next('');
        }
      });
  }
}
