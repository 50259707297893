<div
  class="content-overlay"
  [ngClass]="{
    'content-overlay-restricted': isRestricted({ visibility: state.visibility })
  }"
>
  <!--  <ec-play-icon-->
  <!--    (click)="navigate.emit()"-->
  <!--    [icon]="iconSource"-->
  <!--    *ngIf="isPlayable({ type: type.type })"-->
  <!--  ></ec-play-icon>-->

  <div *ngIf="isRestricted({ visibility: state.visibility })">
    <ec-popover [popoverTemplate]="popoverTemplate" [popoverToggle]="popoverToggle">
      <ng-template #popoverToggle>
        <img
          *ngIf="isRestricted"
          class="icon-lock content-locked"
          src="assets/icons/rounded_lock.svg"
          alt="Restricted"
        />
      </ng-template>

      <ng-template #popoverTemplate>
        <div ec-unlock-content></div>
      </ng-template>
    </ec-popover>

    <!--    <img-->
    <!--      #popOver="ngbPopover"-->
    <!--      placement="bottom"-->
    <!--      triggers="manual"-->
    <!--      container="body"-->
    <!--      (hidden)="handleOnHidden()"-->
    <!--      (shown)="handleOnShown()"-->
    <!--      [ngbPopover]="unlockPopover"-->
    <!--      popoverClass="z-index-10"-->
    <!--      [src]="-->
    <!--        (lockElement.hover$ | async)-->
    <!--          ? 'assets/icons/lock_active.svg'-->
    <!--          : 'assets/icons/rounded_lock.svg'-->
    <!--      "-->
    <!--      alt="Restricted"-->
    <!--      class="icon-lock content-locked"-->
    <!--      (click)="navigate.emit()"-->
    <!--      #lockElement="lockElement"-->
    <!--    />-->
  </div>
  <ng-content></ng-content>
</div>

<!--<ng-template #unlockPopover>-->
<!--  <div ec-unlock-content></div>-->
<!--</ng-template>-->

<!--<popover-content-->
<!--  #myPopover-->
<!--  [animation]="true"-->
<!--  placement="bottom"-->
<!--  parentClass="content-overlay"-->
<!--  [closeOnMouseOutside]="false"-->
<!--  [appendToBody]="true"-->
<!--  [closeOnClickOutside]="true"-->
<!--&gt;-->
<!--  <div ec-unlock-content></div>-->
<!--</popover-content>-->
