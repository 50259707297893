import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ColorPalette, Sizes } from '@core/branding';
import { Icon } from '@widgets/icons';

@Component({
  selector: 'div[ec-icon-button]',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  @HostBinding('class') classes = 'ec-icon-button-wrapper';

  @Input() color: ColorPalette = 'primary';

  @Input() size: Sizes = 'md';

  @Input() icon!: Icon;

  @Input() disabled!: boolean;

  @Input() iconColor: 'blue' | 'green' | 'red' | 'gray' | 'white' = 'blue';
}
