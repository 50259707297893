import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Sizes } from '@core/branding';

@Component({
  selector: 'ec-logo',
  template: ' <img [src]="logoSrc" alt="Logo" [class]="size" /> ',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Input() logoSrc!: string;

  @Input() size: Sizes | 'full'  = 'md';
}
