import { Injectable } from '@angular/core';
import {
  BackendService,
  BaseApiService,
  RequestFacadeModel,
  RequestModel,
  RequestType,
} from '@core/backend';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import {
  EProjectType,
  IMarketProjectList,
} from '../../market/interfaces/marketplace-project.interface';
import { IAccreditationInfo, LandingPageItem } from '../interfaces/landing.interface';
import { catchError, filter, map, take } from 'rxjs/operators';

interface Link {
  link: string;

  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class LandingService extends BaseApiService {
  private accreditationLink$: BehaviorSubject<string> = new BehaviorSubject('');

  private accreditationLinkIsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private constructor(backendService: BackendService) {
    super(backendService, 'client/company-info/landing-page');
  }

  public getAccreditationLink$(): Observable<string> {
    return this.accreditationLink$.asObservable();
  }

  public getLandingInfo(): Observable<LandingPageItem[]> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl(),
    });

    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });

    return this.backendService.send(requestFacade);
  }

  public getRecentInvestProjects(): Observable<IMarketProjectList[]> {
    const request: RequestModel = new RequestModel({
      url: 'client/marketplace/projects/recent_projects/',
    });

    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });

    return this.backendService
      .send<IMarketProjectList[]>(requestFacade)
      .pipe(tap((projects: IMarketProjectList[]) => this.updateRecentProjectFields(projects)));
  }

  fetchAccreditationLink(): void {
    if (this.accreditationLinkIsLoading$.value) {
      return;
    }
    this.getAccreditationLink$()
      .pipe(
        filter(link => !link),
        take(1),
        tap(() => {
          this.requestAccreditationFormLink().subscribe();
        }),
      )
      .subscribe();
  }

  public requestAccreditationFormLink(): Observable<Link> {
    this.accreditationLinkIsLoading$.next(true);
    const request: RequestModel = new RequestModel<null>({
      url: 'client/account/accreditation-form-request/',
      skipNotify: true,
      shouldIndicateLoader: false,
      requestBody: null,
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.post,
      request,
    });
    return this.backendService.send<Link>(requestFacade).pipe(
      tap(link => {
        this.accreditationLinkIsLoading$.next(false);
        this.accreditationLink$.next(link.link);
        return link;
      }),
      catchError(error => {
        this.accreditationLinkIsLoading$.next(false);
        this.accreditationLink$.next('');
        throw error;
      }),
    );
  }

  public changeAccreditationInfo(
    itemToChange: Partial<IAccreditationInfo>,
  ): Observable<IAccreditationInfo> {
    const request: RequestModel<any> = new RequestModel({
      url: 'client/account/user-notified/',
      skipNotify: true,
      shouldIndicateLoader: false,
      requestBody: itemToChange,
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.patch,
      request,
    });
    return this.backendService.send<IAccreditationInfo>(requestFacade).pipe(
      map(res => res),
      catchError(error => {
        throw error;
      }),
    );
  }

  public getAccreditationInfo(): Observable<IAccreditationInfo> {
    const request: RequestModel = new RequestModel<null>({
      url: 'client/account/user-notified/',
      skipNotify: true,
      shouldIndicateLoader: false,
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.backendService.send<IAccreditationInfo>(requestFacade);
  }

  private updateRecentProjectFields(projects: IMarketProjectList[]): IMarketProjectList[] {
    projects.forEach(project => {
      project.sold_nma_percentage = '100';
      project.available_nma = '0';
      project.available_units = '0';
      if (project.project_type === EProjectType.M) {
        project.price_per_nma = project.price_per_unit;
      }
    });

    return projects;
  }
}
