<div class="modal-wrapper">
  <div ec-modal-header></div>
  <div class="modal-body pb-0">
    <ec-success-notification
      heading="Accreditation Form Submitted"
      description="Great work! The team approval step is next."
    >
      <button ec-button (click)="continue.emit()" type="button">Continue</button>
    </ec-success-notification>
  </div>
</div>
