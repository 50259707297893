import { AfterViewInit, Component } from '@angular/core';
import { AbsComponentWithLayoutChange } from '@base/abs';
import { WithSeveralChildSections } from '@base/interfaces';
import { IContentSectionProps, SectionContentType } from '@widgets/content-sections';
import { Observable, take, tap } from 'rxjs';
import { BackgroundValues, LayoutService } from '@core/layout';
import { LearnLogicService } from '@services/learn';
import { ID } from '@datorama/akita';
import { AuthService } from '@services/auth';
import { ResponsiveService } from '../../../../services/responsive';

@Component({
  selector: 'ec-learn-overview',
  templateUrl: './learn-overview.component.html',
  styleUrls: ['./learn-overview.component.scss'],
})
export class LearnOverviewComponent
  extends AbsComponentWithLayoutChange
  implements WithSeveralChildSections, AfterViewInit
{
  public isAuthorized$: Observable<boolean> = this.authService.isAuthorized$;

  sections$: Observable<IContentSectionProps[]> = this.learnLogicService.learnPageSections$();

  sectionContentType: typeof SectionContentType = SectionContentType;

  constructor(
    layoutService: LayoutService,
    private readonly learnLogicService: LearnLogicService,
    private readonly authService: AuthService,
    private readonly responsiveService: ResponsiveService,
  ) {
    super(layoutService, {
      background: BackgroundValues.grey,
      elements: { breadcrumbs: true, wrapper: true, footer: true },
    });
  }

  trackBySection(index: number, section: IContentSectionProps): ID {
    return section.label;
  }

  ngAfterViewInit(): void {
    this.responsiveService.isTouch$.pipe(
      take(1),
      tap(isTouch => (!isTouch ? this.setSnippet() : null)),
    ).subscribe();
  }
}
