import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MenuItems } from '@core/menu';

@Component({
  selector: 'div[ec-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @HostBinding('class') classes = 'layout-header ec-header';

  @Input() menuItems!: MenuItems | null;

  @Input() isAuthorized: boolean | null = false;

  @Input() notCompletedRegistration: boolean | null = false;

  @Input() fullName!: string | null;

  @Output() register: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() login: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() logout: EventEmitter<unknown> = new EventEmitter<unknown>();
}
