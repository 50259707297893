import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { AuthService } from '@services/auth';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ApiConfig } from '@widgets/table/shared/api-config';

const excludes = [
  ApiConfig.tokenRefresh,
  ApiConfig.login,
  ApiConfig.forgotPassword,
  ApiConfig.changePassword,
];

const UNAUTHORIZED = 401;

// const FORBIDDEN = 403;

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  static refreshTokenInProgress = false;

  static tokenRefreshedSource = new Subject<void>();

  static tokenRefreshed$ = RefreshTokenInterceptor.tokenRefreshedSource.asObservable();

  constructor(private readonly authService: AuthService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const isAuthorized = this.authService.isAuthorized();

    if (excludes.includes(request?.url?.replace(environment.apiUrl, ''))) {
      return next.handle(request);
    }

    if (!isAuthorized) {
      return next.handle(request);
    }

    // eslint-disable-next-line no-param-reassign
    request = this.addAuthHeader(request);

    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === UNAUTHORIZED) {
          return this.refreshToken().pipe(
            switchMap(() => {
              // eslint-disable-next-line no-param-reassign
              request = this.addAuthHeader(request);
              return next.handle(request);
            }),
            catchError(err => {
              this.authService.killSession();
              return throwError(err);
            }),
          );
          // } else if (error.status === FORBIDDEN) {
          //   this.authService.logout();
        }
        return throwError(error);
      }),
    );
  }

  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const { access } = this.authService.getAccess();
    if (access && request.url.includes('/api') && !request.url.includes(ApiConfig.tokenRefresh)) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${access}`,
        },
      });
    }
    return request;
  }

  refreshToken() {
    if (RefreshTokenInterceptor.refreshTokenInProgress) {
      return new Observable(observer => {
        RefreshTokenInterceptor.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      RefreshTokenInterceptor.refreshTokenInProgress = true;
      return this.authService.refreshAccessToken().pipe(
        tap(() => {
          RefreshTokenInterceptor.refreshTokenInProgress = false;
          RefreshTokenInterceptor.tokenRefreshedSource.next();
        }),
        catchError((error: HttpErrorResponse) => throwError(() => error)),
      );
    }
  }
}
