import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconWrapperComponent } from '@widgets/icons/components';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { PlayIconComponent } from './components/play-icon/play-icon.component';
import { LogoComponent } from './components/logo/logo.component';
import { CoverWrapperComponent } from './components/cover-wrapper/cover-wrapper.component';
import { IconFrameComponent } from './components/icon-frame/icon-frame.component';
import { InvestmentStatusIconsComponent } from './components/investment-status-icons/investment-status-icons.component';

@NgModule({
  declarations: [
    InvestmentStatusIconsComponent,
    IconWrapperComponent,
    PlayIconComponent,
    LogoComponent,
    CoverWrapperComponent,
    IconFrameComponent,
  ],
  imports: [CommonModule, InlineSVGModule],
  exports: [
    IconWrapperComponent,
    PlayIconComponent,
    LogoComponent,
    CoverWrapperComponent,
    IconFrameComponent,
    InvestmentStatusIconsComponent,
  ],
})
export class IconsModule {}
