import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'statistic-card-wrapper',
  template: `
    <div class="row margin-size-{{ marginBottomSize }}">
      <div class="col-12" [ngClass]="{'mb-3': !onlyFirst}">
        <ng-content select="statistic-card .first"></ng-content>
      </div>
      <div class="col-12">
        <ng-content select="statistic-card .second"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./statistic-card-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StatisticCardWrapperComponent {
  @Input() onlyFirst: boolean;

  @Input() marginBottomSize: 'md' | 'lg' | 'none' = 'lg';
}
