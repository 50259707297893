import { BasePaginationDTO } from '../../classes';
import { IPaginatedResponse, WithElementsLimitation, WithGetData } from '../../interfaces';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { IContent } from '@services/learn';
import { ContentService } from '@services/content';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ecCreateLogger } from '@utils/logger';
import { limitItems } from '@utils/data-display';
import { AbsComponentWithLoading } from './abs-component-with-loading';
import { IBadgeCalculationResponse, IBadgeProps } from '@widgets/content-sections';
import { guid, ID } from '@datorama/akita';
import { changeLoadingState, loadingStatePipe } from '@utils/loading';

const log = ecCreateLogger('core:base::abs-content');

type ComponentWithRecentUpdatesProps = WithElementsLimitation;

abstract class AbsComponentWithContent<DTO extends BasePaginationDTO>
  extends AbsComponentWithLoading
  implements WithGetData
{
  logger = { log };

  public id: ID = guid();

  public contentSub$!: Subscription;

  public badgePropsBeh$: BehaviorSubject<IBadgeProps | undefined> = new BehaviorSubject<
    IBadgeProps | undefined
  >(undefined);

  public badgeProps$: Observable<IBadgeProps | undefined> = this.badgePropsBeh$.asObservable();

  public contentBeh$: BehaviorSubject<IContent[]> = new BehaviorSubject<IContent[]>([]);

  public content$: Observable<IContent[]> = this.contentBeh$.asObservable();

  public recentUpdatesDTO$!: BehaviorSubject<DTO>;

  protected constructor(
    protected contentService: ContentService,
    paginationDTO: DTO,
    protected props: ComponentWithRecentUpdatesProps,
  ) {
    super();
    this.recentUpdatesDTO$ = new BehaviorSubject<DTO>(paginationDTO);
    this.logger.log(`Pagination DTO - ${JSON.stringify(paginationDTO)}`);
    this.logger.log(`Component Props - ${JSON.stringify(this.props)}`);
  }

  public getData(): void {
    this.contentSub$?.unsubscribe();
    if (!this.loadingState) {
      changeLoadingState(this);
    }
    this.contentSub$ = this.contentService
      .getAll(this.recentUpdatesDTO$.getValue())
      .pipe(
        loadingStatePipe(() => changeLoadingState(this)),
        // hasNextPipe(({ next }) =>
        //   changeHasNextValue<AbsComponentWithContent<DTO>>(this, { next })
        // ),
        map(
          ({ results, badge, next }: IPaginatedResponse<IContent> & IBadgeCalculationResponse) => {
            this.mapBadge(badge);
            this.hasNext = !!next ?? false;
            this.next = next;
            return limitItems<IContent>({
              results,
              showCount: this.props.showCount,
            });
          },
        ),
        tap((data: IContent[]) => this.contentBeh$.next(data)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  handleNextData(): void {
    if (this.next) {
      if (!this.loadingState) {
        changeLoadingState(this);
      }

      this.contentService
        .load<IPaginatedResponse<IContent> & IBadgeCalculationResponse>(this.next)
        .pipe(
          loadingStatePipe(() => changeLoadingState(this)),
          map(
            ({
              results,
              badge,
              next,
            }: IPaginatedResponse<IContent> & IBadgeCalculationResponse) => {
              this.hasNext = !!next ?? false;
              this.next = next;
              this.mapBadge(badge);
              return limitItems<IContent>({
                results,
                showCount: this.props.showCount,
              });
            },
          ),
          tap((data: IContent[]) => {
            const content: IContent[] = this.contentBeh$.getValue();
            this.contentBeh$.next([...content, ...data]);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    }
  }

  private mapBadge(badge: IBadgeProps): void {
    if (badge) {
      this.badgePropsBeh$.next({ ...badge, id: this.id });
    }
  }
}

export { AbsComponentWithContent };
