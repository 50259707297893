import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IAccount } from '@services/account/interfaces';
import { typeWrapper } from '@utils/types';

export const createInitialState = (): IAccount => ({
  id: typeWrapper<number>(undefined),
  accreditation_status: typeWrapper(undefined),
  app_status: typeWrapper(undefined),
  primary_phone: typeWrapper(undefined),
  primary_phone_format: typeWrapper(undefined),
  secondary_phone_format: typeWrapper(undefined),
  has_authenticator_app: typeWrapper(false),
  has_recovery_codes: typeWrapper(false),
  has_sms_device: typeWrapper(false),
  is_blocked: typeWrapper(false),
  is_login_verified: typeWrapper(false),
  is_two_factor_enabled: typeWrapper(false),
  profile_photo_user: typeWrapper(undefined)
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'account', resettable: true })
export class AccountStore extends Store<IAccount> {
  constructor() {
    super(createInitialState());
  }
}
