import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutService } from '@core/layout';
import { MenuItems } from '../../../menu';
import { ResponsiveService } from '@services/responsive';

@Component({
  selector: 'ec-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public menuItems$: Observable<MenuItems> = this.layoutService.menuItems$;

  public isMobile$: Observable<boolean> = this.responsiveService.isMobile$;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly layoutService: LayoutService,
  ) {}
}
