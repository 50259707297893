import { Injectable } from '@angular/core';
import { ID, Query } from '@datorama/akita';
import { DeviceStore } from './device.store';
import { IDeviceState } from '@services/device-info/interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeviceQuery extends Query<IDeviceState> {
  public deviceID$: Observable<ID> = this.select((state: IDeviceState) => state.deviceID);

  constructor(protected store: DeviceStore) {
    super(store);
  }

  public getDeviceId(): ID {
    const { deviceID } = this.getValue();
    return deviceID;
  }
}
