import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItems } from '@core/menu';

@Component({
  selector: 'div[ec-sub-header]',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent {
  @Input() items!: MenuItems | null;
}
