import { BackgroundMap } from '../types';
import { BackgroundValues } from '../enums';
import { BackgroundClass } from '../enums/background-class.enum';

const BACKGROUND_MAP: BackgroundMap = {
  [BackgroundValues.grey]: BackgroundClass.grey,
  [BackgroundValues.white]: BackgroundClass.white,
};

export { BACKGROUND_MAP };
