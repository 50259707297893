import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NotificationsService } from '@services/notifications';

@Component({
  selector: 'ec-copy-button',
  template: `
    <button
      *ngIf="copy"
      type="button"
      class="btn-copy"
      [cdkCopyToClipboard]="copy"
      (click)="clipboardNotify()"
    >
      <span ec-icon-wrapper icon="copy"></span>
      <span class="text-decoration-underline">
        <ng-content></ng-content>
      </span>
    </button>
  `,
  styleUrls: ['./copy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent {
  @Input() copy!: string;

  @Input() notificationTitle = '';

  copied = false;

  constructor(private readonly notifyService: NotificationsService) {}

  public clipboardNotify(): void {
    this.copied = true;
    this.notifyService.success(`${this.notificationTitle} Copied`, 'Clipboard');
  }
}
