<div class="navigation">
  <ng-container *ngIf="!menuItem.externalLink; else external">
    <a
      [routerLink]="menuItem.route"
      class="navigation__link"
      routerLinkActive="navigation__link--active"
    >
      <ng-content select="[routerLabel]"></ng-content>
    </a>
  </ng-container>

  <ng-template #external>
    <a *ngIf="menuItem.externalLink" [href]="menuItem.externalLink" class="navigation__link">
      <ng-content select="[externalRouterLabel]"></ng-content>
    </a>
  </ng-template>
</div>
