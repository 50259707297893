import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuService } from './services';
import { MenuTriggerComponent } from './components';
import { IconsModule } from '@widgets/icons';

@NgModule({
  declarations: [MenuTriggerComponent],
  imports: [CommonModule, IconsModule],
  providers: [MenuService],
  exports: [MenuTriggerComponent],
})
export class MenuModule {}
