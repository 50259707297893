import { Injectable } from '@angular/core';
import { media$ } from '@utils/helpers/media-query.helper';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  xs = media$('(max-width: 575.98px)');

  sm$ = media$('(min-width: 576px) and (max-width: 767.98px)');

  md$ = media$('(min-width: 768px) and (max-width: 991.98px)');

  lg$ = media$('(min-width: 992px) and (max-width: 1199.98px)');

  xl$ = media$('(min-width: 1200px) and (max-width: 1399.98px)');

  xxl$ = media$('(min-width: 1400px)');

  isTouch$ = media$('(max-width: 1199.98px)');

  isMobile$ = media$('(max-width: 575.98px)');
}
