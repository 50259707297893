const loadHome = () => import('./home').then(m => m.HomeModule);

const loadCategories = () => import('./category').then(m => m.CategoryModule);

const loadMineralIntro = () =>
  import('./mineral-intro/mineral-intro.module').then(m => m.MineralIntroModule);

const loadMarket = () => import('./market').then(m => m.MarketModule);

const loadLanding = () => import('./landing').then(m => m.LandingModule);

const loadUpdates = () => import('./updates').then(m => m.UpdatesModule);

const loadCompany = () => import('./company').then(m => m.CompanyModule);

const loadHowItWorks = () => import('./how-it-works').then(m => m.HowItWorksModule);

const loadRecentUpdates = () => import('./recent-updates').then(m => m.RecentUpdatesModule);

const loadLearn = () => import('./learn').then(m => m.LearnModule);

const loadAuth = () => import('./auth').then(m => m.AuthModule);

const loadContent = () => import('./content').then(m => m.ContentModule);

const loadProfile = () => import('./profile').then(m => m.ProfileModule);

const loadAccounts = () => import('./accounts').then(m => m.AccountsModule);

const loadAccountInvitation = () =>
  import('./account-invitation').then(m => m.AccountInvitationModule);

const loadP2P = () =>
  import('./p2p').then(m => m.P2PModule);

const loadUnsubscribe = () =>
  import('./unsubscribe').then(m => m.UnsubscribeModule);

export {
  loadHome,
  loadCategories,
  loadMineralIntro,
  loadRecentUpdates,
  loadAuth,
  loadProfile,
  loadAccounts,
  loadHowItWorks,
  loadLearn,
  loadContent,
  loadCompany,
  loadMarket,
  loadLanding,
  loadUpdates,
  loadAccountInvitation,
  loadP2P,
  loadUnsubscribe
};
