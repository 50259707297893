<div [ngClass]="classes" [class.ec-card--default]="view === 'default'" class="{{ customClass }}">
  <ng-container *ngIf="isMinimal; then minimal; else detailed"></ng-container>
  <ng-template #minimal>
    <div class="ec-card-content">
      <div [ngTemplateOutlet]="content"></div>
    </div>
  </ng-template>
  <ng-template #detailed [ngTemplateOutlet]="content">
    <div class="ec-card-content">
      <div [ngTemplateOutlet]="content"></div>
    </div>
  </ng-template>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
