import { IBasePaginationDTO } from '../interfaces';

class BasePaginationDTO implements IBasePaginationDTO {
  page: number;

  page_size: number;

  constructor({ page, page_size }: BasePaginationDTO = { page: 1, page_size: 10 }) {
    this.page = page;
    this.page_size = page_size;
  }
}

export { BasePaginationDTO };
