<div class="container-fluid header-container">
  <div class="header-main">
    <div class="header">
      <a ec-main-logo href="https://eckardenterprises.com/"></a>
      <div class="links desktop-links">
        <ng-container *ngTemplateOutlet="linksTemplate"></ng-container>
      </div>
    </div>
    <div
      ec-header-user-flow
      [notCompletedRegistration]="notCompletedRegistration"
      [isAuthorized]="isAuthorized"
      [fullName]="fullName"
      (login)="login.emit()"
      (register)="register.emit()"
      (logout)="logout.emit()"
    ></div>
  </div>
</div>

<ng-template #linksTemplate>
  <div ec-navigation-items [menuItems]="menuItems"></div>
</ng-template>
