import { IPost } from '../interfaces';

const MOCKS: IPost[] = [
  {
    title: 'How to Invest in Minerals with Eckard',
  },
  {
    title: 'How to Invest in Minerals with Eckard',
  },
  {
    title: 'How to Invest in Minerals with Eckard',
  },
  {
    title: 'How to Invest in Minerals with Eckard',
  },
  {
    title: 'How to Invest in Minerals with Eckard',
  },
  {
    title: 'How to Invest in Minerals with Eckard',
  },
];

export { MOCKS };
