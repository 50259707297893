import { ILayoutConfiguration } from '../interfaces';
import { BackgroundValues } from '../enums';

const DEFAULT_LAYOUT_CONFIGURATION: ILayoutConfiguration = {
  background: BackgroundValues.grey,
  elements: {
    wrapper: false,
    breadcrumbs: false,
    slider: true,
  },
};

export { DEFAULT_LAYOUT_CONFIGURATION };
