<ec-content-section
  [props]="$any(sectionProps$ | async)"
  (actionClick)="handleActionClick()"
  [id]="id"
  [isMain]="isMain"
>
  <div
    class="mt-3"
    *ngIf="withVirtualScroll; then withVirtualScrollContent; else noVirtualScrollContent"
  ></div>
</ec-content-section>

<ng-template #noVirtualScrollContent>
  <ec-spinner-wrapper [loadingState]="loadingState"></ec-spinner-wrapper>
  <div *ngIf="content$ | async as content" [ngClass]="{ 'recent-updates': content.length }">
    <ng-container *ngIf="content.length; then contentView; else noData"></ng-container>
    <ng-template #contentView>
      <div *ngFor="let content of content; trackBy: trackByContent">
        <div ec-content-card [content]="content"></div>
      </div>
    </ng-template>
    <ng-template #noData>
      <no-data-list
        *ngIf="!loadingState"
        [heading]="isMain ? 'You’re All Caught Up!' : ''"
        [icon]="isMain ? 'newspaper' : ''"
        [maxWidth]="isMain ? 260 : 720"
        [text]="
          !isMain
            ? 'You’re All Caught Up! New Items will be added here as they become available.'
            : 'New Items will be added here as they become available.'
        "
      ></no-data-list>
    </ng-template>
  </div>
</ng-template>

<ng-template #withVirtualScrollContent>
  <div
    *ngIf="content$ | async as content"
    class="recent-updates"
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    (scrolled)="handleScroll()"
  >
    <div *ngFor="let content of content; trackBy: trackByContent" class="card-wrapper">
      <div ec-content-card [content]="content"></div>
    </div>
  </div>
  <ec-spinner-wrapper [loadingState]="loadingState"></ec-spinner-wrapper>
</ng-template>
