import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsModalComponent } from '@widgets/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ec-link-expired',
  templateUrl: './link-expired.modal.html',
  styleUrls: ['./link-expired.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkExpiredModal extends AbsModalComponent {
  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
  }
}
