import ClientEditProfile = API.ClientEditProfile;
import { PhoneFormat } from '@services/auth';

class EditProfileDto implements ClientEditProfile {
  email: string;

  first_name: string;

  last_name: string;

  middle_name: string;

  primary_phone: string;

  primary_phone_format: PhoneFormat;

  constructor({
    email,
    first_name,
    last_name,
    middle_name,
    primary_phone,
    primary_phone_format,
  }: EditProfileDto) {
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.middle_name = middle_name;
    this.primary_phone = primary_phone;
    this.primary_phone_format = primary_phone_format;
  }
}

export { EditProfileDto };
