import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbsComponentWithContentCheck,
  WithContentType,
  WithContentVisibility,
  WithVisibility,
} from '@base/index';
import { AuthService } from '@services/auth';
import { LockIconDirective } from '@widgets/content/directives';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'div[ec-content-overlay]',
  templateUrl: './content-overlay.component.html',
  styleUrls: ['./content-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentOverlayComponent
  extends AbsComponentWithContentCheck<WithContentVisibility>
  implements AfterViewInit
{
  @ViewChild(LockIconDirective) lockIcon!: LockIconDirective;

  @ViewChild('popOver') popOverToggle!: NgbPopover;

  @Input() state!: WithVisibility;

  @Input() type!: WithContentType;

  @Output() navigate: EventEmitter<unknown> = new EventEmitter<unknown>();

  popOverActive = false;

  constructor(authService: AuthService) {
    super(authService);
  }

  get iconSource(): 'microphone' | 'play' {
    const { type } = this.type;
    switch (true) {
      case this.isVideo({ type }) || this.isWebinar({ type }):
        return 'play';
      case this.isPodcast({ type }):
        return 'microphone';
      default:
        return 'none' as any;
    }
  }

  ngAfterViewInit(): void {
    this.lockIcon?.hover$.pipe(takeUntil(this.destroyed$)).subscribe((popOverActive: boolean) => {
      if (popOverActive && !this.popOverActive) {
        this.popOverToggle?.toggle();
        this.popOverActive = popOverActive;
      }
      if (!popOverActive && this.popOverActive) {
        this.popOverToggle?.toggle();
        this.popOverActive = popOverActive;
      }
    });
  }

  handleOnShown(): void {
    this.popOverActive = true;
    this.lockIcon.setValue(true);
  }

  handleOnHidden(): void {
    this.popOverActive = false;
    this.lockIcon.setValue(false);
  }
}
