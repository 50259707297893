import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestActionCallComponent } from './components';
import { ButtonsModule } from '@widgets/buttons';
import { OverrideWidthDirective } from './directives';
import { TypographyModule } from '@widgets/typography';

@NgModule({
  declarations: [InvestActionCallComponent, OverrideWidthDirective],
  imports: [CommonModule, ButtonsModule, TypographyModule],
  exports: [InvestActionCallComponent],
})
export class CallToActionModule {}
