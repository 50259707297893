import { CategoriesService, GetCategoriesDto, ICategory } from '@services/categories';
import { BehaviorSubject, Observable } from 'rxjs';
import { BasePaginationDTO } from '../../classes';
import { IPaginatedResponse, WithElementsLimitation, WithGetData } from '../../interfaces';
import { map, takeUntil, tap } from 'rxjs/operators';
import { limitItems } from '@utils/data-display';
import { ecCreateLogger } from '@utils/logger';
import { AbsComponentWithLoading } from './abs-component-with-loading';
import { changeLoadingState, loadingStatePipe } from '@utils/loading';

const log = ecCreateLogger('core:base::abs-categories');

type ComponentWithCategoriesProps = WithElementsLimitation;

abstract class AbsComponentWithCategories<DTO extends BasePaginationDTO>
  extends AbsComponentWithLoading
  implements WithGetData
{
  logger = { log };

  public categoriesBeh$: BehaviorSubject<ICategory[]> = new BehaviorSubject<ICategory[]>([]);

  public categories$: Observable<ICategory[]> = this.categoriesBeh$.asObservable();

  public categoriesDTO$!: BehaviorSubject<DTO>;

  protected constructor(
    protected categoriesService: CategoriesService,
    paginationDTO: DTO,
    protected props: ComponentWithCategoriesProps,
  ) {
    super();
    this.categoriesDTO$ = new BehaviorSubject<DTO>({
      ...paginationDTO,
      page_size: this.props.showCount ?? paginationDTO.page_size,
    });
    this.logger.log(`Pagination DTO - ${JSON.stringify(paginationDTO)}`);
    this.logger.log(`Component Props - ${JSON.stringify(this.props)}`);
  }

  public getData(dto: GetCategoriesDto = this.categoriesDTO$.getValue()): void {
    if (!this.loadingState) {
      changeLoadingState(this);
    }

    this.categoriesService
      .getAll(dto)
      .pipe(
        loadingStatePipe(() => changeLoadingState(this)),
        // hasNextPipe(({ next }) =>
        //   changeHasNextValue<AbsComponentWithCategories<DTO>>(this, { next })
        // ),
        map(({ results, next }: IPaginatedResponse<ICategory>) => {
          this.hasNext = !!next ?? false;
          this.next = next;
          return limitItems({ results, showCount: this.props.showCount });
        }),
        tap((data: ICategory[]) => this.categoriesBeh$.next(data)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public handleNextData(): void {
    if (this.next) {
      if (!this.loadingState) {
        changeLoadingState(this);
      }

      this.categoriesService
        .load(this.next)
        .pipe(
          loadingStatePipe(() => changeLoadingState(this)),
          map(({ results, next }: IPaginatedResponse<ICategory>) => {
            this.hasNext = !!next ?? false;
            this.next = next;
            return limitItems({ results, showCount: this.props.showCount });
          }),
          tap((data: ICategory[]) => {
            const categories: ICategory[] = this.categoriesBeh$.getValue();
            this.categoriesBeh$.next([...categories, ...data]);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    }
  }
}

export { AbsComponentWithCategories };
