import { IEnvironment, ISocial } from '@env/environment.interface';
import { environment } from '@env/environment';

abstract class AbsComponentWithEnvironmentData {
  private readonly envs: IEnvironment = environment;

  get email(): string {
    return this.envs.meta.email;
  }

  get phoneNumber(): string {
    return this.envs.meta.phoneNumber;
  }

  get copyRights(): string {
    return this.envs.meta.copyRights;
  }

  get googleLink(): string {
    return this.envs.apps.google;
  }

  get appStoreLink(): string {
    return this.envs.apps.apple;
  }

  get termsOfUse(): string {
    return this.envs.externalLinks.termsOfUse;
  }

  get privacyPolicy(): string {
    return this.envs.externalLinks.privacyPolicy;
  }

  get globalLink(): string {
    return this.envs.externalLinks.global;
  }

  get landLink(): string {
    return this.envs.externalLinks.land;
  }

  public socialLink(key: keyof ISocial): string {
    return this.envs.social[key];
  }
}

export { AbsComponentWithEnvironmentData };
