import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IAuthState, ITokens } from '../interfaces';
import { typeWrapper } from '@utils/types';

export const createInitialState = (): IAuthState => ({
  access: typeWrapper(undefined),
  refresh: typeWrapper(undefined),
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<IAuthState> {
  constructor() {
    super(createInitialState());
  }

  public addTokens({ access, refresh }: ITokens): void {
    this.update({
      access,
      refresh,
    });
  }
}
