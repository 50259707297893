import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentSectionProps } from '../../interfaces';

@Component({
  selector: 'ec-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentSectionComponent {
  @Input() props: IContentSectionProps;

  @Input() isMain = false;

  @Output() actionClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  get label(): string {
    return this.props.content?.label ?? this.props.label;
  }

  get count(): string | undefined {
    return this.props.badge?.label;
  }

  get isZero(): boolean {
    if (this.props.badge?.label) {
      return +this.props.badge?.label === 0;
    }
    return false;
  }
}
