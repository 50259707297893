import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SliderService } from '@services/slider';
import { Observable } from 'rxjs';
import { ISliderItem } from '@services/slider/interfaces';

@Component({
  selector: 'ec-ec-home-carousel',
  templateUrl: './ec-home-carousel.component.html',
  styleUrls: ['./ec-home-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcHomeCarouselComponent {
  public items$: Observable<ISliderItem[]>;

  constructor(private readonly sliderService: SliderService) {
    this.items$ = this.sliderService.sliderItems$;
  }
}
