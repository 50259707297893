import { IVerificationStep } from '../interfaces';
import { guid } from '@datorama/akita';
import { AccreditationStatus, UserStatus } from '../../../services/account';

const VERIFICATION_STEPS: IVerificationStep[] = [
  {
    title: 'Verify Your Email',
    description:
      'We’ve sent you an email. Check your spam/junk folder as well. If you don’t see it, please contact us.',
    userStatus: UserStatus.pendingEmail,
    method: 'resendVerification',
  },
  {
    title: 'Investor Accreditation Form',
    description: 'Please click the link below to complete your accreditation form.',
    userStatus: AccreditationStatus.form_pending,
  },
  {
    title: 'Account Review',
    description: 'We will notify you via email once your account has been reviewed. ',
    userStatus: AccreditationStatus.pending,
  },
].map((vs: Pick<IVerificationStep, 'title' | 'description'>, index: number) => ({
  ...vs,
  stepTitle: `Step ${index + 1}`,
  id: guid(),
}));

export { VERIFICATION_STEPS };
