import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '@services/account';

@Component({
  selector: '[ec-header-user-flow]',
  templateUrl: './header-user-flow.component.html',
  styleUrls: ['./header-user-flow.component.scss'],
})
export class HeaderUserFlowComponent {
  @Input() isAuthorized: boolean | null = false;

  @Input() notCompletedRegistration: boolean | null = false;

  @Input() fullName!: string | null;

  @Output() register: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() login: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() logout: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(public readonly accountService: AccountService, config: NgbDropdownConfig) {
    config.placement = 'bottom-right';
    config.autoClose = true;
  }
}
