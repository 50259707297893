<section class="section">
  <div class="section-header" [class.is-main]="isMain" *ngIf="props">
    <div class="section-header-title">
      <h2 ec-section-label [styles]="props.sectionStyles" *ngIf="label">{{ label }}</h2>
      <div
        class="section-header-badge"
        *ngIf="count"
        [ngClass]="{ 'section-header-badge--empty': isZero }"
      >
        <span class="section-header-badge-content">
          {{ count }}
        </span>
      </div>
    </div>
    <div class="section-header-actions" *ngIf="props.actionLabel" (click)="actionClick.emit()">
      <a ec-link>{{ props.actionLabel }}</a>
      <div ec-icon-wrapper class="ms-2" icon="arrow-right"></div>
    </div>
  </div>
  <div class="section-content">
    <ng-content></ng-content>
  </div>
</section>
