<div class="card pointer">
  <div ec-content-overlay class="card-image" [state]="content" [type]="content">
    <ng-container *ngIf="content.image; then withImage; else withoutImage"></ng-container>
    <ng-template #withImage>
      <img
        class="card-img-top"
        [src]="content | contentProp : 'image'"
        [alt]="content | contentProp : 'title'"
      />
    </ng-template>
    <ng-template #withoutImage>
      <img
        class="card-img-top"
        loading="lazy"
        src="assets/images/placeholders/article.png"
        [alt]="content.title"
      />
    </ng-template>
  </div>
  <div class="card-body">
    <h5 class="content-type">
      <span ec-icon-wrapper [icon]="getContentIcon(content.type)" size="xs" color="gray"></span>
      {{ content | contentProp : 'type' | uppercase }}
    </h5>
    <h5 class="card-title" ec-text-shortener lines="2">
      {{ content | contentProp : 'title' }}
    </h5>
    <p class="card-text" ec-text-shortener lines="2">
      {{ content | contentProp : 'description' }}
    </p>
  </div>
</div>
