import { IContentSectionProps, SectionContentType, SectionLabel } from '@widgets/content-sections';
import { loadSectionProps } from '@utils/content-section';
import { DEFAULT_SECTION_ACTION_LABEL } from '@base/const';

const actionLabel: string = DEFAULT_SECTION_ACTION_LABEL;

const LEARN_PAGES_SECTIONS: IContentSectionProps[] = [
  loadSectionProps({
    label: SectionLabel.recentUpdates,
    content: {
      type: SectionContentType.recentUpdates,
      label: SectionLabel.recentUpdates,
    },
    actionLabel,
  }),
  loadSectionProps({
    label: SectionLabel.featuredStories,
    content: {
      type: SectionContentType.featuredStories,
      label: SectionLabel.featuredStories,
    },
    actionLabel,
  }),
];

export { LEARN_PAGES_SECTIONS };
