<div class="modal-wrapper">
  <div ec-modal-header></div>
  <div class="modal-body pb-0">
    <ec-success-notification
      heading="Verification Email Resent"
      description="We've just resent the verification email to your inbox."
    >
      <button ec-button (click)="activeModal.close()" type="button">Continue</button>
    </ec-success-notification>
  </div>
</div>
