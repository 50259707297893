<ec-spinner-wrapper [loadingState]="loadingState"></ec-spinner-wrapper>

<div class="category-items" [ngClass]="{ 'with-margin': verticalScrolling$ | async }">
  <div
    ec-content-card
    *ngFor="let item of items$ | async; trackBy: trackByItem"
    [content]="item"
    [verticalScrollable]="verticalScrolling$ | async"
  ></div>
</div>
