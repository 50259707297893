import { Pipe, PipeTransform } from '@angular/core';
import { noDataValue } from '@utils/helpers/date.helper';

@Pipe({
  name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return noDataValue;
    }
    return value.replace(/\+1(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
}
