import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ec-is-blocked-error',
  templateUrl: './is-blocked-error.modal.html',
  styleUrls: ['./is-blocked-error.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IsBlockedErrorModal {
  @Input() text: string;

  constructor(public activeModal: NgbActiveModal) {}
}
