import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { Icon } from '@widgets/icons';
import {IconColor} from '@widgets/icons/components';
import { DropdownService } from '../../services/dropdown.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ec-dropdown',
  template: `
    <div ngbDropdown (openChange)="isOpen = !isOpen" container="body" placement="auto" (click)="$event.stopPropagation()">
      <div
        *ngIf="iconName"
        ec-icon-wrapper
        [icon]="isOpen && iconOpenName ? iconOpenName : iconName"
        [color]="iconColor"
        ngbDropdownToggle
        (click)="$event.preventDefault(); $event.stopPropagation()"
        class="pointer"
      ></div>
      <button
        type="button"
        class="btn btn-standard btn-yellow"
        ngbDropdownToggle
        (click)="$event.preventDefault(); $event.stopPropagation()"
        *ngIf="buttonName"
      >
        {{ buttonName }}
      </button>

      <div ngbDropdownMenu (click)="$event.stopPropagation()">
        <ng-content select="ec-dropdown-item"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
  @Input() iconName!: Icon;

  @Input() iconOpenName?: Icon;

  @Input() iconColor!: IconColor;

  @Input() buttonName!: string;

  isOpen: boolean;

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  constructor(readonly dropdownService: DropdownService) {
    dropdownService.hideDropdown$.subscribe(() => {
      this.dropdown?.close();
    });
  }
}
