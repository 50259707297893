import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAddress } from '@services/account';

@Component({
  selector: 'ec-address-block',
  template: `
    <ng-container *ngIf="!addressIsEmpty; else noAddress">
      <ec-text size="lg" touchSize="lg">
        {{ addressLabel }}
      </ec-text>
      <ec-text color="primary" size="lg" touchSize="lg">
        <div class="mt-1" *ngIf="address.name">{{ address.name }}</div>
        <div class="mt-1" *ngIf="address.attention_to">
          {{ address.attention_to }}
        </div>
        <div class="mt-1" *ngIf="address.street">{{ address.street }}</div>
        <div class="mt-1">
          <span *ngIf="address.city">
            {{ address.city }}
          </span>
          <span *ngIf="address.state">{{ address.state | stateAbbr }}&nbsp;&nbsp;</span>
          <span *ngIf="address.zip_code">{{ address.zip_code }}</span>
        </div>
      </ec-text>
    </ng-container>

    <ng-template #noAddress>
      <div class="col-12">
        <ec-text touchSize="lg" size="lg" class="d-block">Contact Address</ec-text>
        <ec-text color="primary" touchSize="lg" size="lg" class="mt-2"
          >No contact addresses added</ec-text
        >
      </div>
    </ng-template>
  `,
  styleUrls: ['./address-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressBlockComponent {
  @Input() address: IAddress;

  @Input() index: number;

  @Input() addToCountAddress: number;

  @Input() isContactAddress: boolean;

  get addressIsEmpty(): boolean {
    if (this.address) {
      return (
        !this.address.name &&
        !this.address.attention_to &&
        !this.address.street &&
        !this.address.city &&
        !this.address.state &&
        !this.address.zip_code
      );
    }

    return true;
  }

  get addressLabel(): string {
    if (this.index === undefined) {
      return 'Address';
    } else {
      return this.isContactAddress
        ? 'Contact Address'
        : 'Account Address ' + (this.index + this.addToCountAddress);
    }
  }
}
