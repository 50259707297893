import { ICollapsibleMenuItem, MenuItemLabel } from '@core/menu';
import { guid } from '@datorama/akita';
import { ROUTES } from '@const';

export const PROFILE_MENU_ITEMS: ICollapsibleMenuItem = {
  id: guid(),
  label: MenuItemLabel.profile,
  route: `/${ROUTES.profile}`,
  collapsed: true,
  child: [
    {
      id: guid(),
      label: 'Contact Information' as MenuItemLabel,
      route: `/${ROUTES.profile}/contact-info`,
    },
    {
      id: guid(),
      label: 'Multi-Factor Authentication' as MenuItemLabel,
      route: `/${ROUTES.profile}/mfa`,
    },
    {
      id: guid(),
      label: 'Accounts' as MenuItemLabel,
      route: `/${ROUTES.profile}/accounts`,
    },
    {
      id: guid(),
      label: 'Investment History' as MenuItemLabel,
      route: `/${ROUTES.profile}/investments-history`,
    },
    {
      id: guid(),
      label: 'Documents & Reports' as MenuItemLabel,
      route: `/${ROUTES.profile}/documents-reports`,
    },
    {
      id: guid(),
      label: 'Trusted Devices' as MenuItemLabel,
      route: `/${ROUTES.profile}/trusted-devices`,
    },
  ],
};
