import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewAllCardComponent } from './components';
import { CardsModule } from '@widgets/cards';
import { ButtonsModule } from '@widgets/buttons';
import { IconsModule } from '@widgets/icons';

@NgModule({
  declarations: [ViewAllCardComponent],
  imports: [CommonModule, CardsModule, ButtonsModule, IconsModule],
  exports: [ViewAllCardComponent],
})
export class MobileModule {}
