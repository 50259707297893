import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PageComponent,
  PageContentComponent,
  PageTitleComponent,
  PageWrapperComponent,
  PageActionComponent,
  PageFieldComponent,
} from './components';
import { IconsModule } from '@widgets/icons';
import { PageSectionComponent } from './components/page-section/page-section.component';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@widgets/buttons';

@NgModule({
  declarations: [
    PageComponent,
    PageWrapperComponent,
    PageTitleComponent,
    PageContentComponent,
    PageActionComponent,
    PageFieldComponent,
    PageSectionComponent,
  ],
  imports: [CommonModule, IconsModule, RouterModule, ButtonsModule],
  exports: [
    PageComponent,
    PageWrapperComponent,
    PageTitleComponent,
    PageContentComponent,
    PageActionComponent,
    PageFieldComponent,
    PageSectionComponent,
  ],
})
export class PageModule {}
