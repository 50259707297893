type InputIcons = 'email' | 'password' | 'phone';

type StatusIcons = 'verified' | 'checked-circle' | 'error-circle';

type RadioIcons = 'radio-placeholder' | 'radio-placeholder_primary';

type MenuIcons = 'menu' | 'cross';

type UserActionsIcons = 'logout' | 'back';

type FooterIcons = 'contacts' | 'phone_white';

type ArrowIcons = 'arrow-down' | 'arrow-up_active' | 'arrow-right' | 'arrow-left';

type ActionIcons = 'edit' | 'download' | 'close' | 'delete';

type AccountIcons = 'account';

type BehaviourIcons = 'show' | 'hide';

type MarketplaceIcons = 'appstore' | 'googleplay';

type Icon =
  | AccountIcons
  | MenuIcons
  | UserActionsIcons
  | ArrowIcons
  | ActionIcons
  | InputIcons
  | BehaviourIcons
  | FooterIcons
  | StatusIcons
  | RadioIcons
  | MarketplaceIcons
  | 'info'
  | 'info-yellow'
  | 'date_picker'
  | 'play'
  | 'play_black'
  | 'document'
  | 'article'
  | 'microphone_black'
  | 'listen'
  | 'notification'
  | 'location'
  | 'search'
  | 'temporary'
  | 'no-production'
  | 'auth-app'
  | 'auth-app-blue'
  | 'auth-app-enable'
  | 'auth-sms'
  | 'filter-list'
  | 'lock-check'
  | 'folder'
  | 'copy'
  | 'avatar'
  | 'cross-circle-red'
  | 'checked-circle-white'
  | 'options'
  | 'dollar'
  | 'carbon_document'
  | 'no-cashflow'
  | 'invite'
  | 'tick-mark'
  | 'active-bell'
  | 'laptop'
  | 'card'
  | 'security'
  | 'bank';

export {
  Icon,
  InputIcons,
  StatusIcons,
  RadioIcons,
  MenuIcons,
  UserActionsIcons,
  FooterIcons,
  ArrowIcons,
  ActionIcons,
  AccountIcons,
  BehaviourIcons,
};
