import { Injectable } from '@angular/core';
import { AccountService } from '@services/account';
import { ClientAuthService } from '@services/auth';

@Injectable({
  providedIn: 'root',
})
export class ClientFacadeService {
  constructor(public accountService: AccountService, public clientAuthService: ClientAuthService) {}
}
