import {ProjectStatusName} from '../../accounts/models/project-details.model';

export interface IMarketProjectList {
  available_nma: string;
  available_units: string;
  cover_image: string;
  id: number;
  is_new: boolean;
  is_nra: boolean;
  min_investment_amount: string;
  min_nma: string;
  name: string;
  price_per_nma: string;
  price_per_nra: string;
  price_per_unit: string;
  project_type: EProjectType | ProjectStatusName;
  sold_nma_percentage: string;
  status: EProjectStatus;
  total_cost: string;
  total_nma: string;
  total_nra: string;
  total_units: string;
  to_waitlist: boolean;
  is_for_waitlist: boolean;
  class_id?: number;
  class_name?: string;
}

export interface IMarketProject extends IMarketProjectList {
  image: string;
  count_wells: string;
  count_tracts: string;

  carts: IProjectCart[];
}

export interface IProjectCart {
  invested_type: EPurchaseType;
  nma: string;
  total: string;
}

export enum EProjectType {
  M = 'Minerals',
  F = 'Funds',
  WI = 'Working_Interest',
}

export enum EProjectTypeNames {
  'Working Interest' = 'Working Interest',
  Working_Interest = 'Working Interest',
  Funds = 'Fund',
  Minerals = 'Minerals',
}

export enum EProjectStatus {
  Draft = 'Draft',
  Open = 'Open',
  Funded = 'Funded',
  Archived = 'Archived',
}

export enum EPurchaseType {
  NMA = 'nma',
  Dollars = 'dollars',
}
