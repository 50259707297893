import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsModalComponent } from '@widgets/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingService } from '../../../../routing/landing/services/landing.service';
import { Observable, take } from 'rxjs';
import { ResponsiveService } from '../../../../services/responsive';

@Component({
  selector: 'ec-accreditation-expired',
  templateUrl: './accreditation-expired.modal.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccreditationExpiredModal extends AbsModalComponent {
  accreditationLink$ = this.landingService.getAccreditationLink$();

  isMobile$: Observable<boolean> = this.responsiveService.isMobile$;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly landingService: LandingService,
    private readonly responsiveService: ResponsiveService,
  ) {
    super(activeModal);
    this.landingService.fetchAccreditationLink();
    this.landingService
      .changeAccreditationInfo({ is_notified_expired_today: true })
      .pipe(take(1))
      .subscribe();
  }

  goToPandaDoc(link: string | null): void {
    this.activeModal.close();
    if (link) {
      window.open(link, '_blank');
    }
  }
}
