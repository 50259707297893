import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnlockContentComponent, UnlockContentOverlayComponent } from './components';
import { ButtonsModule } from '@widgets/buttons';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [UnlockContentComponent, UnlockContentOverlayComponent],
  imports: [CommonModule, ButtonsModule, RouterModule],
  exports: [UnlockContentComponent, UnlockContentOverlayComponent],
})
export class RestrictionMessagesModule {}
