import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ec-accreditation-form-submitted',
  templateUrl: './accreditation-form-submitted.modal.html',
  styleUrls: ['./accreditation-form-submitted.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccreditationFormSubmittedModal {
  @Output() continue: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() cancel: EventEmitter<unknown> = new EventEmitter<unknown>();
}
