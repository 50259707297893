/**
 * Accepts Component as @param {CTX} ctx, and changes it loadingState property to !loadingState.
 *
 * @template CTX
 * @param ctx - Component with loadingState property available.
 */
import { AbsComponentWithLoading } from '@base/abs';

const changeLoadingState = <CTX extends AbsComponentWithLoading>(ctx: CTX): void => {
  ctx.loadingState = !ctx.loadingState === undefined || null ? true : !ctx.loadingState;
};

export { changeLoadingState };
