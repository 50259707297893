import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'div[ec-modal-header]',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
  @Input() heading!: string;

  @Input() hideCloseButton!: boolean;

  @Input() customClass: 'centered' | 'centered small' | 'small' | undefined;

  @Input() subHeading?: string;

  @Input() customClose: boolean;

  @Output() closeClicked: EventEmitter<void> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}
}
