import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth';
import { AccreditationStatus } from '@services/account';
import {ROUTES} from '@const';

@Injectable({
  providedIn: 'root',
})
export class P2PGuard  {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {}

  canActivate(): boolean {
    if (
      this.authService.getUserAccreditationStatus().accreditation_status !== AccreditationStatus.accredited
      || this.authService.getAccount().accreditation_expired
      || !this.authService.getAccount().has_psa_and_funds_active_investments
    ) {
      void this.router.navigate([ROUTES.learn]);
      return false;
    }

    return true;
  }
}
