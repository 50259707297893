import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthStepperStore } from './auth-stepper.store';
import { IAuthStepperRedirectToPage, IAuthStepperState } from '../interfaces';
import { Observable } from 'rxjs';
import { RegisterStep } from '../enums';
import { ISignupInvitation } from '@services/account';

@Injectable()
export class AuthStepperQuery extends Query<IAuthStepperState> {
  public currentStep$: Observable<RegisterStep> = this.select(
    ({ currentStep }: IAuthStepperState) => currentStep,
  );

  public email$: Observable<string | undefined> = this.select(
    ({ email }: IAuthStepperState) => email,
  );

  public invitationToken$: Observable<string | undefined> = this.select(
    ({ invitationToken }: IAuthStepperState) => invitationToken,
  );

  public invitation$: Observable<ISignupInvitation | undefined> = this.select(
    ({ invitation }: IAuthStepperState) => invitation,
  );

  public message$: Observable<string | undefined> = this.select(
    ({ message }: IAuthStepperState) => message,
  );

  public invitationFromSharedAccount$: Observable<boolean> = this.select(
    ({ invitationFromSharedAccount }: IAuthStepperState) => invitationFromSharedAccount || false,
  );

  public redirectToPage$: Observable<IAuthStepperRedirectToPage | undefined> = this.select(
    ({ redirectToPage }: IAuthStepperState) => redirectToPage,
  );

  constructor(protected store: AuthStepperStore) {
    super(store);
  }

  get currentStep(): RegisterStep {
    const { currentStep } = this.getValue();
    return currentStep;
  }

  get email(): string | undefined {
    const { email } = this.getValue();
    return email;
  }

  get redirectToPage(): IAuthStepperRedirectToPage | undefined {
    const { redirectToPage } = this.getValue();
    return redirectToPage;
  }

  get invitationFromSharedAccount(): boolean | undefined {
    const { invitationFromSharedAccount } = this.getValue();
    return invitationFromSharedAccount;
  }
}
