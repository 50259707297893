import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '@services/auth';
import {UserStatus} from '@services/account';

@Injectable({
  providedIn: 'root',
})
export class PortfolioGuard  {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  canActivate(): Observable<boolean> {
    return of(this.validateAccess());
  }

  private validateAccess(): boolean {
    const { access } = this.authService.getAccess();

    const account = this.authService.getAccount();

    if (
      !access ||
      account.app_status === UserStatus.notAppUser
    ) {
      this.router.navigate(['/home']).then();
    }

    return !!access;
  }
}
