<ng-container *ngIf="loadingState; then loader; else content"> </ng-container>

<ng-template #loader>
  <div class="spinner-wrapper">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
