import { BehaviourMessagesType } from '../types/behaviour-messages.type';
import { BehaviourMessages, CustomPagesBehaviour } from '../enums';

const BEHAVIOUR_MESSAGES: BehaviourMessagesType = {
  [CustomPagesBehaviour.something_went_wrong]: BehaviourMessages.something_went_wrong,
  [CustomPagesBehaviour.user_already_exists]: BehaviourMessages.user_already_exists,
  [CustomPagesBehaviour.password_changed]: BehaviourMessages.password_changed,
};

export { BEHAVIOUR_MESSAGES };
