import { Directive, ElementRef, OnInit } from '@angular/core';
import { LayoutService, ILayoutConfiguration } from '@core/layout';
import { ecCreateLogger } from '@utils/logger';
import { BACKGROUND_MAP, POSSIBLE_CLASSES } from '../const';

const log = ecCreateLogger('core:branding::layout-configuration');

@Directive({
  selector: 'div[ec-dynamic-background]',
})
export class DynamicBackgroundDirective implements OnInit {
  logger = { log };

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private readonly layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    this.layoutService.layoutConfiguration$.subscribe(({ background }: ILayoutConfiguration) => {
      this.handleBackgroundChange({ background });
    });
  }

  private handleBackgroundChange({ background }: Pick<ILayoutConfiguration, 'background'>): void {
    this.element.nativeElement.classList.remove(...POSSIBLE_CLASSES);
    this.logger.log(`Background value - ${background}`);
    this.element.nativeElement.classList.add(BACKGROUND_MAP[background]);
  }
}
