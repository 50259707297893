interface ITokens {
  access: string;
  refresh: string;
}

interface StatusResponse {
  status: string;
}

export { ITokens, StatusResponse };
