import { ColorPalette } from '@core/branding';
import { ID } from '@datorama/akita';

interface IBadgeProps {
  id?: ID;
  label?: string;
  styles?: ColorPalette;
}

export { IBadgeProps };
