import { Router } from '@angular/router';
import { ROUTES } from '@const';

const detectMainRoute = (router: Router): string => {
  const { url: moduleURL } = router;
  switch (moduleURL) {
    case `/${ROUTES.learn}`:
      return moduleURL;
    default:
      return `${ROUTES.home}`;
  }
};

export { detectMainRoute };
