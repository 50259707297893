import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentSectionComponent } from './components';
import { ButtonsModule } from '../buttons';
import { SectionLabelDirective } from './directives/section-label.directive';
import { IconsModule } from '@widgets/icons';

@NgModule({
  declarations: [ContentSectionComponent, SectionLabelDirective],
  imports: [CommonModule, ButtonsModule, IconsModule],
  exports: [ContentSectionComponent],
})
export class ContentSectionsModule {}
