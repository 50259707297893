<div class="modal-wrapper">
  <div ec-modal-header></div>
  <div class="modal-body pb-0 mb-0">
    <ec-success-notification
      heading="Account Locked"
      [description]="text"
      icon="error-circle"
    >
      <button ec-button color="white" (click)="activeModal.close()" type="button">Close</button>
    </ec-success-notification>
  </div>
</div>
