import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_SECTION_ACTION_LABEL } from '@base/const';

@Component({
  selector: 'ec-view-all-card',
  templateUrl: './view-all-card.component.html',
  styleUrls: ['./view-all-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewAllCardComponent {
  @Input() title = DEFAULT_SECTION_ACTION_LABEL;

  @Output() clicked: EventEmitter<unknown> = new EventEmitter<unknown>();
}
