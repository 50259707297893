import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoModalComponent } from '@widgets/buttons/components/watch-video/video-modal/video-modal.component';

@Component({
  selector: 'ec-watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.scss'],
})
export class WatchVideoComponent {
  @Input() content!: unknown;

  @Input() disableButton!: boolean;

  constructor(private dialog: NgbModal) {}

  playVideo(): void {
    if (!this.disableButton) {
      const modal = this.dialog.open(VideoModalComponent, {
        modalDialogClass: 'video-modal',
        size: 'xl',
        centered: true,
        backdrop: true
      });
      modal.componentInstance.content = this.content;
    }
  }
}
