import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PopoverModal } from '@widgets/dropdown/modals/popover/popover.modal';
import { DropdownService } from '@widgets/dropdown/services/dropdown.service';

@Component({
  selector: 'ec-popover',
  template: `
    <ng-container *ngIf="showAsModalOnTouch; else elseTemplate">
      <div
        (mouseenter)="handlePopover()"
        [ngbPopover]="popContent"
        [autoClose]="'outside'"
        popoverClass="pop-{{ contentType }}"
        triggers="mouseenter"
        container="body"
        class="d-none d-xl-inline-block"
      >
        <ng-container *ngTemplateOutlet="popoverToggle"></ng-container>
      </div>

      <div
        class="d-xl-none"
        (click)="handleModal(); $event.stopPropagation(); $event.preventDefault()"
      >
        <ng-container *ngTemplateOutlet="popoverToggle"></ng-container>
      </div>
    </ng-container>
    <ng-template #popContent>
      <ng-container *ngTemplateOutlet="popoverTemplate"></ng-container>
    </ng-template>
    <ng-template #elseTemplate>
      <div
        (mouseenter)="handlePopover()"
        [ngbPopover]="popContent"
        [autoClose]="'outside'"
        popoverClass="pop-{{ contentType }} pop-on-hover"
        triggers="click"
        container="body"
      >
        <ng-container *ngTemplateOutlet="popoverToggle"></ng-container>
      </div>
    </ng-template>
  `,
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent {
  @ViewChild(NgbPopover) popover: NgbPopover;

  @Input() popoverTemplate: TemplateRef<any>;

  @Input() popoverToggle: TemplateRef<any>;

  @Input() windowTitle: string;

  @Input() contentType: 'table' | '600' | 'default' | 'with-scroll' | 'hint' = 'default';

  @Input() madalBackdrop: boolean | 'static' = true;

  @Input() showAsModalOnTouch = true;

  constructor(private readonly dropdownService: DropdownService, private readonly modal: NgbModal) {
    this.dropdownService.hidePopover$.subscribe(() => {
      this.popover?.close();
    });
  }

  handlePopover(): void {
    this.dropdownService.hidePopover$.next(true);
  }

  handleModal(): void {
    const modal = this.modal.open(PopoverModal, {
      backdrop: this.madalBackdrop,
    });
    (modal.componentInstance as PopoverModal).windowTitle = this.windowTitle;
    (modal.componentInstance as PopoverModal).currentTemplate = this.popoverTemplate;
  }

  closeModal(): void {
    this.modal.dismissAll();
  }
}
