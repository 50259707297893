<div class="row">
  <header
    *ngIf="!withoutHeader"
    class="col-12 {{ headerClass }}"
  >
    <ng-content select="page-title"></ng-content>
    <div>
      <ng-content select="page-action"></ng-content>
    </div>
  </header>
  <section class="col-12">
    <ng-content select="page-content"></ng-content>
  </section>
</div>
