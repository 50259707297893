import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CategoriesService, GetCategoriesDto } from '@services/categories';
import { AbsComponentWithCategories } from '@base/abs';
import { BasePaginationDTO } from '@base/classes';
import { IContentSectionProps, SectionLabel } from '@widgets/content-sections';
import { WithSectionPropsWithoutAction } from '@base/interfaces';

@Component({
  selector: 'ec-categories-mobile',
  templateUrl: './categories-mobile.component.html',
  styleUrls: ['./categories-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesMobileComponent
  extends AbsComponentWithCategories<BasePaginationDTO>
  implements OnChanges, WithSectionPropsWithoutAction
{
  @Input() categoriesDto!: GetCategoriesDto;

  sectionProps: IContentSectionProps = {
    label: SectionLabel.categories,
  };

  constructor(categoriesService: CategoriesService) {
    super(categoriesService, new BasePaginationDTO(), { showCount: undefined });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoriesDto && changes.categoriesDto.firstChange) {
      this.categoriesDTO$.next(changes.categoriesDto.currentValue);
      this.getData();
    }
  }

  handleActionClick(): void {
    this.categoriesService.viewAll();
  }
}
