<ul class="flex-wrap">
  <li *ngFor="let menuItem of menuItems; trackBy: trackMenus">
    <a *ngIf="!menuItem.externalLink" [routerLink]="menuItem.route">
      {{ menuItem.label }}
    </a>
    <a *ngIf="menuItem.externalLink" [href]="menuItem.externalLink">
      {{ menuItem.label }}
    </a>
  </li>
</ul>
