import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ec-email-verified',
  templateUrl: './email-verified.modal.html',
  styleUrls: ['./email-verified.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailVerifiedModal {
  @Output() continue: EventEmitter<unknown> = new EventEmitter<unknown>();
}
