import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AccountService, UserStatus} from '@services/account';

@Injectable({
  providedIn: 'root',
})
export class MfaGuard  {
  constructor(
    private readonly accountService: AccountService,
    private readonly router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { is_login_verified, app_status, is_two_factor_enabled, has_authenticator_app, has_sms_device } =
      this.accountService.account;

    if (
      !is_login_verified &&
      app_status === UserStatus.appUser &&
      (!is_two_factor_enabled || (has_sms_device && !has_authenticator_app) )
    ) {
      return this.router.navigate(['/auth/mfa/email']);
    }

    if (!is_login_verified && is_two_factor_enabled) {
      if (has_authenticator_app && has_sms_device) {
        return this.router.navigate(['/auth/mfa/email']);
      }
      if (has_authenticator_app) {
        return this.router.navigateByUrl('/auth/mfa/app');
      } else if (has_sms_device) {
        return this.router.navigateByUrl('/auth/mfa/sms');
      }
    }

    return true;
  }
}
