import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '@services/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(this.validateAccess(state.url));
  }

  private validateAccess(redirectUrl: string): boolean {
    const { access } = this.authService.getAccess();
    if (!access) {
      this.authService.redirectUrl = redirectUrl;
      void this.router.navigate(['/auth/login']);
    }
    return !!access;
  }
}
