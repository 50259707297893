import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { CustomCountdownComponent } from '@widgets/timer/components/countdown/countdown.component';

@Component({
  selector: 'ec-send-again-timer',
  template: `
    <div class="wrapper">
      Didn’t receive the code?
      <ec-countdown #countdownComponent>
        <a ec-link size="sm" touchSize="sm" (click)="resend.emit(); countdownComponent.restart()">
          Send Again
        </a>
      </ec-countdown>
    </div>
  `,
  styleUrls: ['./send-again-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendAgainTimerComponent implements AfterViewInit {
  @ViewChild(CustomCountdownComponent)
  countdownComponent!: CustomCountdownComponent;

  @Output() resend = new EventEmitter();

  ngAfterViewInit(): void {
    this.countdownComponent?.restart();
  }
}
