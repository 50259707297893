import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { DigitPipe } from './digit.pipe';
import { IntToMonthPipe } from './int-to-month.pipe';
import { DocumentIconPipe } from './document-icon.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { StateAbbrPipe } from './state-abbr.pipe';
import { DefaultValuePipe } from './default-value.pipe';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
  declarations: [
    DigitPipe,
    IntToMonthPipe,
    DocumentIconPipe,
    PhoneFormatPipe,
    StateAbbrPipe,
    FileSizePipe,
    DefaultValuePipe,
  ],
  exports: [
    DigitPipe,
    IntToMonthPipe,
    DocumentIconPipe,
    PhoneFormatPipe,
    StateAbbrPipe,
    DefaultValuePipe,
    FileSizePipe,
  ],
  imports: [CommonModule],
  providers: [DecimalPipe, CurrencyPipe],
})
export class PipeModule {}
