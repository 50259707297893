import { AbsBaseComponent } from './abs-base-component';
import { WithLoading } from '@base/interfaces';

abstract class AbsComponentWithLoading extends AbsBaseComponent implements WithLoading {
  hasNext = false;

  next!: string | undefined;

  loadingState = true;
}

export { AbsComponentWithLoading };
