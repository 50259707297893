import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast, IndividualConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  options: Partial<IndividualConfig> = {
    closeButton: true,
    timeOut: 4999,
    enableHtml: true,
    progressAnimation: 'increasing',
    toastClass: 'ngx-toastr',
    positionClass: 'toast-top-right',
    titleClass: 'toast-title',
    messageClass: 'toast-message',
    tapToDismiss: true,
    newestOnTop: true,
  };

  constructor(private service: ToastrService) {}

  success(
    message: string,
    title: string,
    override?: Partial<IndividualConfig>,
  ): ActiveToast<unknown> {
    return this.service.success(message, title, {
      ...this.options,
      ...override,
    });
  }

  error(
    message: string,
    title: string,
    override?: Partial<IndividualConfig>,
  ): ActiveToast<unknown> {
    return this.service.error(message, title, {
      ...this.options,
      ...override,
    });
  }

  warning(
    message: string,
    title: string,
    override?: Partial<IndividualConfig>,
  ): ActiveToast<unknown> {
    return this.service.warning(message, title, {
      ...this.options,
      ...override,
    });
  }
}
