import { Injectable } from '@angular/core';
import { ID, Store, StoreConfig } from '@datorama/akita';
import { IDeviceState } from '@services/device-info/interfaces';
import { typeWrapper } from '@utils/types';

const createInitialState = (): IDeviceState => ({
  deviceID: typeWrapper(undefined),
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'device' })
export class DeviceStore extends Store<IDeviceState> {
  constructor() {
    super(createInitialState());
  }

  public setDeviceID(deviceID: ID): void {
    this.update({
      deviceID,
    });
  }
}
