import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoDataListComponent } from './components/no-data-list/no-data-list.component';
import { IconsModule } from '@widgets/icons';

@NgModule({
  declarations: [NoDataListComponent],
  exports: [NoDataListComponent],
  imports: [CommonModule, IconsModule],
})
export class NoDataModule {}
