import { WithActive, WithContentType, WithImage, WithPosition, WithUrl } from '@base/interfaces';
import { ISliderButton } from '@services/slider';

interface ISliderItem extends WithContentType, WithUrl, WithPosition, WithActive, WithImage {
  title: string;
  sub_title: string;
  url: string;

  primary_button?: ISliderButton;
  secondary_button?: ISliderButton;
}

export { ISliderItem };
