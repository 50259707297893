import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { AccountStore } from './account.store';
import { AccreditationStatus, IAccount } from '@services/account';
import { Observable } from 'rxjs';
import { typeWrapper } from '@utils/types';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountQuery extends Query<IAccount> {
  public account$: Observable<IAccount> = this.select((state: IAccount) => state).pipe(
    debounceTime(1000),
  );

  public fullName$: Observable<string> = this.account$.pipe(
    map(({ full_name: fullName }: IAccount) => typeWrapper(fullName)),
  );

  public accreditationStatus$: Observable<AccreditationStatus> = this.account$.pipe(
    map(({ accreditation_status }: IAccount) =>
      typeWrapper<AccreditationStatus>(accreditation_status),
    ),
  );

  constructor(protected store: AccountStore) {
    super(store);
  }

  public registrationFlowWarningVisibility$(withSession = true): Observable<boolean> {
    return this.account$.pipe(
      map((acc: IAccount) => {
        const hideRegistrationWarning = sessionStorage.getItem('hideRegistrationWarning');
        const notCompletedStatuses = [
          AccreditationStatus.pending_email,
          AccreditationStatus.pending,
          AccreditationStatus.form_pending,
        ];
        if (withSession) {
          return (
            notCompletedStatuses.includes(acc.accreditation_status) &&
            !toBoolean(hideRegistrationWarning)
          );
        } else {
          return notCompletedStatuses.includes(acc.accreditation_status);
        }
      }),
    );
  }
}
