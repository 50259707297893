enum SectionLabel {
  default = 'Section',
  categories = 'Categories',
  allCategories = 'All Categories',
  recentUpdates = 'Recent Updates',
  relatedContent = 'Related Content',
  featuredStories = 'Featured Stories',
  learn = 'Learn',
}

export { SectionLabel };
