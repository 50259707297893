<div class="modal-wrapper">
  <div
    ec-modal-header
    heading="Accreditation Expired"
  ></div>
  <div class="modal-body">
    <ec-text>Update your accreditation to maintain full access. </ec-text>
  </div>
  <div class="modal-footer">
    <button ec-button color="white" (click)="activeModal.close()">Close</button>
    <ng-container *ngIf="{ value: accreditationLink$ | async } as link">
      <button ec-button [disabled]="!link.value" color="primary" (click)="goToPandaDoc(link.value)">
        Update Accreditation
      </button>
    </ng-container>
  </div>
</div>
