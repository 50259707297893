import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomePage } from './pages';
import { CategoriesWModule } from '@widgets/categories';
import { RecentUpdatesWModule } from '@widgets/recent-updates';
import { CardsModule } from '@widgets/cards';
import { ButtonsModule } from '@widgets/buttons';
import { ContentSectionsModule } from '@widgets/content-sections';
import { CustomCarouselModule } from '@widgets/carousel/carousel.module';
import { EcHomeCarouselComponent } from './components';
import { PageModule } from '@widgets/page';
import { CallToActionModule } from '@widgets/call-to-action';

@NgModule({
  declarations: [HomePage, EcHomeCarouselComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ButtonsModule,
    ContentSectionsModule,
    CardsModule,
    CategoriesWModule,
    RecentUpdatesWModule,
    CustomCarouselModule,
    PageModule,
    CallToActionModule,
  ],
  exports: [HomePage],
})
export class HomeModule {}
