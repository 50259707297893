import { PhoneFormat } from '@services/auth';

class SignUpDto implements API.SignUp {
  email: string;

  first_name: string;

  is_accredited: boolean;

  is_agree_terms_and_use: boolean;

  is_licensed: boolean;

  last_name: string;

  password: string;

  primary_phone: string;

  primary_phone_format: PhoneFormat;

  constructor({
    email,
    first_name,
    is_accredited,
    is_agree_terms_and_use,
    is_licensed,
    last_name,
    password,
    primary_phone,
    primary_phone_format,
  }: SignUpDto) {
    this.email = email;
    this.first_name = first_name;
    this.is_accredited = is_accredited;
    this.is_agree_terms_and_use = is_agree_terms_and_use;
    this.is_licensed = is_licensed;
    this.last_name = last_name;
    this.password = password;
    this.primary_phone = primary_phone;
    this.primary_phone_format = primary_phone_format;
  }
}

export { SignUpDto };
