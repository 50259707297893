import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Generated class for the SafeHtmlPipe pipe.
 *
 */
@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(html: string): SafeHtml | null {
    if (!html) {
      return null;
    }

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
