import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AndroidAppComponent } from './components/android-app/android-app.component';
import { IosAppComponent } from './components/ios-app/ios-app.component';

@NgModule({
  declarations: [AndroidAppComponent, IosAppComponent],
  imports: [CommonModule],
  exports: [AndroidAppComponent, IosAppComponent],
})
export class AppsModule {}
