<div class="modal-wrapper">
  <div ec-modal-header heading="Filters"></div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
  </div>
  <div class="modal-footer">
    <a
      ec-link
      size="sm"
      class="w-50"
      (click)="form.reset(); activeModal.close()"
      [disabled]="!resettable"
    >
      Clear Filters
    </a>

    <button (click)="activeModal.close()" ec-button type="button">Apply Filters</button>
  </div>
</div>
