import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ec-reset-filters',
  templateUrl: './reset-filters.component.html',
  styleUrls: ['./reset-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetFiltersComponent {
  @Output() resetFiltersEmitter = new EventEmitter();

  resetFilters(): void {
    this.resetFiltersEmitter.emit();
  }
}
