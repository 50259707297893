<ng-container *ngIf="isMobile$ | async; then mobileView; else webView"></ng-container>

<ng-template #mobileView>
  <div class="category-content" [ngClass]="{ 'with-image': withImage }">
    <div class="category-content-image" *ngIf="withImage">
      <ng-container *ngIf="category.image; then withImage; else withoutImage"></ng-container>
      <ng-template #withImage>
        <div
          ec-category-card-overlay
          [isRestricted]="isRestricted({ visibility: category.visibility })"
        >
          <img [src]="category | categoryProp : 'image'" [alt]="category.name" />
        </div>
      </ng-template>
      <ng-template #withoutImage>
        <img src="assets/images/placeholders/article.png" [alt]="category.name" />
      </ng-template>
    </div>
    <main>
      <div class="category-content-header" ec-text-shortener lines="2" [maxHeight]="52">
        {{ category | categoryProp : 'name' }}
      </div>
      <div class="category-content-description">
        <ec-text weight="600">{{ category | categoryProp : 'content_count' }} posts</ec-text>
        <img
          *ngIf="isRestricted({ visibility: category.visibility }) && !withImage"
          class="icon-lock"
          src="assets/icons/lock.svg"
          alt="Restricted"
        />
      </div>
    </main>
  </div>
</ng-template>
<ng-template #webView>
  <div class="category-content" [ngClass]="{ 'with-image': withImage }">
    <div class="category-content-image" *ngIf="withImage">
      <ng-container *ngIf="category.image; then withImage; else withoutImage"></ng-container>
      <ng-template #withImage>
        <div
          ec-category-card-overlay
          [isRestricted]="isRestricted({ visibility: category.visibility })"
        >
          <img [src]="category | categoryProp : 'image'" [alt]="category.name" />
        </div>
      </ng-template>
      <ng-template #withoutImage>
        <img src="assets/images/placeholders/article.png" [alt]="category.name" />
      </ng-template>
    </div>
    <main>
      <div class="category-content-header" ec-text-shortener lines="2" [maxHeight]="52">
        {{ category | categoryProp : 'name' }}
      </div>
      <div class="category-content-description">
        <ec-text weight="600">{{ category | categoryProp : 'content_count' }} posts</ec-text>

        <ec-popover [popoverTemplate]="popoverTemplate" [popoverToggle]="popoverToggle">
          <ng-template #popoverToggle>
            <img
              *ngIf="isRestricted({ visibility: category.visibility }) && !withImage"
              class="icon-lock content-locked"
              src="assets/icons/lock.svg"
              alt="Restricted"
            />
          </ng-template>

          <ng-template #popoverTemplate>
            <div ec-unlock-content></div>
          </ng-template>
        </ec-popover>
      </div>
    </main>
  </div>
</ng-template>
