import { RegisterFormDto } from '@services/auth';
import { isNil } from '@datorama/akita';
import { WHITESPACE } from '@const';

class NameProcessing {
  fullName: string;

  constructor({ full_name }: Pick<RegisterFormDto, 'full_name'>) {
    this.fullName = full_name;
  }

  get firstName(): string | undefined {
    if (this.isNotEmpty()) {
      return this.fullName.split(WHITESPACE).find(Boolean);
    }
    return undefined;
  }

  get lastName(): string | undefined {
    if (this.isNotEmpty()) {
      const splitterArray: string[] = this.fullName.split(WHITESPACE);
      return splitterArray.length !== 1 ? splitterArray[splitterArray.length - 1] : undefined;
    }
    return undefined;
  }

  get middleName(): string | undefined {
    if (this.isNotEmpty() && this.containsMiddleName()) {
      const splitterArray: string[] = this.fullName.split(WHITESPACE);
      return splitterArray[1];
    }
    return undefined;
  }

  private containsMiddleName(): boolean {
    if (!this.isNotEmpty()) {
      return false;
    }
    return this.fullName.split(WHITESPACE).length > 2;
  }

  private isNotEmpty(): boolean {
    return !isNil(this.fullName) && this.fullName !== '';
  }
}

export { NameProcessing };
