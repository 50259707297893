import { MenuItemLabel } from '@core/menu';
import {Params} from '@angular/router';

interface IBreadcrumb {
  label: MenuItemLabel | string;
  route: string;
  params?: Params;
  children?: IBreadcrumb;
}

export { IBreadcrumb };

