import { WithContentTypeCheck, WithContentVisibility, WithVisibilityCheck } from '@base/interfaces';
import { AuthService } from '@services/auth';
import { ContentTypeUtility } from '@services/learn';
import { AbsComponentWithVisibilityCheck } from './abs-component-with-visibility-check';

abstract class AbsComponentWithContentCheck<ENTITY extends WithContentVisibility>
  extends AbsComponentWithVisibilityCheck<ENTITY>
  implements WithVisibilityCheck<ENTITY>, WithContentTypeCheck<ENTITY>
{
  protected constructor(authService: AuthService) {
    super(authService);
  }

  public isArticle({ type }: Pick<ENTITY, 'type'>): boolean {
    return ContentTypeUtility.isArticle({ type });
  }

  public isPodcast({ type }: Pick<ENTITY, 'type'>): boolean {
    return ContentTypeUtility.isPodcast({ type });
  }

  public isVideo({ type }: Pick<ENTITY, 'type'>): boolean {
    return ContentTypeUtility.isVideo({ type });
  }

  public isWebinar({ type }: Pick<ENTITY, 'type'>): boolean {
    return ContentTypeUtility.isWebinar({ type });
  }

  public isPlayable({ type }: Pick<ENTITY, 'type'>): boolean {
    return ContentTypeUtility.isPlayable({ type });
  }
}

export { AbsComponentWithContentCheck };
