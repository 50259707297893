<div class="sub-header">
  <div
    class="sub-header-element"
    *ngFor="let menuItem of items"
    [routerLink]="menuItem.route"
    routerLinkActive="sub-header-element--active"
  >
    {{ menuItem.label }}
  </div>
</div>
