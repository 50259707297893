import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentCardComponent, ContentOverlayComponent } from './components';
import { ContentPipe } from './pipes';
import { TypographyModule } from '@widgets/typography';
import { LockIconDirective } from './directives';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RestrictionMessagesModule } from '@widgets/restriction-messages';
import { IconsModule } from '@widgets/icons';
import { DropdownModule } from '@widgets/dropdown/dropdown.module';

@NgModule({
  declarations: [ContentCardComponent, ContentPipe, ContentOverlayComponent, LockIconDirective],
  imports: [
    CommonModule,
    TypographyModule,
    NgbPopoverModule,
    RestrictionMessagesModule,
    IconsModule,
    DropdownModule,
  ],
  exports: [ContentCardComponent, LockIconDirective],
})
export class ContentWModule {}
