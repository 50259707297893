import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InvestmentStatusNames } from '@widgets/icons/components/investment-status-icons/invest-status.interface';

@Component({
  selector: 'ec-investment-status-icons',
  template: `
    <div class="d-flex align-items-center justify-content-center">
      <span
        ec-icon-wrapper
        icon="document"
        [color]="psaStatuses.includes(status) ? 'blue' : 'light'"
      ></span>

      <span
        class="ms-2"
        ec-icon-wrapper
        icon="dollar"
        [color]="fundStatuses.includes(status) ? 'blue' : 'light'"
      ></span>
    </div>
  `,
  styleUrls: ['./investment-status-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestmentStatusIconsComponent {
  @Input() status: InvestmentStatusNames;

  readonly fundStatuses = [InvestmentStatusNames.Funds, InvestmentStatusNames.FundsAndPSA];

  readonly psaStatuses = [InvestmentStatusNames.PSA, InvestmentStatusNames.FundsAndPSA];
}
