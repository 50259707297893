import {Injectable} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {ConfirmModalComponent} from '@widgets/modal/components/confirm-modal/confirm-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '@services/auth/auth.service';
import {environment} from '@env/environment';

export const VIEW_PAGE_WITHOUT_LOGOUT_INACTIVITY_REDIRECT = 'viewPageWithoutLogoutInactivityRedirect';

const INACTIVITY_TIME_KEY = 'ecInactivityTimeAvailable';

const INACTIVITY_MODAL_RESULT_KEY = 'ecInactivityModalResult';

enum INACTIVITY_MODAL_RESULTS {
  close = 'close',
  logout = 'logout'
}

// 25 min
const INACTIVITY_TIME = 1500000;

// 5 min
const MODAL_TIME = 300000;

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  public isModalOpen: boolean;

  private inactivityTimerSubscription: Subscription;

  private modalTimerSubscription: Subscription;

  private modalInstance: NgbModalRef;

  constructor(
    private modal: NgbModal,
    private authService: AuthService,
  ) {
    if (!environment.production) {
      localStorage.removeItem(INACTIVITY_TIME_KEY);
      localStorage.removeItem(INACTIVITY_MODAL_RESULT_KEY);

      return;
    }
  }

  public setInactivityTime(): void {
    localStorage.setItem(INACTIVITY_TIME_KEY, (Date.now() + INACTIVITY_TIME).toString());
    this.setInactivityTimer();
  }

  public setInactivityTimer(): void {
    this.inactivityTimerSubscription?.unsubscribe();
    this.inactivityTimerSubscription = timer(+localStorage.getItem(INACTIVITY_TIME_KEY)! - Date.now())
      .subscribe(() => {
        if (this.authService.isAuthorized() && this.authService.getAccount().is_login_verified) {
          this.openLogoutModal();
        }
      });
  }

  public checkModalView(): void {
    const modalResult = localStorage.getItem(INACTIVITY_MODAL_RESULT_KEY);

    if (modalResult === INACTIVITY_MODAL_RESULTS.logout) {
      this.closeModalWindow(INACTIVITY_MODAL_RESULTS.logout, true);
    } else if (modalResult === INACTIVITY_MODAL_RESULTS.close) {
      this.closeModalWindow(INACTIVITY_MODAL_RESULTS.close, true);
    }
  }

  public isActivityTimeHasExpired(): boolean {
    if (+localStorage.getItem(INACTIVITY_TIME_KEY)! - Date.now() < 0) {
      this.authService.logout(!!localStorage.getItem(VIEW_PAGE_WITHOUT_LOGOUT_INACTIVITY_REDIRECT));
      return true;
    }

    return false;
  }

  private openLogoutModal(): void {
    this.isModalOpen = true;
    this.inactivityTimerSubscription.unsubscribe();

    localStorage.removeItem(INACTIVITY_MODAL_RESULT_KEY);

    this.modalInstance = this.modal.open(ConfirmModalComponent, {
      windowClass: 'modal-small',
      backdrop: true
    });

    this.modalTimerSubscription = timer(MODAL_TIME).subscribe(() => {
      this.authService.logout();
      this.modalInstance.close();
    });

    this.modalInstance.componentInstance.header = 'Are You Still There?';
    this.modalInstance.componentInstance.text =
      `We've noticed there hasn't been any activity for a while.
      For your security, we will log you out automatically in 5 minutes if there's no further activity.`;
    this.modalInstance.componentInstance.okButtonText = 'Log Out Now';
    this.modalInstance.componentInstance.cancelButtonText = 'Stay Logged In';
    this.modalInstance.result
      .then(() => {
        this.closeModalWindow(INACTIVITY_MODAL_RESULTS.logout);
      })
      .catch(() => {
        this.closeModalWindow(INACTIVITY_MODAL_RESULTS.close);
      });
  }

  private closeModalWindow(result: INACTIVITY_MODAL_RESULTS, skipLocalStorage?: boolean): void {
    if (!skipLocalStorage) {
      localStorage.setItem(
        INACTIVITY_MODAL_RESULT_KEY,
        result === INACTIVITY_MODAL_RESULTS.close ? INACTIVITY_MODAL_RESULTS.close : INACTIVITY_MODAL_RESULTS.logout
      );
    }

    if (result === INACTIVITY_MODAL_RESULTS.logout) {
      this.authService.logout();
    }

    this.isModalOpen = false;
    this.modalInstance.dismiss();

    this.modalTimerSubscription.unsubscribe();
    this.inactivityTimerSubscription.unsubscribe();

    this.setInactivityTime();
  }
}

