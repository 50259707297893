import { LearnDtoUnion, LearnLogicService } from '@services/learn';
import { SectionContentType } from '@widgets/content-sections';
import { WithElementsLimitation } from '@base/interfaces';

abstract class AbsComponentWithLearnSection {
  private readonly type!: SectionContentType;

  private readonly learnDTO?: Partial<LearnDtoUnion>;

  private readonly elementsLimitation?: WithElementsLimitation;

  protected constructor(
    protected readonly learnLogicService: LearnLogicService,
    type: SectionContentType,
    learnDTO?: Partial<LearnDtoUnion>,
    elementsLimitation?: WithElementsLimitation,
  ) {
    this.learnDTO = learnDTO;
    this.type = type;
    this.elementsLimitation = elementsLimitation;
  }

  public getDto(): LearnDtoUnion {
    return this.learnLogicService.getDto(this.type, this.learnDTO, this.elementsLimitation);
  }
}

export { AbsComponentWithLearnSection };
