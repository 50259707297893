import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from '@datorama/akita';
import { NameProcessing } from '@widgets/account';

@Pipe({
  name: 'firstName',
})
export class FirstNamePipe implements PipeTransform {
  transform(fullName: string | null | undefined): string {
    const nameProcessing: NameProcessing = new NameProcessing({
      full_name: fullName as string,
    });
    if (isNil(fullName)) {
      return 'Guest';
    } else {
      return nameProcessing.firstName ?? 'Guest';
    }
  }
}
