import { Injectable } from '@angular/core';
import { ClientService } from '@services/client';
import { BackendService, RequestFacadeModel, RequestModel, RequestType } from '@core/backend';
import { ChangePasswordDto, ForgotPasswordDto } from '@services/auth/dtos';
import {Observable, switchMap} from 'rxjs';
import { BaseStatusResponse } from '@base/classes';
import { ICheckToken } from '@services/auth/interfaces';
import {CsrfService} from '@services/auth/csrf.service';

@Injectable({
  providedIn: 'root',
})
export class ClientAuthService extends ClientService {
  constructor(backendService: BackendService, private csrfService: CsrfService) {
    super(backendService, 'auth');
  }

  public forgotPassword(forgotPasswordDto: ForgotPasswordDto): Observable<BaseStatusResponse> {
    const request: RequestModel<ForgotPasswordDto> = new RequestModel<ForgotPasswordDto>({
      url: this.getFullUrl('reset-password'),
      requestBody: forgotPasswordDto,
      skipNotify: true,
      skipRedirect: true
    });
    const requestFacade: RequestFacadeModel<ForgotPasswordDto> =
      new RequestFacadeModel<ForgotPasswordDto>({
        requestType: RequestType.post,
        request,
      });
    return this.send<BaseStatusResponse, ForgotPasswordDto>(requestFacade);
  }

  public changePassword(changePasswordDto: ChangePasswordDto): Observable<unknown> {
    const request: RequestModel<ChangePasswordDto> = new RequestModel<ChangePasswordDto>({
      url: this.getFullUrl('change-password'),
      requestBody: changePasswordDto,
      options: {
        withCredentials: true
      }
    });
    const requestFacade: RequestFacadeModel<ChangePasswordDto> =
      new RequestFacadeModel<ChangePasswordDto>({
        requestType: RequestType.post,
        request,
      });

    return this.csrfService.getCsrfToken().pipe(
      switchMap(() => {
        return this.send<unknown, ChangePasswordDto>(requestFacade)
      })
    );
  }

  public checkToken(token: string): Observable<ICheckToken> {
    const request: RequestModel = new RequestModel<null>({
      url: this.getFullUrl('is-valid'),
    }).withQuery<{ token: string }>({ token });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<ICheckToken, null>(requestFacade);
  }
}
