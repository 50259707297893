import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkComponent } from './components';
import { ButtonDirective } from './directives';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { IconsModule } from '@widgets/icons';
import { WatchVideoComponent } from './components/watch-video/watch-video.component';
import { ListenPodcastComponent } from './components/listen-podcast/listen-podcast.component';
import { VideoModalComponent } from './components/watch-video/video-modal/video-modal.component';
import { VideoModule } from '@widgets/video';
import { DownloadButtonComponent } from './components/download-button/download-button.component';
import { ButtonGroupComponent } from './components/button-group/button-group.component';
// import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { CopyButtonComponent } from '@widgets/buttons/components/copy-button/copy-button.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ButtonComponent } from './components/button/button.component';
import { SendAgainTimerComponent } from './components/send-again-timer/send-again-timer.component';
import { TimerModule } from '@widgets/timer/timer.module';
import { ResetFiltersComponent } from '@widgets/buttons/components/reset-filters/reset-filters.component';
import { FilterButtonComponent } from '@widgets/buttons/components/filter-button/filter-button.component';
import { BadgeModule } from '@widgets/badge/badge.module';
import { ButtonToggleGroupComponent } from './components/button-toggle-group/button-toggle-group.component';

@NgModule({
  declarations: [
    LinkComponent,
    ButtonDirective,
    IconButtonComponent,
    WatchVideoComponent,
    ListenPodcastComponent,
    VideoModalComponent,
    DownloadButtonComponent,
    ButtonGroupComponent,
    CopyButtonComponent,
    ButtonComponent,
    SendAgainTimerComponent,
    ResetFiltersComponent,
    FilterButtonComponent,
    ButtonToggleGroupComponent,
  ],
  imports: [
    CommonModule,
    IconsModule,
    VideoModule,
    // NgbButtonsModule,
    FormsModule,
    ClipboardModule,
    TimerModule,
    BadgeModule,
  ],
  exports: [
    LinkComponent,
    ButtonDirective,
    ButtonComponent,
    IconButtonComponent,
    WatchVideoComponent,
    ListenPodcastComponent,
    DownloadButtonComponent,
    ButtonGroupComponent,
    CopyButtonComponent,
    SendAgainTimerComponent,
    ResetFiltersComponent,
    FilterButtonComponent,
    ButtonToggleGroupComponent,
  ],
})
export class ButtonsModule {}
