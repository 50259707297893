interface WithElementsLimitation {
  /**
   * Elements to show
   *
   * @type {number}
   */
  showCount?: number;

  executor?: string;
}

export { WithElementsLimitation };
