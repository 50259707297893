<ec-content-section [props]="sectionProps">
  <ec-spinner-wrapper [loadingState]="loadingState"></ec-spinner-wrapper>

  <div class="categories">
    <div class="categories-element" *ngFor="let category of categories$ | async">
      <div ec-card view="minimal">
        <div ec-category-card-content [category]="category" [withImage]="false"></div>
      </div>
    </div>
    <div class="categories-view" *ngIf="!loadingState">
      <ec-view-all-card (clicked)="handleActionClick()" title="All Categories"></ec-view-all-card>
    </div>
  </div>
</ec-content-section>
