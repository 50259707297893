import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LayoutWrapperComponent,
  NavigationItemComponent,
  HeaderUserFlowComponent,
  FooterComponent,
  BreadcrumbComponent,
  HeaderComponent,
  SubHeaderComponent,
  MobileHeaderComponent,
} from './components';
import { LogosModule } from '@widgets/logos';
import { MenuModule } from '../menu';
import { ButtonsModule } from '@widgets/buttons';
import { LayoutService } from './services';
import { StaticContentModule } from '@services/content-facade';
import { DynamicBackgroundDirective } from './directives/dynamic-background.directive';

import { RouterModule } from '@angular/router';
import { NavigationItemsComponent } from './components/navigation-items/navigation-items.component';
import { AccountWModule } from '@widgets/account';
import { TypographyModule } from '@widgets/typography';
import { IconsModule } from '@widgets/icons';
import { GridModule } from '@widgets/grid';
import { DateModule } from '@widgets/date';
import { DividersModule } from '@widgets/dividers';
import { AppsModule } from '@widgets/apps';
import { SocialModule } from '@widgets/social';
import { WebFooterComponent } from './components/web-footer/web-footer.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterPartnerBoxComponent } from './components/web-footer/components/footer-partner-box/footer-partner-box.component';
import { FooterMenuItemComponent } from './components/web-footer/components/footer-menu-item/footer-menu-item.component';
import { FooterContactBoxComponent } from './components/web-footer/components/footer-contact-box/footer-contact-box.component';
import { BadgeModule } from '@widgets/badge/badge.module';

@NgModule({
  declarations: [
    LayoutWrapperComponent,
    NavigationItemComponent,
    HeaderUserFlowComponent,
    FooterComponent,
    DynamicBackgroundDirective,
    BreadcrumbComponent,
    HeaderComponent,
    NavigationItemsComponent,
    SubHeaderComponent,
    MobileHeaderComponent,
    WebFooterComponent,
    FooterPartnerBoxComponent,
    FooterMenuItemComponent,
    FooterContactBoxComponent,
  ],
  imports: [
    CommonModule,
    LogosModule,
    MenuModule,
    ButtonsModule,
    StaticContentModule,
    RouterModule,
    AccountWModule,
    TypographyModule,
    IconsModule,
    GridModule,
    DateModule,
    DividersModule,
    AppsModule,
    SocialModule,
    NgbDropdownModule,
    BadgeModule,
  ],
  providers: [LayoutService],
  exports: [LayoutWrapperComponent],
})
export class LayoutModule {}
