import { IContent } from '@services/learn';
import { ICategory } from '@services/categories';

interface IContentWithActualCategory {
  info: ICategory;
  featuredContent: IContent;
  content: IContent[];
  hasNext?: boolean;
  count?: number;
}

export { IContentWithActualCategory };
