import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PopoverContentService } from '../services';
import { guid, ID } from '@datorama/akita';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '.content-locked',
  exportAs: 'lockElement',
})
export class LockIconDirective implements OnDestroy {
  destroyed$: Subject<boolean> = new Subject<boolean>();

  private eventListenerExists = false;

  private hoverBeh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public id: ID;

  public hover$: Observable<boolean> = this.hoverBeh$
    .asObservable()
    .pipe(takeUntil(this.destroyed$));

  constructor(
    private element: ElementRef<HTMLImageElement>,
    private readonly popoverContentService: PopoverContentService,
  ) {
    this.id = guid();

    if (!this.eventListenerExists) {
      this.element.nativeElement.addEventListener('mouseenter', this.handleMouseEnter.bind(this));

      this.popoverContentService.addElement(this.id);
    }

    this.popoverContentService.close$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((elements: ID[]) => {
        if (elements.includes(this.id)) {
          this.hoverBeh$.next(false);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public setValue(value: boolean): void {
    this.hoverBeh$.next(value);
  }

  private handleMouseEnter(): void {
    this.eventListenerExists = true;
    this.hoverBeh$.next(true);
    this.popoverContentService.setActive(this.id);
  }
}
