import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { LearnLogicService } from '@services/learn';
import { IContentSectionProps, SectionContentType } from '@widgets/content-sections';
import { WithSectionPropsWithoutAction } from '@base/interfaces';
import { AbsComponentWithLearnSection } from '../abs-learn-section';
import { LEARN_SECTION_TEMPLATE } from '../../const';

@Component({
  selector: 'ec-learn-recent-updates',
  template: LEARN_SECTION_TEMPLATE,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnRecentUpdatesComponent
  extends AbsComponentWithLearnSection
  implements WithSectionPropsWithoutAction, OnDestroy
{
  @Input() sectionProps!: IContentSectionProps;

  constructor(learnLogicService: LearnLogicService) {
    super(
      learnLogicService,
      SectionContentType.recentUpdates,
      {
        most_recent: true,
      },
      { showCount: 3, executor: LearnRecentUpdatesComponent.name },
    );
  }

  ngOnDestroy(): void {
    this.learnLogicService.itemsLimitationsBeh$.next(undefined);
  }
}
