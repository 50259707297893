import { IContentSectionProps } from '@widgets/content-sections';

interface WithSectionProps {
  sectionProps: IContentSectionProps | null;
  handleActionClick(props?: IContentSectionProps): void;
}

type WithSectionPropsWithoutAction = Pick<WithSectionProps, 'sectionProps'>;

export { WithSectionProps, WithSectionPropsWithoutAction };
