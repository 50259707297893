import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoWrapperComponent } from './components';
import { SafeModule } from '@widgets/safe';
import { PlayerCoverImgComponent } from './components/player-cover-img/player-cover-img.component';
import { IconsModule } from '@widgets/icons';
import { VideoPlayerComponent } from './components/video-player/video-player.component';

@NgModule({
  declarations: [VideoWrapperComponent, PlayerCoverImgComponent, VideoPlayerComponent],
  imports: [CommonModule, SafeModule, IconsModule],
  exports: [VideoWrapperComponent, PlayerCoverImgComponent, VideoPlayerComponent],
})
export class VideoModule {}
