/* eslint-disable @typescript-eslint/member-ordering */

export class ApiConfig {
  /**
   * Base endpoints
   */
  static base = 'v1/';

  static portfolio = `${ApiConfig.base}client/portfolio/`;

  static docs = `${ApiConfig.base}client/docs/`;

  /**
   * Auth endpoints
   */
  static auth = `${ApiConfig.base}auth/`;

  static client = `${ApiConfig.base}client/`;

  static login = `${ApiConfig.auth}client/login/`;

  static tokenRefresh = `${ApiConfig.auth}refresh/`;

  static changePassword = `${ApiConfig.client}auth/change-password/`;

  static forgotPassword = `${ApiConfig.client}auth/reset-password/`;

  /**
   * Learn endpoints
   */
  static learnCategories = `${ApiConfig.client}learn/categories/`;

  /**
   * MFA endpoints
   */
  static authMFA = 'auth/mfa';

  static common = 'common';

  /**
   * Contact Document Reports
   */
  static contactPsaFunds = `${ApiConfig.docs}psa-and-funds/`;

  static contactCertificates = `${ApiConfig.docs}certificates/`;

  static contactReports = `${ApiConfig.docs}reports/`;

  static contactMineralDeeds = `${ApiConfig.docs}mineral-deeds/`;

  static contactLinkedAccounts = `${ApiConfig.portfolio}accounts/`;

  static contactLinkedProjects = `${ApiConfig.portfolio}accounts/`;

  static portfolioUpdates = `${ApiConfig.portfolio}updates/`;

  static portfolioUpdateProjects = `${ApiConfig.portfolioUpdates}projects/`;

  /**
   * Marketplace endpoints
   */
  static marketplace = `${ApiConfig.base}client/marketplace`;

  static marketplacePurchases = `${ApiConfig.base}client/marketplace/purchases/`;

  static marketplaceAccounts = `${ApiConfig.base}client/marketplace/accounts/`;

  static marketplaceTracts = (projectId: number) =>
    `${ApiConfig.marketplace}/projects/${projectId}/tracts/`;

  static marketplaceWellInTract = (projectId: number, tractId: number) =>
    `${ApiConfig.marketplaceTracts(projectId)}${tractId}/project-wells/`;

  static marketplaceWells = (projectId: number) =>
    `${ApiConfig.marketplace}/projects/${projectId}/wells`;

  static marketplaceProjectWells = (projectId: number) =>
    `${ApiConfig.marketplaceWells(projectId)}/project-wells/`;

  static marketDocumentsInWell = (projectId: number, wellId: number) =>
    `${ApiConfig.marketplaceWells(projectId)}/${wellId}/documents/`;

  static marketFilesInWell = (projectId: number, wellId: number) =>
    `${ApiConfig.marketplaceWells(projectId)}/${wellId}/files/`;

  static marketLocationsInWell = (projectId: number, wellId: number) =>
    `${ApiConfig.marketplaceWells(projectId)}/${wellId}/locations/`;

  static marketLocationTractsInWell = (projectId: number, wellId: number, sectionId: number) =>
    `${ApiConfig.marketLocationsInWell(projectId, wellId)}${sectionId}/tracts/`;

  /**
   * Account endpoints
   */
  static accounts = `${ApiConfig.portfolio}accounts/`;

  static sharedAccounts = `${ApiConfig.portfolio}accounts/shared/`;

  static projects = (accountId: number) => `${ApiConfig.accounts}${accountId}/projects/`;

  static accountInvitations = (accountId: number) =>
    `${ApiConfig.accounts}${accountId}/invitations/`;

  static accountInvestments = (accountId: number) =>
    `${ApiConfig.accounts}${accountId}/investments/`;

  static accountWells = (accountId: number) => `${ApiConfig.accounts}${accountId}/wells/`;

  static accountCashflow = (accountId: number) => `${ApiConfig.accounts}${accountId}/cashflow/`;

  static accountProjectInWells = (accountId: number, wellId: number) =>
    `${ApiConfig.accounts}${accountId}/wells/${wellId}/projects/`;

  static accountsProjectSummary = `${ApiConfig.accounts}by-project-type-summary/`;

  static accountProjectSummary = (accountId: number) =>
    `${ApiConfig.accounts}${accountId}/projects/by-project-type-summary/`;

  static accountsShort = `${ApiConfig.accounts}short/`;

  /**
   * Project endpoints
   */
  static project = (accountId: number, projectId: number) =>
    `${ApiConfig.projects(accountId)}${projectId}/`;

  static wells = (accountId: number, projectId: number) =>
    `${ApiConfig.project(accountId, projectId)}wells/`;

  static cashFlow = (accountId: number, projectId: number) =>
    `${ApiConfig.project(accountId, projectId)}cashflow/`;

  static cashFlowByWells = (accountId: number, projectId: number) =>
    `${ApiConfig.project(accountId, projectId)}cashflow/by-wells/`;

  static tracts = (accountId: number, projectId: number) =>
    `${ApiConfig.project(accountId, projectId)}tracts/`;

  static documents = (accountId: number, projectId: number) =>
    `${ApiConfig.project(accountId, projectId)}documents/`;

  static investments = (accountId: number, projectId: number) =>
    `${ApiConfig.project(accountId, projectId)}investments/`;

  static updates = (accountId: number, projectId: number) =>
    `${ApiConfig.project(accountId, projectId)}updates/`;

  /**
   * Tract endpoints
   */
  static wellsInTract = (accountId: number, projectId: number, tractId: number) =>
    `${ApiConfig.project(accountId, projectId)}tracts/${tractId}/wells/`;

  static tractInSection = (accountId: number, projectId: number, sectionId: number) =>
    `${ApiConfig.project(accountId, projectId)}locations/${sectionId}/tracts/`;

  /**
   * Well endpoints
   */
  static well = (accountId: number, projectId: number, wellId: number) =>
    `${ApiConfig.project(accountId, projectId)}wells/${wellId}/`;

  static cashFlowInWells = (accountId: number, projectId: number, wellId: number) =>
    `${ApiConfig.well(accountId, projectId, wellId)}cashflow/`;

  static documentInWells = (accountId: number, projectId: number, wellId: number) =>
    `${ApiConfig.well(accountId, projectId, wellId)}documents/`;

  static fileInWells = (accountId: number, projectId: number, wellId: number) =>
    `${ApiConfig.well(accountId, projectId, wellId)}files/`;

  static wellSection = (accountId: number, projectId: number, wellId: number) =>
    `${ApiConfig.well(accountId, projectId, wellId)}locations/`;

  static productionList = (accountId: number, projectId: number, wellId: number) =>
    `${ApiConfig.well(accountId, projectId, wellId)}production-table/`;

  /**
   * P2P endpoints
   */

  static p2pListing = `${ApiConfig.base}client/p2p-marketplace/listing/`;

  static p2pMyListing = `${ApiConfig.p2pListing}my-list/`;

  static p2pMyBids = `${ApiConfig.p2pListing}my-bids/`;

  static p2pPreCreate  = `${ApiConfig.p2pListing}pre-create/`;

  static p2pAccounts  = `${ApiConfig.p2pListing}accounts/`;

  static p2pProjects = (id: string)  => `${ApiConfig.p2pAccounts}${id}/projects/`;

  static p2pListingBids = (id: number)  => `${ApiConfig.p2pListing}${id}/bids/`;
}
