import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { LayoutModule } from '@core/layout';
import { BackendModule } from '@core/backend';
import { environment } from '@env/environment';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NgOtpInputModule } from 'ng-otp-input';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RefreshTokenInterceptor } from '@core/backend/services/refresh-token.interceptor';
import { EcDateAdapter, EcDateParserFormatter } from '@utils/adapters/date-picker.adapter';
import { WebsocketModule } from '@core/websocket';
import { NgSelectModule } from '@ng-select/ng-select';
import {CsrfInterceptor} from '@core/backend/services/csrf.interceptor';
import { ModalsSharedModule } from './modules/shared/modals-shared/modals-shared.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {PlatformInterceptor} from '@core/backend/services/platform.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    BackendModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    AkitaNgRouterStoreModule,
    LayoutModule,
    NgSelectModule,
    NgxMaskDirective,
    ToastrModule.forRoot(),
    NgbModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyBs43rfuUlF8dEs-09OiY4QtJArxc54d9o',
      authDomain: 'eckard-app.firebaseapp.com',
      projectId: 'eckard-app',
      storageBucket: 'eckard-app.appspot.com',
      messagingSenderId: '927777770347',
      appId: '1:927777770347:web:20ca001a1b1293ae211a2e',
    }),
    WebsocketModule.config({
      url: environment.ws,
    }),
    AngularFireMessagingModule,
    NgOtpInputModule,
    ModalsSharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformInterceptor,
      multi: true,
    },
    { provide: NgbDateAdapter, useClass: EcDateAdapter },
    { provide: NgbDateParserFormatter, useClass: EcDateParserFormatter },
    provideNgxMask({ dropSpecialCharacters: true }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.pipe(filter((e: any): e is Scroll => e instanceof Scroll)).subscribe(scroll => {
      if (scroll.routerEvent.url.includes('?')) {
        return;
      }

      if (!router.url.includes('ordering=')) {
        setTimeout(() => {
          viewportScroller.scrollToPosition([0, 0]);
        }, 100);
      }
    });
  }
}
