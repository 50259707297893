import { SectionLabel } from '../enums';
import { ISectionStyles, IBadgeProps, IContentProps } from '@widgets/content-sections';

interface IContentSectionProps {
  label: SectionLabel | string;
  badge?: IBadgeProps;
  content?: IContentProps;
  actionLabel?: string;
  sectionStyles?: ISectionStyles;
}

export { IContentSectionProps };
