import { Pipe, PipeTransform } from '@angular/core';
import { IAccount } from '@services/account';

@Pipe({
  name: 'account',
})
export class AccountPipe implements PipeTransform {
  transform(value: IAccount, key: keyof IAccount): unknown {
    switch (key) {
      case 'primary_phone':
        return value[key].replace(/\+1(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      default:
        return value[key];
    }
  }
}
