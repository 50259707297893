import { Component, HostBinding, Input } from '@angular/core';
import { AbsComponentWithLoading } from '@base/abs';

@Component({
  selector: 'ec-spinner-wrapper',
  templateUrl: './spinner-wrapper.component.html',
  styleUrls: ['./spinner-wrapper.component.scss'],
})
export class SpinnerWrapperComponent extends AbsComponentWithLoading {
  @Input() loadingState = false;

  @HostBinding('class.d-none')
  public get isHidden(): boolean {
    return !this.loadingState;
  }
}
