import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'page-content',
  template: `
    <ng-container>
      <ng-content></ng-content>
    </ng-container>
  `,
  styleUrls: ['./page-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageContentComponent {}
