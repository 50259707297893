import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { FilterModal } from '@widgets/modal/components/filter/filter.modal';

@Component({
  selector: '[ec-filter-button]',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterButtonComponent {
  @ViewChild('filters') initialTemplate!: TemplateRef<any>;

  @Input() form!: FormGroup;

  @Input() resettable: boolean;

  formFirstValue!: any;

  constructor(private readonly modal: NgbModal) {}

  openFilterWindow(): void {
    this.formFirstValue = this.form.value;

    const modal = this.modal.open(FilterModal, {
      centered: false,
      backdrop: true,
      keyboard: false,
    });

    const instance = modal.componentInstance as FilterModal;
    instance.currentTemplate = this.initialTemplate;
    instance.form = this.form;

    modal.dismissed.subscribe(res => {
      if (res) {
        // this.form.reset();
      } else {
        this.form.patchValue(this.formFirstValue);
      }
    });
  }
}
