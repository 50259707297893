import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ec-filter',
  templateUrl: './filter.modal.html',
  styleUrls: ['./filter.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterModal {
  currentTemplate!: TemplateRef<any>;

  form!: FormGroup;

  constructor(public readonly activeModal: NgbActiveModal) {}

  get resettable(): boolean {
    if (this.form && this.form.controls) {
      return Object.values(this.form.controls).some(
        (control: AbstractControl) => control.value && control.value.toString().length,
      );
    }
    return false;
  }
}
