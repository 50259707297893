import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentUpdateSectionComponent } from './components';
import { ContentSectionsModule } from '../content-sections';
import { CardsModule } from '../cards';
import { ContentWModule } from '../content';
import { SpinnerModule } from '@widgets/spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ButtonsModule } from '@widgets/buttons';
import { NoDataModule } from '@widgets/no-data/no-data.module';

@NgModule({
  declarations: [RecentUpdateSectionComponent],
  imports: [
    CommonModule,
    ContentSectionsModule,
    CardsModule,
    ContentWModule,
    SpinnerModule,
    InfiniteScrollModule,
    ButtonsModule,
    NoDataModule,
  ],
  exports: [RecentUpdateSectionComponent],
})
export class RecentUpdatesWModule {}
