import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '@widgets/icons';

@Component({
  selector: 'ec-icon-frame',
  template: `
    <div class="icon-frame">
      <div ec-icon-wrapper [icon]="icon" [color]="color" size="lg"></div>
    </div>
  `,
  styleUrls: ['./icon-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconFrameComponent {
  @Input() icon: Icon | string;

  @Input() color: 'blue' | 'green' | 'red' | 'gray' | 'white' | 'light' = 'blue';
}
