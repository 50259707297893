import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnInit,
} from '@angular/core';
import { combineLatest, Observable, timer } from 'rxjs';
import { LayoutService } from '@core/layout/services';
import { MenuItems, MenuTriggerState } from '@core/menu';
import { AuthService, NavigationElement } from '@services/auth';
import { ResponsiveService } from '@services/responsive';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import { LandingService } from '../../../../routing/landing/services/landing.service';
import { IAccreditationInfo } from '../../../../routing/landing/interfaces/landing.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { InactivityService } from '@services/auth/inactivity.service';
import { AccountService, IAccount } from '@services/account';
import { environment } from '@env/environment';

@Component({
  selector: '[ec-layout-wrapper]',
  templateUrl: './layout-wrapper.component.html',
  styleUrls: ['./layout-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutWrapperComponent implements OnInit, AfterViewInit {
  public hideMFAWarningButton = false;

  public accountWarningVisibilityWithSession$: Observable<boolean> =
    this.layoutService.accountService.accountWarningVisibilityWithSession;

  public notCompletedRegistration$: Observable<boolean> =
    this.layoutService.accountService.accountWarningVisibility;

  public isAuthorized$: Observable<boolean> = this.layoutService.isAuthorized$;

  public account$: Observable<IAccount> = this.accountService.account$;

  public fullName$: Observable<string> = this.layoutService.fullName$;

  public impersonate$: Observable<boolean> = this.layoutService.impersonate$;

  public menuItems$: Observable<MenuItems> = this.layoutService.menuItems$;

  public subHeaderItems$: Observable<MenuItems> = this.layoutService.subHeaderItems$;

  public subHeaderActive$: Observable<boolean> = this.layoutService.subHeaderActive$;

  public hasBreadcrumbs$: Observable<boolean> = this.layoutService.hasBreadcrumbs$;

  public hasContainer$: Observable<boolean> = this.layoutService.hasContainer$;

  public hasWrapper$: Observable<boolean> = this.layoutService.hasWrapper$;

  public hasFooter$: Observable<boolean> = this.layoutService.hasFooter$;

  public isMobileView$: Observable<boolean> = this.responsiveService.isTouch$;

  public isTouch$: Observable<boolean> = this.responsiveService.isTouch$;

  public triggerState$: Observable<MenuTriggerState> = this.layoutService.triggerState$;

  public accreditationLink$ = this.landingService.getAccreditationLink$();

  public menuOpen$: Observable<boolean> = combineLatest([
    this.triggerState$,
    this.isMobileView$,
  ]).pipe(map(([state, isMobile]) => state === MenuTriggerState.opened && isMobile));

  public fullName = '';

  public accreditationExpiration = false;

  public accreditationExpired = false;

  public expirationDays: number;

  public accreditationInfo: IAccreditationInfo;

  private isNewImpersonate = false;

  constructor(
    private readonly layoutService: LayoutService,
    private readonly responsiveService: ResponsiveService,
    private readonly inactivityService: InactivityService,
    private readonly cd: ChangeDetectorRef,
    private readonly accountService: AccountService,
    private readonly authService: AuthService,
    private readonly landingService: LandingService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
  ) {
    this.authService.isLogInRequested$.subscribe(res => {
      if (res) {
        this.getAccreditationInfo();
      } else {
        this.accreditationExpiration = false;
        this.accreditationExpired = false;
      }
    });
    this.ngZone.runOutsideAngular(() => {
      this.ngZone.run(() => {
        router.events.subscribe(() => {
          this.handleImpersonate();
          if (!!localStorage.getItem('logoutImpersonate')) {
            this.logout();
            return;
          }
        });
      });
    });
  }

  ngOnInit(): void {
    this.getAccreditationInfo();

    timer(100000)
      .pipe(take(1))
      .subscribe(() => {
        this.isNewImpersonate = false;
      });
  }

  getAccreditationInfo(): void {
    this.authService
      .getAccount$()
      .pipe(take(1))
      .subscribe(account => {
        if (account.accreditation_expired) {
          this.landingService
            .getAccreditationInfo()
            .pipe(
              take(1),
              debounceTime(1000),
              tap(info => {
                this.accreditationInfo = info;
                if (info.is_notified_expired_banner) {
                  return;
                }
                this.landingService.fetchAccreditationLink();
                this.accreditationExpired = true;
              }),
            )
            .subscribe();
        }
        if (!account.accreditation_expired && account.accreditation) {
          this.landingService
            .getAccreditationInfo()
            .pipe(
              take(1),
              debounceTime(1000),
              tap(info => {
                this.accreditationInfo = info;
                if (account.accreditation) {
                  if (info.is_notified_expiration_10_days_banner) {
                    return;
                  }
                  const expirationDate = new Date(account.accreditation.expiration);
                  const now = new Date();
                  const diffTime = Math.abs(expirationDate.getTime() - now.getTime());
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                  if (diffDays <= 10) {
                    this.landingService.fetchAccreditationLink();
                    this.expirationDays = diffDays;
                    this.accreditationExpiration = true;
                  }
                }
              }),
            )
            .subscribe();
        }
      });
  }

  hideAccreditationExpiry(): void {
    this.landingService
      .changeAccreditationInfo({ is_notified_expiration_10_days_banner: true })
      .pipe(
        take(1),
        tap(() => (this.accreditationExpiration = false)),
      )
      .subscribe();
  }

  hideAccreditationExpired(): void {
    this.landingService
      .changeAccreditationInfo({ is_notified_expired_banner: true })
      .pipe(
        take(1),
        tap(() => (this.accreditationExpired = false)),
      )
      .subscribe();
  }

  goToPandaDoc(link: string | null, reason: 'expired' | 'willExpired'): void {
    if (link) {
      if (reason === 'expired') {
        this.hideAccreditationExpired();
      }
      if (reason === 'willExpired') {
        this.hideAccreditationExpiry();
      }
      window.open(link, '_blank');
    }
  }

  get mfaWarningExpired(): boolean {
    const expirationTime = localStorage.getItem('hideMFA');
    const now = new Date();

    if (!expirationTime) {
      return false;
    }

    if (now.getTime() > +expirationTime) {
      localStorage.removeItem('hideMFA');
      return false;
    }

    this.hideMFAWarningButton = false;
    return true;
  }

  get showMFAWarning(): boolean {
    const hasMFA =
      this.layoutService.accountService.account.has_authenticator_app ||
      this.layoutService.accountService.account.has_sms_device;

    if (hasMFA) {
      return false;
    } else {
      return !this.mfaWarningExpired;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler() {
    this.handleImpersonate();
  }

  ngAfterViewInit(): void {
    this.layoutService.accountService.account$.subscribe(account => {
      this.fullName = account.full_name || '';
      this.layoutService.accountService.impersonateBeh$.next(!!localStorage.getItem('impersonate'));

      if (!!!localStorage.getItem('impersonate')) {
        if (environment.production) {
          if (this.inactivityService.isActivityTimeHasExpired()) {
            this.inactivityService.setInactivityTime();
          }
        }
      }

      this.cd.detectChanges();
    });
  }

  handleMenuTriggerClick(): void {
    this.layoutService.handleMenuTriggerClick();
  }

  handleLoginClick(): void {
    this.layoutService.navigate(NavigationElement.login);
  }

  handleRegisterClick(): void {
    this.layoutService.navigate(NavigationElement.register);
  }

  handleLogout(): void {
    this.layoutService.navigate(NavigationElement.logout);
  }

  hideWarning() {
    this.layoutService.accountService.hideAccountWarning();
  }

  hideMFAWarningWithExpiry(ttl = 86400000) {
    const now = new Date();
    this.hideMFAWarningButton = true;
    setTimeout(() => {
      localStorage.setItem('hideMFA', (now.getTime() + ttl).toString());
      this.cd.detectChanges();
    }, 3000);
  }

  logout(): void {
    localStorage.removeItem('logoutImpersonate');
    this.layoutService.accountService.logout();
    this.layoutService.navigate(NavigationElement.logout);
  }

  private handleImpersonate(): void {
    if (this.route.snapshot.queryParams.access && this.route.snapshot.queryParams.user) {
      this.isNewImpersonate = true;
    }

    timer(500)
      .pipe(take(1))
      .subscribe(() => {
        if (!!localStorage.getItem('impersonate')) {
          if (
            !this.isNewImpersonate &&
            new Date() > new Date(localStorage.getItem('impersonate') as string)
          ) {
            localStorage.setItem('logoutImpersonate', 'true');
          }
        }
      });
  }
}
