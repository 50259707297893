import { EntityState } from '@datorama/akita';
import { IContent } from '@services/learn';

interface IContentLocal extends IContent {
  seen?: boolean;
}

type IContentState = EntityState<IContentLocal>;

export { IContentState, IContentLocal };
