<div class="container">
  <section class="row mt-0">
    <div class="col-md-4 mb-md-0 mb-4">
      <ec-footer-partner-box img="logo-white" alt="Eckard Enterprises, LLC" linkTo="/">
        Eckard Enterprises, LLC, is a family-owned and operated energy asset management firm,
        focused on the U.S. energy industry. Eckard believes that owning tangible energy assets is
        one of the safest, long-term investment strategies available in today’s investment climate.
      </ec-footer-partner-box>
    </div>
    <div class="col-md-2 menu-box">
      <ec-footer-menu-item [menuItems]="menuItems"></ec-footer-menu-item>
    </div>
    <div class="col-md-3 col-6">
      <ec-footer-partner-box img="eckard_global" alt="Eckard Land" [linkTo]="globalLink">
        Eckard Global helps accredited investors own non-operated working interest in oil and gas
        wells located in major U.S. shale basins.
      </ec-footer-partner-box>
    </div>
    <div class="col-md-3 col-6">
      <ec-footer-partner-box img="eckard_land" alt="Eckard Land" [linkTo]="landLink">
        Eckard Land and Acquisition, LLC, is an oil and gas mineral company focused on acquiring
        minerals, maturing the mineral assets, and growing a strong mineral portfolio for its
        Partners.
      </ec-footer-partner-box>
    </div>
  </section>
</div>

<div ec-divider size="lg" color="transparent"></div>

<div class="container">
  <section class="row">
    <div class="col-md-4 col-5">
      <ec-footer-contact-box heading="Download App:">
        <div class="d-flex flex-wrap flex-md-row flex-column">
          <ec-ios-app [style.margin-right.px]="8"></ec-ios-app>
          <ec-android-app class="mt-2 mt-md-0"></ec-android-app>
        </div>
      </ec-footer-contact-box>
    </div>
    <div class="col-md-5 contact-box d-flex align-items-center">
      <ec-footer-contact-box heading="Contact Us:">
        <div class="d-flex flex-wrap flex-md-row flex-column">
          <div class="contact-wrapper">
            <div
              ec-icon-wrapper
              icon="contacts"
              [style.height.px]="24"
              class="d-flex align-items-center"
              size="md"
            ></div>
            <a href="mailto:{{ email }}">{{ email }}</a>
          </div>
          <div class="contact-wrapper">
            <div
              ec-icon-wrapper
              icon="phone_white"
              [style.height.px]="24"
              class="d-flex align-items-center"
              size="md"
            ></div>
            <a href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a>
          </div>
        </div>
      </ec-footer-contact-box>
    </div>
    <div class="col-md-3 col-7">
      <ec-footer-contact-box heading="Follow Us:" wrapperClass="justify-content-xl-end">
        <ec-social-links></ec-social-links>
      </ec-footer-contact-box>
    </div>

    <div class="col-md-9" [style.margin-top.px]="32">
      <p class="text">
        DISCLAIMER: The publicly-available information provided in and on this website is for
        informational purposes only. It provides business information about Eckard Enterprises, LLC.
        <br />
        This website is not intended to and does not constitute an offer to sell or the solicitation
        of an offer to purchase to any person in any jurisdiction. The contents of this website
        shall not be construed as legal, business or tax advise.
      </p>
    </div>
  </section>
</div>

<div ec-divider size="lg" color="transparent"></div>

<div class="container">
  <section class="row mb-0">
    <div class="col-12 d-flex align-items-center justify-content-between flex-column flex-md-row">
      <div class="links mb-md-0 mb-3">
        <a [href]="termsOfUse" target="_blank">Terms of Use</a> |
        <a [href]="privacyPolicy" target="_blank">Privacy Policy</a>
      </div>

      <div class="links">
        {{ copyRights }}
      </div>
    </div>
  </section>
</div>
