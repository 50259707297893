import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '@widgets/icons';

@Component({
  selector: 'ec-play-icon',
  template: `
    <div class="wrapper" *ngIf="icon">
      <div class="play-icon {{ icon }}" ec-icon-wrapper [icon]="iconName" color="blue"></div>
    </div>
  `,
  styleUrls: ['./play-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayIconComponent {
  @Input() icon!: 'microphone' | 'play';

  get iconName(): Icon {
    return `${this.icon}_black`;
  }
}
