<div class="info-panel">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
        class="breadcrumb-item"
        aria-current="page"
        *ngFor="let breadcrumb of breadcrumbs$ | async"
      >
        <a [routerLink]="breadcrumb.route" [queryParams]="breadcrumb.params ?? {}">
          {{ breadcrumb.label }}
        </a>
      </li>
    </ol>
  </nav>
</div>
