import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessNotificationComponent } from './components/success-notification/success-notification.component';
import { IconsModule } from '@widgets/icons';
import { ButtonsModule } from '@widgets/buttons';

@NgModule({
  declarations: [SuccessNotificationComponent],
  exports: [SuccessNotificationComponent],
  imports: [CommonModule, IconsModule, ButtonsModule],
})
export class NotificationModule {}
