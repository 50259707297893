import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {BackendService} from '@core/backend';
import {ApiConfig} from '@widgets/table/shared/api-config';


@Injectable()
export class PlatformInterceptor implements HttpInterceptor {
  constructor(private backendService: BackendService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!!this.backendService.userAgentData && request.url.includes(ApiConfig.base)) {
      request = this.addPlatformHeader(request, this.backendService.userAgentData);
      return next.handle(request);
    }

    return next.handle(request);
  }

  addPlatformHeader(request: HttpRequest<any>, userAgentData: any): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'x-PLATFORM': userAgentData.platform,
        'x-PLATFORM-VERSION': userAgentData.platformVersion,
      },
    });
  }
}
