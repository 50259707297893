import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ContentStore } from './content.store';
import { IContentState } from './content-state.interface';

@Injectable({ providedIn: 'root' })
export class ContentQuery extends QueryEntity<IContentState> {
  constructor(protected store: ContentStore) {
    super(store);
  }
}
