import { Injectable } from '@angular/core';
import { LearnService } from '../learn';
import { BackendService, RequestFacadeModel, RequestModel, RequestType } from '@core/backend';
import { Observable } from 'rxjs';
import { ICategory } from './interfaces';
import {
  BasePaginationDTO,
  DEFAULT_SECTION_ACTION_LABEL,
  IPaginatedResponse,
  WithVisibility,
} from '@base/index';
import { GetCategoriesDto } from './dtos';
import { Router } from '@angular/router';
import { IContentSectionProps, SectionContentType } from '@widgets/content-sections';
import { map } from 'rxjs/operators';
import { ROUTES } from '@const';
import { PermissionsUtility } from '@utils/permissions';
import { IAccount } from '@services/account';
import { AuthService } from '@services/auth';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService extends LearnService {
  constructor(
    backendService: BackendService,
    private router: Router,
    private authService: AuthService,
  ) {
    super(backendService, 'categories');
  }

  public getAll(
    categoriesDto: GetCategoriesDto | BasePaginationDTO,
  ): Observable<IPaginatedResponse<ICategory>> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl(null),
      skipNotify: true,
    }).withQuery<GetCategoriesDto | BasePaginationDTO>(categoriesDto);
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });
    return this.send<IPaginatedResponse<ICategory>>(requestFacade);
  }

  public viewAll(): void {
    this.router.navigate([`./${ROUTES.learn}/${ROUTES.categories}`]).then();
  }

  public getSectionProps$(
    categories$: Observable<ICategory[]>,
  ): Observable<IContentSectionProps[]> {
    return categories$.pipe(
      map((categories: ICategory[]) =>
        categories
          .filter((category: ICategory) => category.content_count)
          .map((category: ICategory) => {
            const currentUser: Pick<IAccount, 'accreditation_status'> =
              this.authService.getUserAccreditationStatus();

            const actionLabel = this.isRestricted({
              visibility: category.visibility,
              accreditation_status: currentUser.accreditation_status,
            })
              ? 'Unlock'
              : DEFAULT_SECTION_ACTION_LABEL;
            return {
              label: category.name,
              actionLabel,
              content: {
                id: category.id,
                label: category.name,
                type: SectionContentType.categories,
              },
            };
          }),
      ),
    );
  }

  private isRestricted({
    visibility,
    accreditation_status,
  }: Pick<WithVisibility, 'visibility'> & Pick<IAccount, 'accreditation_status'>): boolean {
    return PermissionsUtility.isRestricted({
      visibility,
      accreditation_status,
    });
  }
}
