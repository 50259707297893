import { Component, Input, ViewChild } from '@angular/core';
import { IContent } from '@services/learn';
import { VideoWrapperComponent } from '@widgets/video/components';

@Component({
  selector: 'ec-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent {
  @Input() content!: IContent;

  @ViewChild(VideoWrapperComponent) videoWrapper!: VideoWrapperComponent;
}
