<ec-ec-home-carousel></ec-ec-home-carousel>

<page>
  <div class="mobile-wrapper">
    <section>
      <ec-categories-view-wrapper></ec-categories-view-wrapper>
    </section>

    <section>
      <ec-recent-update-section
        [contentDto]="contentDto | async"
        [sectionProps]="contentProps | async"
      ></ec-recent-update-section>
    </section>

    <section
      class="invest-section"
      ec-invest-action-call
      [isAuthorized]="isAuthorized$ | async"
      [accreditationExpired]="accreditationExpired"
    ></section>
  </div>
</page>
