import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RowDirective, ColumnDirective } from './directives';

@NgModule({
  declarations: [RowDirective, ColumnDirective],
  imports: [CommonModule],
  exports: [RowDirective, ColumnDirective],
})
export class GridModule {}
