import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SocialElements } from '../types';
import { SOCIAL_ELEMENTS } from '../const';
import { IPost } from '../interfaces';
import { MOCKS } from '../mocks/post.mocks';

@Injectable()
export class StaticContentService {
  public socialElements$: BehaviorSubject<SocialElements> = new BehaviorSubject<SocialElements>(
    SOCIAL_ELEMENTS,
  );

  public getPosts(): Observable<IPost[]> {
    return of(MOCKS);
  }
}
