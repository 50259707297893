import { BaseEmailDto } from './base-email.dto';

class ChangePasswordDto extends BaseEmailDto {
  token: string;

  password: string;

  constructor({ email, token, password }: ChangePasswordDto) {
    super({ email });
    this.token = token;
    this.password = password;
  }
}

export { ChangePasswordDto };
