import {Injectable} from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiConfig} from '@widgets/table/shared/api-config';
import {CsrfService} from '@services/auth/csrf.service';
import {environment} from '@env/environment';

const includes = [
  ApiConfig.login,
  ApiConfig.changePassword,
  `${ApiConfig.client}account/set-password/`,
  `${ApiConfig.client}account/signup/`,
  `${ApiConfig.client}account/signup-invitation/`,
];

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(private csrfService: CsrfService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (includes.includes(request.url.replace(environment.apiUrl, ''))) {
      return next.handle(this.addCsrfHeader(request));
    }

    return next.handle(request);
  }

  addCsrfHeader(request: HttpRequest<any>): HttpRequest<any> {
    const csrfHeader = this.csrfService.token;
    if (csrfHeader) {
      return request.clone({
        setHeaders: {
          'X-CSRFTOKEN': csrfHeader,
          Cookie: 'csrftoken=' + csrfHeader
        },
      });
    }
    return request;
  }
}
