import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthStore } from './auth.store';
import { IAuthState, ITokens } from '../interfaces';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<IAuthState> {
  public getTokens$: Observable<ITokens> = this.select(({ access, refresh }: IAuthState) => ({
    access,
    refresh,
  }));

  public isAuthorized$: Observable<boolean> = this.select(
    ({ access }: IAuthState) => !!access ?? false,
  );

  constructor(protected store: AuthStore) {
    super(store);
  }
}
