import { BackendService, BaseApiService } from '@core/backend';
import { ecCreateLogger } from '@utils/logger';
import { Observable } from 'rxjs';
import { IPaginatedResponse } from '@base/interfaces';
import { ICategory } from '@services/categories';

const log = ecCreateLogger('core:base::learn');

abstract class LearnService extends BaseApiService {
  logger = { log };

  protected constructor(backendService: BackendService, apiExtension: string) {
    super(backendService, `client/learn/${apiExtension}`);
    this.logger.log(`Learn service extension: ${apiExtension}`);
  }

  public load<T = IPaginatedResponse<ICategory>>(apiEndPoint?: string): Observable<T> {
    return this.loadAll<T>(apiEndPoint);
  }
}

export { LearnService };
