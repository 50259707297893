import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLogoComponent } from './components';

@NgModule({
  declarations: [MainLogoComponent],
  imports: [CommonModule],
  exports: [MainLogoComponent],
})
export class LogosModule {}
