import { Observable } from 'rxjs';

export interface IWebsocketService {
  status: Observable<boolean | string>;
  on(event: IWsDTO): Observable<any>;
  send(event: string, data: any): void;
}

export interface WebSocketConfig {
  url: string;
  reconnectInterval?: number;
  reconnectAttempts?: number;
}

export interface IWsDTO {
  event: WsEventE;
  action: WsActionsE;
  params: any;
}

export interface IWsMessage<T> {
  action: WsActionsE;
  event: WsEventE;
  params: T;
}

export enum WsEventE {
  NOTIFICATION = 'NOTIFICATION',
}

export enum WsActionsE {
  ACCREDITATION_REVOKED = 'ACCREDITATION_REVOKED',
  ACCOUNT_ACCESS_REMOVED = 'ACCOUNT_ACCESS_REMOVED',
  ACCREDITATION_EXPIRED = 'ACCREDITATION_EXPIRED'
}
