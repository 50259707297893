import { IContentSectionProps } from '../interfaces';
import { SectionLabel } from '../enums';
import { DEFAULT_SECTION_ACTION_LABEL } from '@base/const';

const DEFAULT_SECTION_PROPS: IContentSectionProps = {
  label: SectionLabel.default,
  actionLabel: DEFAULT_SECTION_ACTION_LABEL,
};

export { DEFAULT_SECTION_PROPS };
