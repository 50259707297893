export enum FileTypes {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  PNG = 'png',
  MP4 = 'mp4',
  JPEG = 'jpeg',
  JPG = 'jpg',
  GIF = 'gif',
  XLS = 'xls',
  XLSX = 'xlsx',
}

export const imageFileTypes = [FileTypes.PNG, FileTypes.JPG, FileTypes.GIF];

export const fileExtensions = {
  [FileTypes.PDF]: 'PDF',
  [FileTypes.DOC]: 'DOC',
  [FileTypes.DOCX]: 'DOCX',
  [FileTypes.PNG]: 'PNG',
  [FileTypes.JPG]: 'JPG',
  [FileTypes.GIF]: 'GIF',
  [FileTypes.XLS]: 'XLS',
  [FileTypes.XLSX]: 'XLSX',
};

export const extractFileNameFromLink = (link: string): string => {
  if (!link) {
    return '';
  }
  return link.split('?')[0].split('/').reverse()[0];
};
