import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'download-button',
  template: `
    <button>
      <div ec-icon-wrapper icon="download" size="md" color="blue"></div>
      <span>
        <ng-content></ng-content>
      </span>
    </button>
  `,
  styleUrls: ['./download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadButtonComponent {}
