import { Injectable } from '@angular/core';
import {
  BIG_FONT_SIZE,
  IContentSectionProps,
  SectionContentType,
  SectionLabel,
} from '@widgets/content-sections';
import { GetContentDto } from '@services/content/dtos';
import { GetCategoriesDto } from '@services/categories/dtos';
import { BasePaginationDTO } from '@base/classes';
import { LearnDtoUnion } from '@services/learn';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { LEARN_PAGES_SECTIONS } from '../../routing/learn/const';
import { WithElementsLimitation } from '@base/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LearnLogicService {
  public itemsLimitationsBeh$: BehaviorSubject<WithElementsLimitation | undefined> =
    new BehaviorSubject<WithElementsLimitation | undefined>(undefined);

  private dynamicSectionsBeh$: BehaviorSubject<IContentSectionProps[]> = new BehaviorSubject<
    IContentSectionProps[]
  >([]);

  private defaultLearnPagePagination: BasePaginationDTO = new BasePaginationDTO({
    page: 1,
    page_size: 3,
  });

  public dynamicSections$: Observable<IContentSectionProps[]> =
    this.dynamicSectionsBeh$.asObservable();

  public getDto(
    type?: SectionContentType,
    dto?: Partial<LearnDtoUnion>,
    elementsLimitation?: WithElementsLimitation,
  ): LearnDtoUnion {
    if (elementsLimitation) {
      this.itemsLimitationsBeh$.next(elementsLimitation);
    }
    switch (type) {
      case SectionContentType.categories:
        return new GetCategoriesDto({
          ...this.defaultLearnPagePagination,
          ...dto,
        });
      case SectionContentType.recentUpdates:
        return new GetContentDto({
          ...this.defaultLearnPagePagination,
          ...dto,
          page_size: 12,
        });
      case SectionContentType.featuredStories:
        return new GetContentDto({
          ...this.defaultLearnPagePagination,
          ...dto,
        });
      default:
        return new BasePaginationDTO({ page: 1, page_size: 15 });
    }
  }

  public learnPageSections$(): Observable<IContentSectionProps[]> {
    return of(LEARN_PAGES_SECTIONS);
  }

  public sectionPropsByRoute$(route: ActivatedRoute): Observable<IContentSectionProps> {
    return combineLatest([route.queryParams, route.data]).pipe(
      map(([queryParams, data]) => {
        const containsFeatured: boolean = queryParams.featured || data.query?.featured;
        if (containsFeatured) {
          return {
            label: SectionLabel.featuredStories,
            sectionStyles: { fontSize: BIG_FONT_SIZE },
          };
        }
        return {
          label: SectionLabel.recentUpdates,
          sectionStyles: { fontSize: BIG_FONT_SIZE },
        };
      }),
    );
  }

  public withVirtualScrollByRoute$(route: ActivatedRoute): Observable<boolean> {
    return combineLatest([route.queryParams, route.data]).pipe(
      map(([queryParams, data]) => {
        const containsRecent = queryParams.most_recent || data.query?.most_recent;

        return !containsRecent;
      }),
    );
  }

  public contentDtoByRoute$(route: ActivatedRoute): Observable<GetContentDto> {
    return combineLatest([route.queryParams, route.data]).pipe(
      map(([queryParams, data]) => {
        const containsFeatured: boolean = queryParams.featured || data.query?.featured;

        return new GetContentDto({
          page: 1,
          page_size: containsFeatured ? 15 : 12,
          most_recent: queryParams.most_recent ?? data.query?.most_recent,
          featured: queryParams.featured ?? data.query?.featured,
        });
      }),
    );
  }
}
