import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'page-section',
  template: `
    <section>
      <header *ngIf="heading">
        <h2>
          {{ heading }}
        </h2>
      </header>
      <main>
        <ng-content></ng-content>
      </main>
    </section>
  `,
  styleUrls: ['./page-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSectionComponent {
  @Input() heading!: string;
}
