import { BackendService, BaseApiService } from '@core/backend';
import { ecCreateLogger } from '@utils/logger';

const log = ecCreateLogger('core:base::client');

abstract class ClientService extends BaseApiService {
  logger = { log };

  protected constructor(backendService: BackendService, apiExtension: string) {
    super(backendService, `client/${apiExtension}`);
    this.logger.log(`Client service extension: ${apiExtension}`);
  }
}

export { ClientService };
