import {Injectable, Injector, Optional} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpResponseModel, RequestFacadeModel, RequestModel } from '../models';
import { BackendConfig } from '../config';
import { RequestType } from '../enum';
import { ecCreateLogger } from '@utils/logger';
import { LoadingService } from '@core/backend/services/loading.service';
import { NotificationsService } from '@services/notifications';
import {AuthService} from '@services/auth';

const log = ecCreateLogger('core:backend');

type RequestData = Pick<
  RequestModel,
  'successMessage' | 'shouldIndicateLoader' | 'errorMessage' | 'skipNotify' | 'skipRedirect'
>;

/**
 * Our awesome backend service, to provide the same syntax around all requests, with pre-settled error-handling and success notifications
 *
 * @export
 * @class BackendService
 */
@Injectable({
  providedIn: 'root',
})
export class BackendService {
  logger = { log };

  public userAgentData: any = undefined;

  /**
   * In case u need some specific headers, that will be appended to every request, just update this values
   *
   * @type BehaviorSubject<{ [key: string]: string } | null>
   * @memberOf BackendService
   */
  private $headers: BehaviorSubject<{ [key: string]: string } | null> = new BehaviorSubject<{
    [key: string]: string;
  } | null>(null);

  private readonly apiUrl: string;

  /**
   * Creates an instance of BackendService.
   *
   * @param config
   * @param http
   * @param router
   * @param loadingService
   * @param notificationsService
   * @param injector
   * @memberof BackendService
   */
  constructor(
    @Optional() config: BackendConfig,
    private http: HttpClient,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationsService: NotificationsService,
    private readonly injector: Injector
  ) {
    this.logger.log(`Backend Service options: ${JSON.stringify(config)}`);
    this.apiUrl = config.apiUrl;
    if ((window.navigator as any).userAgentData) {
      (window.navigator as any).userAgentData
        .getHighEntropyValues(['platformVersion'])
        .then((data: any) => (this.userAgentData = data));
    }
  }

  /**
   * Add Header to BehaviourSubject, or create the new one
   *
   * @memberof BackendService
   * @param header
   */
  setHeader(header: { [key: string]: string }): void {
    const currentHeaders = this.$headers.getValue();
    if (currentHeaders) {
      this.$headers.next({ ...currentHeaders, ...header });
    } else {
      this.$headers.next(header);
    }
  }

  /**
   * Removes Header to BehaviourSubject by key
   *
   * @memberof BackendService
   * @param header
   */
  removeHeader(header: string): void {
    const currentHeaders = this.$headers.getValue();
    if (currentHeaders) {
      delete currentHeaders[header];
      this.$headers.next(currentHeaders);
    }
  }

  /**
   * Facade around all send api request(according to requestType enum)
   *
   * @template T
   * @template R
   * @param facade
   * @return
   * @memberof BackendService
   */
  send = <T, R = null>(facade: RequestFacadeModel<R>): Observable<T> => {
    if (!facade.request.preventDefaultHeaders) {
      facade.request = this.setHeaders<R>(facade.request);
    }

    switch (facade.requestType) {
      case RequestType.get:
        return this.get<T>(facade.request as unknown as RequestModel);
      case RequestType.post:
        return this.post<T, R>(facade.request);
      case RequestType.patch:
        return this.patch<T, R>(facade.request);
      case RequestType.put:
        return this.put<T, R>(facade.request);
      case RequestType.delete:
        return this.delete<T>(facade.request as unknown as RequestModel);
      default:
        return this.get<T>(facade.request as unknown as RequestModel);
    }
  };

  /**
   * Proceed full request with standardized error-handling and success notifications
   *
   * @private
   * @template T
   * @param request
   * @param data
   * @return
   * @memberof BackendService
   */
  private proceedFullRequest<T>(
    request: Observable<HttpResponseModel<T>>,
    data: RequestData,
  ): Observable<T> {
    this.loadingService.loadingState$.next(data.shouldIndicateLoader);
    return request.pipe(
      finalize(() => this.loadingService.loadingState$.next(false)),
      map((response: HttpResponseModel<T>) => {
        if (data.successMessage) {
          this.notificationsService.success(data.successMessage.message, data.successMessage.title);
        }
        if (response?.data) {
          return response.data;
        }
        return response as unknown as T;
      }),
      catchError(err => this.handleError(err, data)),
    );
  }

  /**
   * Error-handler
   *
   * @private
   * @memberof BackendService
   */
  private handleError = (error: HttpErrorResponse, data: RequestData) => {
    // const currentHeader = this.$headers.getValue();

    // if (error.status === 401 && currentHeader?.Authorization) {
    //   this.router.navigate(['auth/logout']).then();
    // }

    if (error.url?.includes('auth/mfa')) {
      return throwError(() => error);
    }

    if (error.status === 400 && error.error.status === 'Registered') {
      return throwError(() => error);
    }

    if (
      (error.status === HttpStatusCode.NotFound && !data.skipRedirect) ||
      (error.status === HttpStatusCode.Forbidden && !data.skipRedirect)
    ) {
      void this.router.navigate(['/learn']);
    }

    if (error.status === HttpStatusCode.Forbidden && error.error.is_access_denied) {
      const auth = this.injector.get(AuthService);
      auth.logout();
    }

    /*
     * if url include **Undefined**, then we do not need to display a notification, it can be a check for the existence of an object
     * */
    if (!error.url?.includes('undefined')) {
      /*
       * the notification handle only the **Object**, if another data type is returned, then the BE must be informed about it
       * */
      if (typeof error.error === 'object') {
        const errorObj = error.error;
        const keys = Object.keys(errorObj);

        if (!data.skipNotify && errorObj.code !== 'token_not_valid') {
          keys.forEach(key => {
            const title = key.replace('_', ' ').toUpperCase();
            const value = errorObj[key];

            const isString = typeof value === 'string';

            if (Array.isArray(value) || isString) {
              if (title !== 'REFRESH') {
                if (value?.includes('You do not have permission')) {
                  void this.router.navigate(['/learn']);
                }
                this.notificationsService.error(value as string, title);
              }
            } else {
              const subKeys = Object.keys(value);
              subKeys.forEach(sK => {
                const subTitle = sK.replace('_', ' ').toUpperCase();
                this.notificationsService.error(value[sK], subTitle);
              });
            }
          });
        }
      } else {
        this.notificationsService.error('Something went wrong', `Error - ${error.status}`);
      }
    }
    return throwError(() => error);
  };

  /**
   * Combines url sent from services with apiHost
   *
   * @private
   * @template R
   * @param request
   * @return
   * @memberof BackendService
   */
  private getFullUrl<R>(request: RequestModel<R>): string {
    return request.customUrl
      ? request.url
        ? `${request.customUrl}${request.url}`
        : request.customUrl
      : `${this.apiUrl}${request.version}/${request.url}`;
  }

  /**
   * GET method (called from send() method)
   *
   * @private
   * @template T
   * @param request
   * @return
   * @memberof BackendService
   */
  private get<T>(request: RequestModel): Observable<T> {
    this.logger.log(`Processing GET request with url: ${request.url}`);
    return this.proceedFullRequest<T>(
      this.http.get<HttpResponseModel<T>>(this.getFullUrl<null>(request), request.options),
      request,
    );
  }

  /**
   * POST method (called from send() method)
   *
   * @private
   * @template T
   * @template R
   * @param request
   * @return
   * @memberof BackendService
   */
  private post<T, R>(request: RequestModel<R>): Observable<T> {
    this.logger.log(`Processing POST request with url: ${request.url}`);
    return this.proceedFullRequest<T>(
      this.http.post<HttpResponseModel<T>>(
        this.getFullUrl<R>(request),
        request.requestBody,
        request.options,
      ),
      request,
    );
  }

  /**
   * PUT method (called from send() method)
   *
   * @private
   * @template T
   * @template R
   * @param request
   * @return
   * @memberof BackendService
   */
  private put<T, R>(request: RequestModel<R>): Observable<T> {
    this.logger.log(`Processing PUT request with url: ${request.url}`);
    return this.proceedFullRequest<T>(
      this.http.put<HttpResponseModel<T>>(
        this.getFullUrl<R>(request),
        request.requestBody,
        request.options,
      ),
      request,
    );
  }

  /**
   * PATCH method (called from send() method)
   *
   * @private
   * @template T
   * @template R
   * @param request
   * @return
   * @memberof BackendService
   */
  private patch<T, R>(request: RequestModel<R>): Observable<T> {
    this.logger.log(`Processing PATCH request with url: ${request.url}`);
    return this.proceedFullRequest<T>(
      this.http.patch<HttpResponseModel<T>>(
        this.getFullUrl<R>(request),
        request.requestBody,
        request.options,
      ),
      request,
    );
  }

  /**
   * DELETE method (called from send() method)
   *
   * @private
   * @template T
   * @param request
   * @return
   * @memberof BackendService
   */
  private delete<T>(request: RequestModel): Observable<T> {
    this.logger.log(`Processing DELETE request with url: ${request.url}`);
    return this.proceedFullRequest<T>(
      this.http.delete<HttpResponseModel<T>>(this.getFullUrl<null>(request), request.options),
      request,
    );
  }

  /**
   * Function that modifies existing request to append with headers that come from BehaviourSubject
   *
   * @private
   * @template R
   * @param request
   * @return
   * @memberof BackendService
   */
  private setHeaders<R>(request: RequestModel<R>): RequestModel<R> {
    const headers = this.$headers.getValue();
    if (headers) {
      Object.keys(headers).forEach(h => {
        if (headers[h]) {
          request.addHeader(h, headers[h]);
        }
      });
    }
    return request;
  }
}
