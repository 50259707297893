import { SocialElements } from '../types';
import { guid } from '@datorama/akita';

const SOCIAL_ELEMENTS: SocialElements = [
  {
    id: guid(),
    image: 'facebook',
  },
  {
    id: guid(),
    image: 'linkedin',
  },
  {
    id: guid(),
    image: 'youtube',
  },
  {
    id: guid(),
    image: 'twitter',
  },
];

export { SOCIAL_ELEMENTS };
