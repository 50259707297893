import { AccreditationStatus, UserStatus } from '@services/account';

interface IAccountMfa extends API.ClientSProfile {
  has_authenticator_app: boolean;
  has_sms_device: boolean;
  has_recovery_codes: boolean;
  is_blocked: boolean;
  is_login_verified: boolean;
  is_two_factor_enabled: boolean;
}

interface IAccount extends Omit<IAccountMfa, 'accreditation_status' | 'app_status'> {
  first_name?: string;
  accreditation_status: AccreditationStatus;
  accreditation_expired?: boolean;
  approval_screen_shown?: boolean;
  app_status: UserStatus;
  date_of_birth?: string;
  prefix?: string;
  marital?: IMarital;
  address?: IAddress;
  marital_status?: MaritalStatus;
  count_investments?: number;
  count_shared_accounts?: number;
  count_accounts?: number;
  count_cart?: number;
  is_address_editable?: boolean;
  unseen_updates?: boolean;
  unseen_updates_count?: number;
  show_money?: boolean;
  wealth_manager?: IWealthManager;
  has_psa_and_funds_active_investments?: boolean;
  p2p_terms_and_conditions?: boolean;
  accreditation?: IAccreditation;
  title?: ContactTitle;
  profile_photo_user: string;
}

export interface IAccreditation {
  expiration: string;
  signed_accreditation_on: string;
}

export interface IWealthManager {
  email: string;
  full_name: string;
  image: string | null;
  phone: string;
}

interface IMFAPhone {
  primary_phone: string;
  available_for_mfa: boolean;
  phone_for_mfa: string;
}

interface IMarital {
  first_name: string;
  last_name: string;
  middle_name: string;
  prefix: string;
  email: string;
}

interface IAddress {
  attention_to: string;
  city: string;
  name: string;
  note: string;
  state: string;
  street: string;
  zip_code: string;
  is_contact_address?: boolean;
}

export { IAccount, IMFAPhone, IAddress };

export enum MaritalStatus {
  Single = 'Single',
  Married = 'Married',
}

export enum ContactTitle {
  Individual = 'Individual',
  Custodian = 'Custodian',
  President = 'President',
  Trustee = 'Trustee',
  Other = 'Other'
}

export enum ContactPrefix {
  Mr = 'Mr',
  Ms = 'Ms',
  Mrs = 'Mrs',
  Dr = 'Dr',
}

export enum PhoneFormatE {
  'Undefined' = 'Undefined',
  Mobile = 'Mobile',
  Landline = 'Landline',
}

export const TITLE_OPTIONS = [
  { value: ContactTitle.Custodian },
  { value: ContactTitle.Individual },
  { value: ContactTitle.Other },
  { value: ContactTitle.President },
  { value: ContactTitle.Trustee },
];
