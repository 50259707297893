import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ec-cover-wrapper',
  template: `
    <img *ngIf="coverSrc" class="cover-img cover-img-{{type}}" [src]="coverSrc" alt="Cover"  />
    <div *ngIf="!coverSrc" class="placeholder placeholder-{{type}}">
      <img [src]="coverPlaceholder" alt="Cover"  />
    </div>
  `,
  styleUrls: ['./cover-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverWrapperComponent {
  @Input() coverSrc?: string;

  @Input() type: 'default' | 'listing' = 'default';

  coverPlaceholder = '/assets/images/placeholders/placeholder_logo.svg';
}
