import { Injectable } from '@angular/core';
import { ClientService } from '@services/client';
import { BackendService, RequestFacadeModel, RequestModel, RequestType } from '@core/backend';
import { BasePaginationDTO } from '@base/classes';
import { IPaginatedResponse } from '@base/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISliderItem } from '@services/slider/interfaces';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SliderService extends ClientService {
  private sliderItemsBeh$: BehaviorSubject<ISliderItem[]> = new BehaviorSubject<ISliderItem[]>([]);

  public sliderItems$: Observable<ISliderItem[]> = this.sliderItemsBeh$.asObservable();

  constructor(backendService: BackendService) {
    super(backendService, 'hero-slider');
    this.getAll(new BasePaginationDTO({ page: 1, page_size: 10 }));
  }

  public getAll(paginationDTO: BasePaginationDTO): void {
    const request: RequestModel<null> = new RequestModel<null>({
      url: this.getFullUrl(null),
      requestBody: null,
    }).withQuery({...paginationDTO, image: 'web'});
    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    this.send<IPaginatedResponse<ISliderItem>, null>(requestFacade)
      .pipe(
        map(({ results }: IPaginatedResponse<ISliderItem>) =>
          results.map((item: ISliderItem, index: number) => ({
            ...item,
            active: index === 0,
          })),
        ),
        tap((items: ISliderItem[]) => this.sliderItemsBeh$.next(items)),
      )
      .subscribe();
  }

  public changeActiveItem(activeIndex: number): void {
    const items: ISliderItem[] = this.sliderItemsBeh$.getValue();
    this.sliderItemsBeh$.next(
      items.map((item: ISliderItem, index: number) => ({
        ...item,
        active: +activeIndex === index,
      })),
    );
  }
}
