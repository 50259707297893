import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  loadProfile,
  loadAccounts,
  loadAuth,
  loadHome,
  loadLearn,
  loadAccountInvitation,
  loadMarket,
  loadUpdates,
  loadLanding, loadP2P, loadUnsubscribe,
} from '@loader';
import { AuthGuard, PortfolioGuard } from './modules/guards';
import { ROUTES } from '@const';
import { buildRoute } from '@utils/routing';
import { LearnResolver } from './modules/routing/learn';
import { MfaGuard } from './modules/guards/mfa.guard';
import {P2PGuard} from './modules/guards/p2p.guard';

const routes: Routes = [
  {
    path: ROUTES.default,
    redirectTo: ROUTES.home,
    pathMatch: 'full',
  },
  buildRoute({ path: ROUTES.home, loadChildren: loadHome }),
  buildRoute({
    path: ROUTES.howItWorks,
    redirectTo: ROUTES.home,
    pathMatch: 'full',
  }),
  buildRoute({
    path: ROUTES.company,
    redirectTo: ROUTES.home,
    pathMatch: 'full',
  }),
  buildRoute({
    path: ROUTES.learn,
    loadChildren: loadLearn,
    canActivate: [MfaGuard],
    // data: { parent: ROUTES.home },
    resolve: { subHeader: LearnResolver },
  }),
  buildRoute({
    path: ROUTES.market,
    loadChildren: loadMarket,
    // data: { parent: ROUTES.home },
    // canActivate: [AuthGuard, PortfolioGuard, MfaGuard],
  }),
  buildRoute({
    path: ROUTES.landing,
    loadChildren: loadLanding,
    canActivate: [MfaGuard],
  }),
  buildRoute({
    path: ROUTES.updates,
    loadChildren: loadUpdates,
  }),
  {
    path: ROUTES.auth,
    loadChildren: loadAuth,
  },
  {
    path: ROUTES.profile,
    loadChildren: loadProfile,
    canActivate: [AuthGuard, MfaGuard],
  },
  {
    path: ROUTES.accounts,
    loadChildren: loadAccounts,
    canActivate: [AuthGuard, PortfolioGuard, MfaGuard],
  },
  {
    path: ROUTES.accountInvitation,
    loadChildren: loadAccountInvitation,
  },
  {
    path: ROUTES.p2p,
    loadChildren: loadP2P,
    canActivate: [AuthGuard, P2PGuard]
  },
  {
    path: ROUTES.unsubscribe,
    loadChildren: loadUnsubscribe
  },
  {
    path: '**',
    redirectTo: ROUTES.home,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
