<ng-container *ngIf="isAuthorized; then authorized; else notAuthorized"></ng-container>

<ng-template #authorized>
  <ng-container *ngIf="accountService.account$ | async as account">
    <div class="shopping">
      <a
        ec-icon-wrapper
        icon="shopping-cart"
        [badge]="account.count_cart"
        badgeSize="small"
        variant="success"
        routerLink="/market/shopping"
      ></a>

      <a
        *ngIf="account.app_status === 'App User'"
        ec-icon-wrapper
        icon="active-bell"
        routerLink="/updates"
        class="ms-3"
        [badge]="account.unseen_updates_count ? 1 : 0"
        badgeSize="xs"
        variant="success"
      ></a>
    </div>
  </ng-container>

  <div class="user">
    <div ngbDropdown>
      <span id="dropdownConfig" ngbDropdownToggle class="user-name">
        {{ fullName | firstName | shortName | titlecase }}
        {{ fullName | lastName }}
        <span
          *ngIf="notCompletedRegistration"
          class="mx-1 mb-1"
          ec-icon-wrapper
          icon="notification"
        ></span>
      </span>
      <div ngbDropdownMenu aria-labelledby="dropdownConfig">
        <div
          ngbDropdownItem
          class="dropdown-item dropdown-item--user"
          routerLink="/profile"
          fragment="contact-info"
        >
          <a>My Profile</a>
        </div>
        <div
          *ngIf="notCompletedRegistration"
          ngbDropdownItem
          class="dropdown-item dropdown-item--user"
          routerLink="/auth/account-verification"
        >
          <a class="text-warning">Continue registration</a>
        </div>
        <div ngbDropdownItem class="dropdown-item dropdown-item--logout" (click)="logout.emit()">
          <div ec-icon-wrapper icon="back"></div>
          <a>Log Out</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #notAuthorized>
  <div class="shopping">
    <a
      ec-icon-wrapper
      icon="shopping-cart"
      badgeSize="small"
      variant="success"
      routerLink="/market/shopping"
    ></a>
  </div>
  <a ec-button color="white" (click)="login.emit()">Log In</a>
  <a ec-button (click)="register.emit()">Register</a>
</ng-template>
