import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsModalComponent } from '@widgets/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ec-complete-registration',
  templateUrl: './complete-registration.modal.html',
  styleUrls: ['./complete-registration.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompleteRegistrationModal extends AbsModalComponent {
  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
  }
}
