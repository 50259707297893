import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CountdownComponent,
  CountdownConfig,
  CountdownEvent,
  CountdownStatus,
} from 'ngx-countdown';

const KEY = 'time';
const DEFAULT = 60 * 5;

@Component({
  selector: 'ec-countdown',
  template: `
    <div class="wrapper">
      <ng-container *ngIf="hideCounter">
        <ng-content></ng-content>
      </ng-container>
      <countdown
        [class.d-none]="hideCounter"
        [config]="config"
        (event)="handleEvent($event)"
      ></countdown>
    </div>
  `,
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCountdownComponent implements OnInit, AfterViewInit {
  @ViewChild(CountdownComponent) countdownComponent!: CountdownComponent;

  config: CountdownConfig = {
    leftTime: DEFAULT,
    notify: 0,
    demand: true,
    format: 'm:ss',
  };

  status!: CountdownStatus;

  timeLeft!: number;

  hideCounter!: boolean;

  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    let value = Number(localStorage.getItem(KEY)) ?? DEFAULT;
    if (value <= 0) {
      value = DEFAULT;
    }
    this.config = { ...this.config, leftTime: value };
  }

  ngAfterViewInit(): void {
    if (this.timeLeft < DEFAULT * 1000) {
      this.countdownComponent?.begin();
    }
  }

  restart(): void {
    this.config = { ...this.config, leftTime: DEFAULT };
    this.countdownComponent.restart();
    setTimeout(() => this.countdownComponent.begin(), 1000);
  }

  handleEvent(ev: CountdownEvent): void {
    this.status = ev.status;
    this.timeLeft = ev.left;
    if (ev.action === 'notify') {
      localStorage.setItem(KEY, `${ev.left / 1000}`);
    }

    this.hideCounter =
      this.status === CountdownStatus.done || this.status === CountdownStatus.pause;
    this.cd.detectChanges();
  }
}
