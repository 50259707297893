import { WithVisibility, WithVisibilityCheck } from '../../interfaces';
import { AuthService } from '@services/auth';
import { IAccount } from '@services/account';
import { PermissionsUtility } from '@utils/permissions';
import { AbsBaseComponent } from './abs-base-component';

abstract class AbsComponentWithVisibilityCheck<ENTITY extends WithVisibility>
  extends AbsBaseComponent
  implements WithVisibilityCheck<ENTITY>
{
  protected constructor(protected authService: AuthService) {
    super();
  }

  public isPublic({ visibility }: Pick<ENTITY, 'visibility'>): boolean {
    return PermissionsUtility.isPublic({ visibility });
  }

  public isRestricted({ visibility }: Pick<ENTITY, 'visibility'>): boolean {
    const userAccreditationStatus: Pick<IAccount, 'accreditation_status'> =
      this.authService.getUserAccreditationStatus();
    const accreditationExpired: boolean =
      this.authService.getAccount().accreditation_expired || false;
    return PermissionsUtility.isRestricted({
      visibility,
      accreditation_status: userAccreditationStatus.accreditation_status,
      accreditation_expired: accreditationExpired,
    });
  }
}

export { AbsComponentWithVisibilityCheck };
