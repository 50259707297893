<page>
  <page-title class="mb-4 mb-xl-5">Overview</page-title>
  <ec-content-section>
    <div
      *ngFor="let section of sections$ | async; trackBy: trackBySection"
      [ngSwitch]="section?.content?.type"
    >
      <div *ngSwitchCase="sectionContentType.recentUpdates">
        <ec-learn-recent-updates [sectionProps]="section"></ec-learn-recent-updates>
        <!--        <div ec-divider color="lgrey"></div>-->
      </div>
      <div *ngSwitchCase="sectionContentType.featuredStories">
        <div class="mt-xl-7 mt-5">
          <ec-learn-featured-stories [sectionProps]="section"></ec-learn-featured-stories>
          <!--          <div ec-divider color="lgrey"></div>-->
        </div>
      </div>
    </div>
  </ec-content-section>

  <div>
    <ec-learn-categories-wrapper></ec-learn-categories-wrapper>
  </div>
</page>
