import { ROUTES } from '@const';
import { BreadcrumbItemsType } from '../types';
import * as contentRoutingKeys from '../../../routing/content/const';
import * as categoryRoutingKeys from '../../../routing/category/const';
import { MenuItemLabel } from '@core/menu';

// const HOME: BreadcrumbItemsType = {
//   [`${ROUTES.home}`]: {
//     label: MenuItemLabel.home,
//     route: `/${ROUTES.home}`,
//   },
// };

const RECENT_UPDATES: BreadcrumbItemsType = {
  [`${ROUTES.recentUpdates}`]: {
    label: MenuItemLabel.recentUpdates,
    route: `/${ROUTES.recentUpdates}`,
  },
};

const LEARN: BreadcrumbItemsType = {
  [`${ROUTES.learn}`]: {
    label: MenuItemLabel.learn,
    route: `/${ROUTES.learn}`,
    // children: HOME[`${ROUTES.home}`],
  },
};

const HOW_IT_WORKS: BreadcrumbItemsType = {
  [`${ROUTES.howItWorks}`]: {
    label: MenuItemLabel.howItWorks,
    route: `/${ROUTES.howItWorks}`,
    // children: HOME[`${ROUTES.home}`],
  },
};

const COMPANY: BreadcrumbItemsType = {
  [`${ROUTES.company}`]: {
    label: MenuItemLabel.home,
    route: `/${ROUTES.home}`,
    // children: HOME[`${ROUTES.home}`],
  },
};

const MARKET: BreadcrumbItemsType = {
  [`${ROUTES.market}`]: {
    label: MenuItemLabel.market,
    route: `/${ROUTES.market}`,
    // children: HOME[`${ROUTES.home}`],
  },
};

const UPDATES: BreadcrumbItemsType = {
  [`${ROUTES.updates}`]: {
    label: MenuItemLabel.updates,
    route: `/${ROUTES.updates}`,
    // children: HOME[`${ROUTES.home}`],
  },
};

const ACCOUNTS: BreadcrumbItemsType = {
  [`${ROUTES.accounts}`]: {
    label: MenuItemLabel.accounts,
    route: `/${ROUTES.accounts}`,
    // children: HOME[`${ROUTES.home}`],
  },
};

const CONTENT_PAGE: BreadcrumbItemsType = {
  [`:${contentRoutingKeys.ROUTING_PARAMS_KEY.contentType}/:${contentRoutingKeys.ROUTING_PARAMS_KEY.contentId}`]:
    {
      label: MenuItemLabel.learn,
      route: `/${ROUTES.learn}`,
    },
};

const RELATED_CONTENT_PAGE: BreadcrumbItemsType = {
  [`:${contentRoutingKeys.ROUTING_PARAMS_KEY.contentType}/:${contentRoutingKeys.ROUTING_PARAMS_KEY.contentId}/${ROUTES.relatedContent}`]:
    {
      label: MenuItemLabel.learn,
      route: `/${ROUTES.learn}`,
    },
};

const CATEGORY_PAGE: BreadcrumbItemsType = {
  [`:${categoryRoutingKeys.ROUTING_PARAMS_KEY.categoryId}`]: {
    label: MenuItemLabel.learn,
    route: `/${ROUTES.categories}`,
  },
};

const CATEGORIES: BreadcrumbItemsType = {
  [`${ROUTES.categories}`]: {
    label: MenuItemLabel.allCategories,
    route: `${ROUTES.learn}/${ROUTES.categories}`,
    children: LEARN[`${ROUTES.learn}`],
  },
};

const P2P_MARKETPLACE: BreadcrumbItemsType = {
  [`${ROUTES.p2p}`]: {
    label: MenuItemLabel.p2p,
    route: `${ROUTES.p2p}`,
    // children: HOME[`${ROUTES.home}`],
  },
};

const P2P_MARKETPLACE_MY_LISTINGS: BreadcrumbItemsType = {
  [`${ROUTES.myListings}`]: {
    label: MenuItemLabel.myListings,
    route: `${ROUTES.p2p}/${ROUTES.myListings}`,
    children: P2P_MARKETPLACE[`${ROUTES.p2p}`],
  },
};

const P2P_MARKETPLACE_ALL_LISTINGS: BreadcrumbItemsType = {
  [`${ROUTES.openListings}`]: {
    label: MenuItemLabel.allListings,
    route: `${ROUTES.p2p}/${ROUTES.openListings}`,
    children: P2P_MARKETPLACE[`${ROUTES.p2p}`],
  },
};

const BREADCRUMBS_ITEMS: BreadcrumbItemsType = {
  // ...HOME,
  ...CATEGORIES,
  ...RECENT_UPDATES,
  ...LEARN,
  ...HOW_IT_WORKS,
  ...COMPANY,
  ...MARKET,
  ...UPDATES,
  ...ACCOUNTS,
  ...CONTENT_PAGE,
  ...CATEGORY_PAGE,
  ...RELATED_CONTENT_PAGE,
  ...P2P_MARKETPLACE_MY_LISTINGS,
  ...P2P_MARKETPLACE_ALL_LISTINGS
};

export { BREADCRUMBS_ITEMS };
