import { Component, Input } from '@angular/core';

@Component({
  selector: 'ec-footer-contact-box',
  templateUrl: './footer-contact-box.component.html',
  styleUrls: ['./footer-contact-box.component.scss'],
})
export class FooterContactBoxComponent {
  @Input() heading!: string;

  @Input() wrapperClass = '';
}
