import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { LockIconDirective } from '@widgets/content/directives';
import { takeUntil } from 'rxjs/operators';
import { AbsBaseComponent } from '@base/abs';

@Component({
  selector: 'div[ec-category-card-overlay]',
  templateUrl: './category-card-overlay.component.html',
  styleUrls: ['./category-card-overlay.component.scss'],
})
export class CategoryCardOverlayComponent extends AbsBaseComponent implements AfterViewInit {
  @Input() isRestricted!: boolean;

  @ViewChild(LockIconDirective) lockIcon!: LockIconDirective;

  @ViewChild('popOverToggle') popOverToggle!: ElementRef<HTMLButtonElement>;

  popOverActive = false;

  ngAfterViewInit(): void {
    this.lockIcon?.hover$.pipe(takeUntil(this.destroyed$)).subscribe((popOverActive: boolean) => {
      if (popOverActive && !this.popOverActive) {
        this.popOverToggle?.nativeElement?.click();
        this.popOverActive = popOverActive;
      }
      if (!popOverActive && this.popOverActive) {
        this.popOverToggle?.nativeElement?.click();
        this.popOverActive = popOverActive;
      }
    });
  }

  handleOnShown(): void {
    this.popOverActive = true;
    this.lockIcon.setValue(true);
  }

  handleOnHidden(): void {
    this.popOverActive = false;
    this.lockIcon.setValue(false);
  }
}
