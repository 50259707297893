import { Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ec-text-shortener]',
})
export class TextShortenerDirective implements OnChanges {
  @Input() lines!: string;

  @Input() maxHeight!: number | string;

  @HostBinding('class') classes = 'ec-text-shortener';

  constructor(private element: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.element.nativeElement.style.webkitLineClamp = this.lines;
      this.element.nativeElement.style.maxHeight = `${this.maxHeight}px`;
    }
  }
}
