import { Component, ViewEncapsulation } from '@angular/core';
import { ResponsiveService } from '@services/responsive';
import { Observable } from 'rxjs';

@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent {
  public isTouch$: Observable<boolean> = this.responsiveService.isTouch$;

  constructor(private readonly responsiveService: ResponsiveService) {}
}
