import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResponsiveService } from '@services/responsive';
import { Observable } from 'rxjs';
import { GetCategoriesDto } from '@services/categories';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ec-categories-view-wrapper',
  templateUrl: './categories-view-wrapper.component.html',
  styleUrls: ['./categories-view-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesViewWrapperComponent {
  hasCustomView$: Observable<boolean> = this.responsiveService.isTouch$;

  constructor(private readonly responsiveService: ResponsiveService) {}

  get categoriesDTO$(): Observable<GetCategoriesDto> {
    return this.hasCustomView$.pipe(
      map((state: boolean) =>
        state
          ? new GetCategoriesDto({ page: 1, page_size: 3 })
          : new GetCategoriesDto({ page: 1, page_size: 5 }),
      ),
    );
  }
}
