import { Pipe, PipeTransform } from '@angular/core';
import { noDataValue } from '@utils/helpers/date.helper';

@Pipe({
  name: 'defaultValue',
})
export class DefaultValuePipe implements PipeTransform {
  transform(value: any, defaultValue = noDataValue): unknown {
    if (value || value === 0) {
      return value;
    }

    return defaultValue;
  }
}
