import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { EPurchaseType } from '../../../../routing/market/interfaces/marketplace-project.interface';

@Component({
  selector: 'ec-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonToggleGroupComponent {
  @Input() selectedType: EPurchaseType;
}
