<div class="box">
  <div class="box-image">
    <a routerLink="/" *ngIf="linkTo === '/'">
      <img src="assets/images/logos/{{ img }}.svg" [alt]="alt" />
    </a>
    <a *ngIf="linkTo !== '/'" [href]="linkTo" target="_blank">
      <img src="assets/images/logos/{{ img }}.svg" [alt]="alt" />
    </a>
  </div>
  <div class="box-text">
    <ng-content></ng-content>
  </div>
</div>
