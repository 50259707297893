import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  hideDropdown$ = new Subject();

  hidePopover$ = new Subject();
}
