import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  private currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  getPreviousUrl(): string {
    return this.previousUrl.value;
  }

  setUrls(url: string) {
    this.previousUrl.next(this.currentUrl.value);
    this.currentUrl.next(url);
  }
}
