import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WithLink } from '@base/interfaces';
import { IContent } from '@services/learn';

@Component({
  selector: 'ec-video-wrapper',
  templateUrl: './video-wrapper.component.html',
  styleUrls: ['./video-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoWrapperComponent implements WithLink {
  @Input() content!: IContent;

  @Input() link!: string;

  getByPassLink(): string {
    if (this.link?.includes('storage')) {
      return `${this.link}&ngsw-bypass=true`;
    }

    return this.link;
  }
}
