<div class="category-overlay" [ngClass]="{ 'category-overlay-restricted': isRestricted }">
  <ec-popover [popoverTemplate]="popoverTemplate" [popoverToggle]="popoverToggle">
    <ng-template #popoverToggle>
      <img
        *ngIf="isRestricted"
        class="icon-lock content-locked"
        src="assets/icons/rounded_lock.svg"
        alt="Restricted"
      />
    </ng-template>

    <ng-template #popoverTemplate>
      <div ec-unlock-content></div>
    </ng-template>
  </ec-popover>

  <ng-content></ng-content>
</div>
