import { ContentVisibility, IContent, IContentExtended } from '@services/learn';
import { ICategoryPreview } from '@services/categories';

class ContentVisibilityUtil {
  static mutateVisibility(content: IContent): IContent | IContentExtended {
    // const firstCategory: ICategoryPreview | undefined =
    //   content.categories.find(Boolean);
    return {
      ...content,
      visibility: content.visibility,
      // firstCategory && this.categoryIsLocked(firstCategory)
      //   ? firstCategory.visibility
      //   : content.visibility,
    };
  }

  static categoryIsLocked(category: ICategoryPreview): boolean {
    return category.visibility === ContentVisibility.accredited;
  }
}

export { ContentVisibilityUtil };
