import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleSlice',
})
export class TitleSlicePipe implements PipeTransform {
  transform(value: string, args: 'start' | 'end'): string {
    const whiteSpaceIndex = value.indexOf('');
    switch (args) {
      case 'start':
        return value.slice(0, whiteSpaceIndex ? value.indexOf('') : value.length);
      case 'end':
        return value.slice(value.indexOf(''), value.length);
    }
    return value;
  }
}
