<div class="modal-wrapper">
  <!-- To prevent the buttons from appearing as preselected -->
  <button class="d-none"></button>

  <div
    ec-modal-header
    [heading]="header"
  ></div>

  <div class="modal-body" [class.m-0]="hideActions" [innerHTML]="text"></div>

  <div *ngIf="!hideActions" class="modal-footer">
    <button
      *ngIf="showCancelButton"
      ec-button
      color="white"
      disableOnRequest
      (click)="activeModal.dismiss()"
    >
      {{ cancelButtonText }}
    </button>
    <button ec-button isLoadingBtn disableOnRequest (click)="activeModal.close(true)">
      {{ okButtonText }}
    </button>
  </div>
</div>
