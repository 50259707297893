import { Pipe, PipeTransform } from '@angular/core';
import { IContent } from '@services/learn';

@Pipe({
  name: 'contentProp',
})
export class ContentPipe implements PipeTransform {
  transform(value: IContent, ...args: Array<keyof IContent>): string {
    const key = args.find(Boolean) as keyof IContent;
    return value[key] as string;
  }
}
