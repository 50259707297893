<div class="social-links">
  <div
    class="social-links-element"
    *ngFor="let element of socialElements$ | async; trackBy: trackSocial"
  >
    <a [href]="socialLink(element.image)" target="_blank">
      <img src="assets/images/social/{{ element.image }}.svg" [alt]="element.image" />
    </a>
  </div>
</div>
