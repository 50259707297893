import { ID } from '@datorama/akita';
import { MenuItemLabel } from '../enums';

interface IMenuItem {
  id: ID;
  label: MenuItemLabel | string;
  route?: string;
  child?: IMenuItem[];
  externalLink?: string;
}

export { IMenuItem };
