import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe, SafeResourceUrlPipe, SafeUrlPipe } from '@widgets/safe/pipes';

@NgModule({
  declarations: [SafeUrlPipe, SafeResourceUrlPipe, SafeHtmlPipe],
  imports: [CommonModule],
  exports: [SafeUrlPipe, SafeResourceUrlPipe, SafeHtmlPipe],
})
export class SafeModule {}
