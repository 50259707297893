import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypographyModule } from '@widgets/typography';
import { AccreditationExpiredModal } from './components/accreditation-expired/accreditation-expired.modal';
import { RouterModule } from '@angular/router';
import { IconsModule } from '../../widgets/icons';
import { NotificationModule } from '../../widgets/notification/notification.module';
import { ButtonsModule } from '../../widgets/buttons';
import { ModalModule } from '../../widgets/modal';

@NgModule({
  declarations: [AccreditationExpiredModal],
  imports: [
    CommonModule,
    TypographyModule,
    RouterModule,
    IconsModule,
    NotificationModule,
    ButtonsModule,
    ModalModule
  ],
  exports: [AccreditationExpiredModal],
})
export class ModalsSharedModule {}
