import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IAuthStepperState } from '../interfaces';
import { RegisterStep } from '../enums';

const createInitialState = (): IAuthStepperState => ({
  currentStep: RegisterStep.emailValidation,
  message: undefined,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth-stepper', resettable: true })
export class AuthStepperStore extends Store<IAuthStepperState> {
  constructor() {
    super(createInitialState());
  }
}
