import { WithRequestResult } from '@base/interfaces';
import { toBoolean } from '@datorama/akita';

abstract class AbsComponentWithRequestResult implements WithRequestResult {
  errorMessage!: string;

  successMessage!: string;

  isSuccess(): boolean {
    return toBoolean(this.successMessage);
  }

  hasError(): boolean {
    return !!this.errorMessage;
  }

  setError(value: string): void {
    this.errorMessage = value;
  }

  setSuccess(value: string): void {
    this.successMessage = value;
  }
}

export { AbsComponentWithRequestResult };
