import { IContentSectionProps, SectionLabel } from '@widgets/content-sections';
import { DEFAULT_SECTION_ACTION_LABEL } from '@base/const';

const loadSectionProps = (
  { label, actionLabel, sectionStyles, content }: IContentSectionProps = {
    label: SectionLabel.default,
    actionLabel: DEFAULT_SECTION_ACTION_LABEL,
    sectionStyles: undefined,
    content: undefined,
  },
): IContentSectionProps => ({
  label,
  actionLabel,
  sectionStyles,
  content,
});

export { loadSectionProps };
