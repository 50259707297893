import { Injectable } from '@angular/core';

import { GetContentDto } from '@services/content';
import { FeaturedValue } from '@services/learn';

type FeaturedResolver = Pick<GetContentDto, 'featured'>;

@Injectable({
  providedIn: 'root',
})
export class FeaturedStoriesResolver  {
  resolve(): FeaturedResolver {
    return { featured: FeaturedValue.featured };
  }
}
