import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ISectionStyles } from '../interfaces';
import { ecCreateLogger } from '@utils/logger';
import { BIG_FONT_SIZE, DEFAULT_SECTION_STYLES } from '../const';
import { ResponsiveService } from '@services/responsive';
import { Observable } from 'rxjs';

type SectionLabelProps = Pick<ISectionStyles, 'fontSize'>;

const log = ecCreateLogger('modules:content-sections::directives');

@Directive({
  selector:
    'p[ec-section-label], h1[ec-section-label], h2[ec-section-label], h3[ec-section-label], h4[ec-section-label]',
})
export class SectionLabelDirective implements OnInit {
  @Input() styles!: SectionLabelProps | null | undefined;

  isMobile$: Observable<boolean> = this.responsiveService.isMobile$;

  logger = { log };

  constructor(
    private element: ElementRef<HTMLElement>,
    private readonly responsiveService: ResponsiveService,
  ) {}

  ngOnInit(): void {
    this.isMobile$.subscribe((state: boolean) => {
      if (this.styles) {
        this.setStyles(this.styles, state);
      }
    });
  }

  private setStyles(props: SectionLabelProps = DEFAULT_SECTION_STYLES, isMobile: boolean) {
    this.logger.log(`${SectionLabelDirective.name} - Received styles - ${JSON.stringify(props)}`);

    this.logger.log(
      `${SectionLabelDirective.name} - Received device - ${isMobile ? 'Mobile' : 'Web'}`,
    );

    if (props) {
      const { fontSize } = props;
      if (fontSize) {
        this.setFontSize({
          fontSize: this.getFontSize(isMobile, { fontSize }),
        });
      }
    }
  }

  private setFontSize({ fontSize }: SectionLabelProps) {
    this.logger.log(`${SectionLabelDirective.name} - Changing font-size to ${fontSize}`);
    this.element.nativeElement.style.fontSize = `${fontSize}px`;
  }

  private getFontSize(isMobile: boolean, { fontSize }: SectionLabelProps): number {
    if (fontSize === BIG_FONT_SIZE && isMobile) {
      return 28;
    }
    return fontSize;
  }
}
