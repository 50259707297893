import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'ec-popover-modal',
  template: `
    <div class="modal-wrapper">
      <div
        ec-modal-header
        customClass="centered small"
        [heading]="windowTitle"
        [hideCloseButton]="false"
      >
        {{ windowTitle }}
      </div>
      <div class="modal-body pb-0 mb-0">
        <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      ec-popover-modal ec-card-table a[ec-card-table-row] {
        padding: 0 15px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PopoverModal {
  @Input() windowTitle: string;

  currentTemplate: TemplateRef<any>;
}
