import { WithContentType } from './with-content-type';

interface WithContentTypeCheck<ENTITY extends WithContentType> {
  isArticle({ type }: Pick<ENTITY, 'type'>): boolean;
  isVideo({ type }: Pick<ENTITY, 'type'>): boolean;
  isPodcast({ type }: Pick<ENTITY, 'type'>): boolean;
  isWebinar({ type }: Pick<ENTITY, 'type'>): boolean;
}

export { WithContentTypeCheck };
