<div class="modal-wrapper">
  <div ec-modal-header></div>
  <div class="modal-body pb-0">
    <ec-success-notification
      heading="Email Verified"
      description="You're all set! Next step is to submit the accreditation form."
    >
      <button ec-button (click)="continue.emit()" type="button">Continue</button>
    </ec-success-notification>
  </div>
</div>
