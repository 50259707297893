import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePage } from './pages';
import { ROUTES } from '@const';
import { loadContent, loadRecentUpdates } from '@loader';
import { buildRoute } from '@utils/routing';

const routes: Routes = [
  buildRoute({
    path: ROUTES.default,
    component: HomePage,
  }),
  buildRoute({
    path: ROUTES.recentUpdates,
    loadChildren: loadRecentUpdates,
    // data: { parent: ROUTES.home },
  }),
  buildRoute({
    path: ROUTES.content,
    loadChildren: loadContent,
    // data: { parent: ROUTES.home },
  }),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
