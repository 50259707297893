import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstNamePipe, LastNamePipe, ShortNamePipe, AccountPipe } from './pipes';
import { AccreditationBadgeComponent } from './components';
import { IconsModule } from '@widgets/icons';
import { ButtonsModule } from '@widgets/buttons';
import { TypographyModule } from '@widgets/typography';

const PIPES = [FirstNamePipe, LastNamePipe, ShortNamePipe, AccountPipe];

@NgModule({
  declarations: [...PIPES, AccreditationBadgeComponent],
  imports: [CommonModule, IconsModule, ButtonsModule, TypographyModule],
  exports: [...PIPES, AccreditationBadgeComponent],
})
export class AccountWModule {}
