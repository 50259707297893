<!--  It's an ugly hack to disable autofocus dialog controls-->
<input type="text" class="d-none" />
<!--**********-->
<h2 class="modal-header--title modal-title" [ngClass]="customClass ?? 'default'">
  <span class="heading">
    {{ heading }}
  </span>
  <span class="sub-heading" *ngIf="subHeading">
    {{ subHeading }}
  </span>
</h2>
<div class="modal-header--close">
  <div
    class="close-modal"
    *ngIf="!hideCloseButton"
    [setSVGAttributes]="{ width: '24px', height: '24px' }"
    inlineSVG="/assets/icons/cross.svg"
    (click)="customClose ? closeClicked.emit() : activeModal.dismiss()"
  ></div>
</div>
