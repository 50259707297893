
import {ROUTES} from '@const';
import {BackendService, BaseApiService, RequestFacadeModel, RequestModel, RequestType} from '@core/backend';
import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';

const CSRF_COOKIE_TOKEN = 'csrftoken';

@Injectable({
  providedIn: 'root'
})
export class CsrfService extends BaseApiService {
  get token(): string {
    return this.cookieService.get(CSRF_COOKIE_TOKEN);
  }

  constructor(
    private cookieService: CookieService,
    public backendService: BackendService,
  ) {
    super(backendService, ROUTES.auth);
  }

  getCsrfToken(): Observable<any> {
    const request: RequestModel<any> = new RequestModel<any>({
      url: this.getFullUrl('csrf'),
      requestBody: {
        code: environment.csrf
      },
      options: {
        withCredentials: true
      }
    });
    const requestFacade: RequestFacadeModel<any> = new RequestFacadeModel<any>({
      requestType: RequestType.post,
      request,
    });
    return this.send<any, any>(requestFacade);
  }
}
