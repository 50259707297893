<div class="unlock-content-overlay">
  <div class="unlock-content-overlay-main">
    <ng-content></ng-content>
    <div class="overlay {{ position }}" *ngIf="isRestricted({ visibility: state.visibility })">
      <div
        ec-unlock-content
        [whiteOverlay]="whiteOverlay"
        [position]="position"
        [overlay]="true"
      ></div>
    </div>
  </div>
</div>
