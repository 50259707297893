import { LayoutService, ILayoutConfiguration } from '@core/layout';
import { Observable, take, timer } from 'rxjs';
import { AbsComponentWithLoading } from './abs-component-with-loading';
import { MenuTriggerState } from '../../../modules/core/menu';

// TODO: pass title service also here
abstract class AbsComponentWithLayoutChange extends AbsComponentWithLoading {
  public triggerState$: Observable<MenuTriggerState> = this.layoutService.triggerState$;

  protected constructor(
    protected layoutService: LayoutService,
    layoutConfiguration: ILayoutConfiguration,
  ) {
    super();
    this.layoutService.setConfiguration(layoutConfiguration);
  }

  setSnippet() {
    timer(100)
      .pipe(take(1))
      .subscribe(() => {
        const appendElem = document.getElementsByClassName('wrapped')[0];
        const snippet = document.getElementById('snippet')?.cloneNode(true) as HTMLDivElement;
        snippet.classList.add('snippet');
        if (!!appendElem && !!snippet) {
          appendElem.appendChild(snippet);
        }
      });
  }
}

export { AbsComponentWithLayoutChange };
