import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon } from '@widgets/icons';
import { AccountService } from '@services/account';
import { LoadingService } from '@core/backend/services/loading.service';

@Component({
  selector: 'page-title',
  template: `
    <div class="d-xl-none d-flex justify-content-between align-items-center mb-4">
      <a ec-link size="md" *ngIf="backUrl" [routerLink]="backUrl">
        <span ec-icon-wrapper icon="arrow-left" size="md"></span>
        Back
      </a>

      <a
        *ngIf="hasHideMoney"
        ec-link
        class="ms-4"
        (click)="toggleMoney()"
        [disabled]="(loadingService.loadingState$ | async) === true"
      >
        <span
          color="blue"
          ec-icon-wrapper
          [icon]="!getShowMoney() ? 'show' : 'hide'"
          size="md"
        ></span>
        {{ !getShowMoney() ? 'Show Money' : 'Hide Money' }}
      </a>
    </div>

    <ng-content select="ec-account-sidebar-projects"></ng-content>

    <div class="wrapper flex-xl-row" [class.flex-column]="showActionsOnTouch">
      <h1 [class.subtitle]="subtitle" class="d-flex">
        <a
          [routerLink]="backUrl"
          *ngIf="backUrl"
          ec-icon-wrapper
          icon="arrow-left"
          size="md"
          class="back-icon d-xl-block d-none"
        ></a>
        <ng-content></ng-content>
      </h1>

      <div
        *ngIf="icon"
        class="icon"
        size="lg"
        touchSize="md"
        (click)="iconClick.emit()"
        ec-icon-wrapper
        [icon]="icon"
      ></div>

      <span class="chip-status {{ badge.replaceAll(' ', '') }}" *ngIf="badge">
        {{ badge }}
      </span>

      <div
        class="d-none d-xl-flex ms-xl-auto ms-0 mt-xl-2 mt-4 align-self-start"
        [class.show-on-touch]="showActionsOnTouch"
        [class.action-block-100-on-touch]="showActionsOnTouch"
      >
        <ng-content select="[actionContent]"></ng-content>

        <a
          *ngIf="hasHideMoney"
          ec-link
          class="ps-4 text-nowrap"
          (click)="toggleMoney()"
          [disabled]="(loadingService.loadingState$ | async) === true"
        >
          <span
            color="blue"
            ec-icon-wrapper
            [icon]="!getShowMoney() ? 'show' : 'hide'"
            size="md"
          ></span>
          {{ !getShowMoney() ? 'Show Money' : 'Hide Money' }}
        </a>
      </div>
    </div>
    <div *ngIf="caption" class="caption d-flex" [class.flex-xl-column]="captionFlexDirectionColumn" [class.has-back]="!!backUrl">
      <div>{{ caption }}</div>
      <ng-content select="page-action"></ng-content>
    </div>
  `,
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent {
  @Input() subtitle = false;

  @Input() badge!: string;

  @Input() caption!: string;

  @Input() backUrl!: string;

  @Input() icon!: Icon;

  @Input() hasHideMoney = false;

  @Input() showActionsOnTouch: boolean;

  @Input() captionFlexDirectionColumn: boolean;

  @Output() iconClick = new EventEmitter();

  constructor(
    private readonly accountService: AccountService,
    public readonly loadingService: LoadingService,
  ) {}

  getShowMoney(): boolean {
    return !!this.accountService.account.show_money;
  }

  toggleMoney(): void {
    this.accountService.updateMoneyVisibility().subscribe(() => {
      location.reload();
    });
  }
}
