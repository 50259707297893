import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerWrapperComponent } from './components';
import { LoaderStyleDirective } from './components/directives/loader-style.directive';

@NgModule({
  declarations: [SpinnerWrapperComponent, LoaderStyleDirective],
  imports: [CommonModule],
  exports: [SpinnerWrapperComponent, LoaderStyleDirective],
})
export class SpinnerModule {}
