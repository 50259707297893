import { Directive, ElementRef } from '@angular/core';
import { ID } from '@datorama/akita';
import { CarouselService } from '../services';

@Directive({
  selector: '.carousel-indicator',
})
export class CarouselIndicatorDirective {
  constructor(
    private readonly element: ElementRef<HTMLButtonElement>,
    private readonly carouselService: CarouselService,
  ) {
    this.element.nativeElement.addEventListener('click', this.handleClick.bind(this));
  }

  private handleClick(): void {
    const slideTo: ID = this.getAttribute();
    this.carouselService.slideTo$.next(slideTo);
  }

  private getAttribute(): ID {
    const attribute = this.element.nativeElement.getAttribute('data-bs-slide-to');
    return attribute || 0;
  }
}
