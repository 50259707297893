import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CategoriesService, GetCategoriesDto, ICategory } from '@services/categories';
import {
  AbsComponentWithCategories,
  BasePaginationDTO,
  WithScroll,
  WithScrollHandler,
  WithSectionProps,
} from '@base/index';
import { IContentSectionProps, SectionLabel } from '../../../content-sections';
import { ID } from '@datorama/akita';
import { ResponsiveService } from '@services/responsive';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ec-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesSectionComponent
  extends AbsComponentWithCategories<BasePaginationDTO>
  implements WithSectionProps, WithScroll, WithScrollHandler, OnChanges
{
  @Input() withVirtualScroll = false;

  @Input() withImage = false;

  @Input() isMain = false;

  @Input() sectionProps: IContentSectionProps = {
    label: SectionLabel.categories,
    actionLabel: 'All Categories',
  };

  @Input() categoriesDto!: GetCategoriesDto;

  public isTouch$: Observable<boolean> = this.responsiveService.isTouch$;

  constructor(
    categoriesService: CategoriesService,
    private readonly responsiveService: ResponsiveService,
  ) {
    super(categoriesService, new BasePaginationDTO(), { showCount: undefined });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoriesDto && changes.categoriesDto.firstChange) {
      this.categoriesDTO$.next(changes.categoriesDto.currentValue);
      this.getData();
    } else if (changes.withVirtualScroll) {
      this.getData();
    }
  }

  sectionProps$(): Observable<IContentSectionProps> {
    return this.isTouch$.pipe(
      map((isTablet: boolean) =>
        isTablet ? { ...this.sectionProps, actionLabel: undefined } : this.sectionProps,
      ),
    );
  }

  handleActionClick(): void {
    this.categoriesService.viewAll();
  }

  handleScroll(): void {
    const dto: BasePaginationDTO = this.categoriesDTO$.getValue();
    const newDtoValue = { ...dto, page: dto.page + 1 };

    this.categoriesDTO$.next(newDtoValue);

    this.handleNextData();
  }

  trackByCategory(index: number, category: ICategory): ID {
    return category.id;
  }
}
