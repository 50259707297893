import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreadcrumbsService, IBreadcrumb } from '@services/breadcrumbs';
import { Observable } from 'rxjs';

@Component({
  selector: 'ec-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  public breadcrumbs$: Observable<IBreadcrumb[]> = this.breadcrumbsService.breadcrumbs$;

  constructor(private readonly breadcrumbsService: BreadcrumbsService) {}
}
