import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownItemComponent } from './components/dropdown-item/dropdown-item.component';
import { IconsModule } from '@widgets/icons';
import { PopoverModal } from './modals/popover/popover.modal';
import { PopoverComponent } from './components/popover/popover.component';
import { ModalModule } from '@widgets/modal';

@NgModule({
  declarations: [DropdownComponent, DropdownItemComponent, PopoverComponent, PopoverModal],
  imports: [CommonModule, NgbDropdownModule, IconsModule, NgbPopoverModule, ModalModule],
  exports: [DropdownComponent, DropdownItemComponent, PopoverComponent, PopoverModal],
})
export class DropdownModule {}
