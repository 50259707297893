import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { MenuService } from '@core/menu/services';
import { MenuItemLabel } from '@core/menu';

@Injectable({
  providedIn: 'root',
})
export class LearnResolver  {
  constructor(private readonly menuService: MenuService) {}

  resolve(): Observable<boolean> {
    this.menuService.setActiveParent(MenuItemLabel.learn);
    return of(true);
  }
}
