import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ColorPalette } from '@core/branding';
import { toBoolean } from '@datorama/akita';

@Component({
  selector: 'a[ec-link]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';

  @Input() touchSize: 'xs' | 'sm' | 'md' | 'lg' = 'md';

  @Input() underlined = true;

  @Input() maxLineClamp!: number;

  @Input() color: ColorPalette = 'primary';

  @Input() cursor: 'pointer' | 'default' = 'pointer';

  @HostBinding('class.disabled') @Input() disabled = false;

  @HostBinding('class') classes = 'ec-link';

  @HostBinding('style.pointer-events') get pEvents(): string {
    if (this.disabled) {
      return 'none';
    }
    return 'auto';
  }

  @HostBinding('style.opacity') get disabledOpacity(): string {
    if (this.disabled) {
      return '0.8';
    }
    return '1';
  }

  get classNames(): string {
    if (this.color) {
      return `${this.classes} ${this.classes}--${this.color}`;
    }
    return this.classes;
  }

  get underlineProp(): boolean {
    return toBoolean(this.underlined);
  }
}
