<div class="layout">
  <div class="impersonate-wrapper" *ngIf="impersonate$ | async as impersonate">
    <div class="impersonate-body">
      <div class="text">
        <span>You are impersonating {{ fullName }}.</span>
        <span class="logout" (click)="logout()">Log Out</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isTouch$ | async; then mobileView; else webView"></ng-container>
  <ng-template #mobileView>
    <div
      ec-mobile-header
      [notCompletedRegistration]="notCompletedRegistration$ | async"
      [menuItems]="menuItems$ | async"
      [isAuthorized]="isAuthorized$ | async"
      [triggerState]="triggerState$ | async"
      (triggerClicked)="handleMenuTriggerClick()"
      (login)="handleLoginClick()"
      (register)="handleRegisterClick()"
      (logout)="handleLogout()"
    ></div>
  </ng-template>
  <ng-template #webView>
    <div
      ec-header
      [notCompletedRegistration]="notCompletedRegistration$ | async"
      [menuItems]="menuItems$ | async"
      [isAuthorized]="isAuthorized$ | async"
      [fullName]="fullName$ | async"
      (login)="handleLoginClick()"
      (register)="handleRegisterClick()"
      (logout)="handleLogout()"
    ></div>
    <div *ngIf="subHeaderActive$ | async" ec-sub-header [items]="subHeaderItems$ | async"></div>
  </ng-template>
  <div
    class="layout-content"
    [class.mt-70]="subHeaderActive$ | async"
    ec-dynamic-background
    [ngClass]="{ 'd-none': menuOpen$ | async }"
  >
    <div
      class="alert alert-permissions accreditation-expiration bg-light-yellow mb-0"
      role="alert"
      *ngIf="(isAuthorized$ | async) && accreditationExpiration && { value: accreditationLink$ | async } as link"
    >
      <div
        ec-icon-wrapper
        icon="document"
        [style.height.px]="24"
        class="d-flex align-items-center"
        size="md"
      ></div>
      <div class="ms-1">
        <ec-text
          size="md"
          touchSize="md"
          color="primary"
          weight="500"
          class="ms-xl-2 ms-1"
        >
          Your accreditation form expiring in {{ expirationDays }} days.
        </ec-text>
        <a [class.disabled]="!link.value" class="ms-1" size="sm" ec-link [underlined]="true" (click)="goToPandaDoc(link.value, 'willExpired')">
          Update Accreditation
        </a>
      </div>
      <div (click)="hideAccreditationExpiry()" ec-icon-wrapper icon="close" class="size-24"></div>
    </div>

    <div
      class="alert alert-permissions accreditation-expiration bg-light-pink mb-0"
      role="alert"
      *ngIf="(isAuthorized$ | async) && accreditationExpired && { value: accreditationLink$ | async } as link"
    >
      <div
        ec-icon-wrapper
        icon="document"
        [style.height.px]="24"
        class="d-flex align-items-center"
        size="md"
      ></div>
      <div class="ms-1">
        <ec-text
          size="md"
          touchSize="md"
          color="primary"
          weight="500"
          class="ms-xl-2 ms-1"
        >
          Accreditation expired. Update your accreditation to maintain full access.
        </ec-text>
        <a [class.disabled]="!link.value" class="ms-1" size="sm" ec-link [underlined]="true" (click)="goToPandaDoc(link.value, 'expired')">
          Update Accreditation
        </a>
      </div>
      <div
        (click)="hideAccreditationExpired()"
        ec-icon-wrapper
        icon="close"
        class="size-24"
      ></div>
    </div>

    <div *ngIf="(account$ | async) as account">
      <div
        class="alert alert-permissions bg-light-yellow mb-0"
        role="alert"
        *ngIf="showMFAWarning && (isAuthorized$ | async) && account.is_login_verified"
      >
        <!--        <div ec-icon-wrapper icon="info"></div>-->
        <div class="alert-permissions-wrapper">
          <p class="ms-0">
            {{
            !hideMFAWarningButton
              ? 'Enable Multi-Factor Authentication to increase security of your account.'
              : 'You can setup Multi-Factor Authentication in Profile Settings.'
            }}
          </p>
          <button
            *ngIf="!hideMFAWarningButton"
            routerLink="/profile/mfa"
            fragment="mfa"
            class="ms-2"
            ec-button
            color="primary"
            size="sm"
          >
            Enable
          </button>
        </div>

        <div
          *ngIf="!hideMFAWarningButton"
          (click)="hideMFAWarningWithExpiry()"
          ec-icon-wrapper
          icon="close"
          class="size-24"
        ></div>
      </div>

      <div
        class="alert alert-permissions bg-yellow mb-0"
        role="alert"
        *ngIf="accountWarningVisibilityWithSession$ | async"
      >
        <div ec-icon-wrapper icon="info"></div>
        <p>
          Your registration process is incomplete.
          <a ec-link [underlined]="true" routerLink="/auth/account-verification">
            Continue registration
          </a>
        </p>
        <div (click)="hideWarning()" ec-icon-wrapper icon="close" class="size-24"></div>
      </div>

      <div [class.layout-content-wrapped]="hasWrapper$ | async">
        <div [ngClass]="{ container: (hasContainer$ | async) && (isTouch$ | async) === false }">
          <ec-breadcrumb *ngIf="hasBreadcrumbs$ | async"></ec-breadcrumb>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="layout-footer" *ngIf="hasFooter$ | async" [ngClass]="{ 'd-none': menuOpen$ | async }">
    <ec-footer></ec-footer>
  </div>
</div>
