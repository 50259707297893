import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import { Icon } from '@widgets/icons';
import { IconColor, IconSize } from '@widgets/icons/components';
import {Observable} from 'rxjs';
import {ResponsiveService} from '@services/responsive';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent} from '@widgets/modal/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'ec-dropdown-item',
  template: `
    <div
      *ngIf="!(isTouch$ | async)"
      [style.pointer-events]="'all'"
      [ngbPopover]="popoverText"
      popoverClass="pop-hint"
      triggers="mouseenter:mouseleave"
    >
      <ng-container
        [ngTemplateOutlet]="dropdownItem"
      ></ng-container>
    </div>

    <div
      *ngIf="isTouch$ | async"
      [style.pointer-events]="'all'"
      (click)="disabled ? handleModal() : null"
    >
      <ng-container
        [ngTemplateOutlet]="dropdownItem"
      ></ng-container>
    </div>

    <ng-template #dropdownItem>
      <button
        [disabled]="disabled"
        type="button"
        ngbDropdownItem
        class="dropdown-button"
        [class.selected]="selected"
        [ngClass]="classNames"
        (click)="clickEmitter.emit()"
      >
        <span
          *ngIf="iconName"
          class="dropdown-icon"
          [ngClass]="iconClassNames"
          ec-icon-wrapper
          [icon]="iconName"
          [color]="iconColor"
          [size]="iconSize"
        >
        </span>
        <span class="dropdown-label">
          <ng-content></ng-content>
        </span>
      </button>
    </ng-template>
  `,
  styleUrls: ['./dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemComponent {
  @HostBinding('class.disabled') @Input() disabled = false;

  @Output() clickEmitter: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Input() iconName!: Icon;

  @Input() classNames!: string[];

  @Input() iconClassNames!: string[];

  @Input() iconColor: IconColor = 'blue';

  @Input() iconSize: IconSize = 'xs';

  @Input() popoverText: string;

  @Input() windowTitle: string;

  @Input() selected: boolean;

  public isTouch$: Observable<boolean> = this.responsiveService.isTouch$;

  constructor(
    private responsiveService: ResponsiveService,
    private modal: NgbModal
  ) {}

  handleModal(): void {
    const modal = this.modal.open(ConfirmModalComponent, {backdrop: true});
    (modal.componentInstance as ConfirmModalComponent).header = this.windowTitle;
    (modal.componentInstance as ConfirmModalComponent).text = this.popoverText;
    (modal.componentInstance as ConfirmModalComponent).hideActions = true;
  }
}
