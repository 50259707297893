import { SectionContentType } from '@widgets/content-sections';
import { ID } from '@core/backend';

interface IContentProps {
  id?: ID;
  type: SectionContentType;
  label: string;
}

export { IContentProps };
