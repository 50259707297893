import UpdatePassword = API.UpdatePassword;

class SetPasswordDto implements UpdatePassword {
  current_password: string;

  new_password: string;

  constructor({ current_password, new_password }: SetPasswordDto) {
    this.current_password = current_password;
    this.new_password = new_password;
  }
}

export { SetPasswordDto };
