import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import { Icon } from '@widgets/icons';

@Component({
  selector: 'ec-success-notification',
  template: `
    <div class="complete-notification" [style.max-width]="containerMaxWidth">
      <div class="icon">
        <div
          class="check-icon color--{{ iconColor }}"
          aria-label="checked-circle"
          ec-icon-wrapper
          [icon]="icon"
        ></div>
      </div>
      <h4 *ngIf="heading">{{ heading }}</h4>
      <div class="description" *ngIf="description">{{ description }}</div>
      <div class="action">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./success-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SuccessNotificationComponent {
  @Input() heading!: string;

  @Input() description!: string;

  @Input() icon: Icon = 'checked-circle';

  @Input() iconColor: 'default' | 'green' = 'green';

  @Input() containerMaxWidth = '430px';
}
