import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from '@widgets/modal/components';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FilterModal } from '@widgets/modal/components/filter/filter.modal';
import { ButtonsModule } from '@widgets/buttons';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [ModalHeaderComponent, FilterModal, ConfirmModalComponent],
  imports: [CommonModule, InlineSVGModule, ButtonsModule],
  exports: [ModalHeaderComponent, FilterModal],
})
export class ModalModule {}
