<div class="layout-header">
  <div class="layout-header-logo">
    <a ec-main-logo href="https://eckardenterprises.com/"></a>
  </div>

  <ng-container *ngIf="isAuthorized; else notAuthorized">
    <ng-container *ngIf="accountService.account$ | async as account">
      <div
        class="layout-header-cart"
        *ngIf="account.app_status === 'App User'"
      >
        <a
          ec-icon-wrapper
          icon="shopping-cart"
          [badge]="account.count_cart"
          badgeSize="small"
          variant="success"
          routerLink="/market/shopping"
          (click)="opened() ? triggerClicked.emit() : null"
        ></a>

        <a
          *ngIf="account.app_status === 'App User'"
          ec-icon-wrapper
          icon="active-bell"
          routerLink="/updates"
          class="ms-3"
          [badge]="account.unseen_updates ? 1 : 0"
          badgeSize="xs"
          variant="success"
        ></a>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #notAuthorized>
    <div class="layout-header-cart">
      <a
        ec-icon-wrapper
        icon="shopping-cart"
        badgeSize="small"
        variant="success"
        routerLink="/landing"
      ></a>
    </div>
  </ng-template>

  <div class="layout-header-menu">
    <div ec-menu-trigger [state]="triggerState" (triggerClicked)="triggerClicked.emit()"></div>
  </div>
</div>

<div class="layout-header-content" *ngIf="opened()">
  <div
    class="layout-header-content-item"
    *ngFor="let menuItem of collapsibleMenuItems; trackBy: trackMenus"
  >
    <div class="layout-header-content-item-content" [ngClass]="{ active: !menuItem.collapsed }">
      <span (click)="handleNavigationClick(menuItem)">
        {{ menuItem.label }}
      </span>
      <div
        (click)="menuItem.collapsed = !menuItem.collapsed"
        *ngIf="menuItem.child"
        ec-icon-wrapper
        [icon]="getIcon(menuItem)"
      ></div>
    </div>
    <div class="layout-header-content-item-sub" *ngIf="!menuItem.collapsed">
      <div
        class="layout-header-content-item-sub-item"
        *ngFor="let child of menuItem.child"
        (click)="handleNavigationClick(child)"
      >
        {{ child.label }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="isAuthorized; then authContent; else noAuthContent"> </ng-container>

  <ng-template #authContent>
    <div class="layout-header-content-item" *ngIf="profileMenuItem">
      <div
        class="layout-header-content-item-content"
        [ngClass]="{ active: !profileMenuItem.collapsed }"
      >
        <span (click)="handleNavigationClick(profileMenuItem)">
          {{ profileMenuItem.label }}
        </span>
        <div
          (click)="profileMenuItem.collapsed = !profileMenuItem.collapsed"
          ec-icon-wrapper
          [icon]="getIcon(profileMenuItem)"
        ></div>
      </div>
      <div class="layout-header-content-item-sub" *ngIf="!profileMenuItem.collapsed">
        <div
          class="layout-header-content-item-sub-item"
          *ngFor="let child of profileMenuItem.child"
          (click)="handleNavigationClick(child)"
        >
          {{ child.label }}
        </div>
      </div>
    </div>

    <div class="layout-header-content-item" *ngIf="notCompletedRegistration">
      <div class="layout-header-content-item-content" routerLink="/auth/account-verification">
        <a class="text-warning">Continue registration</a>
      </div>
    </div>
    <div class="layout-header-content-item">
      <div
        class="layout-header-content-item-content layout-header-content-item-content--logout"
        (click)="handleLogout()"
      >
        <div ec-icon-wrapper icon="back"></div>
        <span>Log Out</span>
      </div>
    </div>
    <div *ngIf="responsiveService.isTouch$ | async" class="menu-snippet"></div>
  </ng-template>

  <ng-template #noAuthContent>
    <div *ngIf="responsiveService.isTouch$ | async" class="menu-snippet"></div>
    <div class="layout-header-content-actions">
      <a ec-button color="white" (click)="login.emit()">Log In</a>
      <a ec-button (click)="register.emit()">Register</a>
    </div>
  </ng-template>

  <div class="layout-header-content-apps">
    <div class="title">Download App</div>
    <div class="apps">
      <ec-ios-app></ec-ios-app>
      <ec-android-app></ec-android-app>
    </div>
  </div>
</div>
