import { IEnvironment } from '@env/environment.interface';

export const environment: IEnvironment = {
  production: false,
  nextSlideDelay: 5 /* s */,
  apiUrl: 'https://api.dev.eckard.bitstudios.dev/api/',
  ws: 'wss://api.dev.eckard.bitstudios.dev/ws/',
  enabledNamespaces: [],
  csrf: '4c74e2fcd4cb049edab854a14fc811ffeaae118fda1398479ef5ed22bc41029f',
  storageEnv: 'eckard-dev',

  plaidEnv: 'sandbox',
  monthlyReportLink: 'https://storage.googleapis.com/eckard-dev-public/placeholders/Monthly_Report_Booklet_Draft.pdf',

  meta: {
    phoneNumber: '(800) 527-8895',
    email: 'app@eckardenterprises.com',
    copyRights: 'Eckard Enterprises LLC 2023 © All Rights Reserved',
  },

  apps: {
    google: 'https://play.google.com/store/apps/details?id=com.eckardenterprises.investments',
    apple: 'https://apps.apple.com/us/app/eckard-insight/id1554729689',
  },

  social: {
    facebook: 'https://facebook.com/eckardenterprises',
    twitter: 'https://twitter.com/eckardnews',
    linkedin: 'https://www.linkedin.com/company/eckard-enterprises',
    youtube: 'https://www.youtube.com/channel/UCI9ymnOaW0I_EjKiLzmWxuA',
  },

  externalLinks: {
    termsOfUse: 'https://eckardenterprises.com/terms-of-use/',
    privacyPolicy: 'https://eckardenterprises.com/privacy-policy/',
    global: 'https://www.eckardglobal.com/',
    land: 'https://www.elaminerals.com/',
  },

  playerConfigs: {
    Article: {
      height: {
        mobile: 200,
        web: 400,
      },
      width: {
        mobile: undefined,
        web: undefined,
      },
    },
    Video: {
      height: {
        mobile: 200,
        web: 580,
      },
      width: {
        mobile: undefined,
        web: 220,
      },
    },
    Podcast: {
      height: {
        mobile: 100,
        web: 190,
      },
      width: {
        mobile: undefined,
        web: undefined,
      },
    },
  },
};
