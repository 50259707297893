import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ID } from '@datorama/akita';

@Injectable({
  providedIn: 'root',
})
export class PopoverContentService {
  private elementsBeh$: BehaviorSubject<ID[]> = new BehaviorSubject<ID[]>([]);

  public elements$: Observable<ID[]> = this.elementsBeh$.asObservable();

  private closeBeh$: BehaviorSubject<ID[]> = new BehaviorSubject<ID[]>([]);

  public close$: Observable<ID[]> = this.closeBeh$.asObservable();

  private activeElementBeh$: BehaviorSubject<ID | null> = new BehaviorSubject<ID | null>(null);

  public activeElement$: Observable<ID | null> = this.activeElementBeh$.asObservable();

  public addElement(id: ID): void {
    const currentElements: ID[] = this.elementsBeh$.getValue();
    this.elementsBeh$.next([...currentElements, id]);
  }

  public setActive(id: ID) {
    this.activeElementBeh$.next(id);
    const currentElements: ID[] = this.elementsBeh$.getValue();
    const elementsToClose: ID[] = currentElements.filter(elementId => elementId !== id);
    this.closeBeh$.next(elementsToClose);
  }
}
