import { BaseEmailDto } from './base-email.dto';
import { PhoneFormat } from '../enums';

class RegisterFormDto extends BaseEmailDto implements API.SignUp {
  /**
   * User for data transformation into first_name and last_name
   */
  full_name: string;

  first_name: string;

  middle_name: string | undefined;

  last_name: string;

  password: string;

  primary_phone: string;

  primary_phone_format: PhoneFormat;

  is_accredited: boolean;

  is_licensed: boolean;

  is_agree_terms_and_use: boolean;

  constructor({
    email,
    full_name,
    first_name,
    middle_name,
    last_name,
    password,
    primary_phone,
    is_accredited,
    is_licensed,
    is_agree_terms_and_use,
  }: RegisterFormDto) {
    super({ email });
    this.full_name = full_name;
    this.first_name = first_name;
    this.middle_name = middle_name;
    this.last_name = last_name;
    this.password = password;
    this.primary_phone = primary_phone;
    this.primary_phone_format = PhoneFormat.undef;
    this.is_accredited = is_accredited;
    this.is_licensed = is_licensed;
    this.is_agree_terms_and_use = is_agree_terms_and_use;
  }
}

export { RegisterFormDto };
