import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AccountService, AccreditationStatus } from '@services/account';
import { VerificationsSentModal } from '../../../../routing/auth/modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { AbsBaseComponent } from '@base/abs';
import { AuthService } from '../../../../services/auth';
import { LandingService } from '../../../../routing/landing/services/landing.service';

@Component({
  selector: 'ec-accreditation-badge',
  template: `
    <div>
      <div [class.is-pending]="inactiveStatus()" class="wrapper">
        <div
          ec-icon-wrapper
          [icon]="accreditationExpired ? 'cross-circle-red' : 'verified'"
          size="lg"
          [color]="
            inactiveStatus()
              ? 'blue'
              : accreditationStatus === 'Not Accredited'
              ? 'gray'
              : accreditationExpired
              ? 'base-red'
              : null
          "
          class="me-2"
        ></div>
        <ec-text
          size="lg"
          touchSize="lg"
          [color]="accreditationStatus === 'Not Accredited' ? 'gray' : 'primary'"
          weight="600"
          class="text-capitalize"
        >
          {{ accreditationExpired ? 'Accreditation Expired' : accreditationStatus }}
        </ec-text>
      </div>
      <div *ngIf="!accreditationExpired">
        <div class="mt-2">
          <ec-text
            *ngIf="!!signingDate"
            size="md"
            touchSize="md"
            color="gray"
            weight="500"
            class="text-capitalize"
          >
            Signing Date:
          </ec-text>
          <ec-text
            size="md"
            touchSize="md"
            color="primary"
            weight="600"
            class="ms-2 text-capitalize"
          >
            {{ signingDate | date }}
          </ec-text>
        </div>
        <div *ngIf="!!expirationDate" class="mt-2">
          <ec-text size="md" touchSize="md" color="gray" weight="500" class="text-capitalize">
            Expiration Date:
          </ec-text>
          <ec-text
            size="md"
            touchSize="md"
            color="primary"
            weight="600"
            class="ms-2 text-capitalize"
          >
            {{ expirationDate | date }}
          </ec-text>
        </div>
        <div></div>
      </div>
      <ng-container *ngIf="accreditationLink$ | async as link">
        <button
          ec-button
          color="white"
          class="mt-3"
          *ngIf="accreditationExpired && !action().visibility"
          [disabled]="!link"
          (click)="goToPandaDoc(link)"
        >
          Submit Accreditation Form
        </button>
        <button
          ec-button
          color="white"
          class="mt-3"
          *ngIf="action().visibility"
          (click)="handleAction(link)"
        >
          {{ action().label }}
        </button>
      </ng-container>
    </div>
  `,
  styleUrls: ['./accreditation-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccreditationBadgeComponent extends AbsBaseComponent implements OnInit {
  @Input() accreditationStatus!: AccreditationStatus;

  @Input() accreditationExpired!: boolean;

  @Input() signingDate: string;

  @Input() expirationDate: string;

  accreditationLink$ = this.landingService.getAccreditationLink$();

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private landingService: LandingService,
  ) {
    super();
  }

  ngOnInit() {
    if (
      this.accountService.account.accreditation_status === AccreditationStatus.form_pending ||
      this.accountService.account.accreditation_expired
    ) {
      this.authService.isAuthorized$.pipe(take(1)).subscribe(isAuthorized => {
        if (isAuthorized) {
          this.landingService.fetchAccreditationLink();
        }
      });
    }
  }

  goToPandaDoc(link: string | null | undefined): void {
    if (link) {
      window.open(link, '_blank');
    }
  }

  resendEmail(): void {
    this.accountService.resendVerification().subscribe(() =>
      this.modalService.open(VerificationsSentModal, {
        windowClass: 'modal-small',
        backdrop: true,
      }),
    );
  }

  action(): { visibility: boolean; label?: string } {
    const action = {
      label: '',
      visibility: true,
    };
    switch (this.accreditationStatus) {
      case AccreditationStatus.pending_email:
        action.label = 'Resend Verification Email';
        action.visibility = true;
        break;
      case AccreditationStatus.form_pending:
        action.label = 'Submit Accreditation Form';
        action.visibility = true;
        break;
      default:
        action.visibility = false;
        break;
    }
    return action;
  }

  inactiveStatus(): boolean {
    const inactiveStatuses = [
      AccreditationStatus.pending_email,
      AccreditationStatus.pending,
      AccreditationStatus.form_pending,
    ];
    return inactiveStatuses.includes(this.accreditationStatus);
  }

  handleAction(link: string) {
    if (this.accreditationStatus === AccreditationStatus.pending_email) {
      this.resendEmail();
    } else if (this.accreditationStatus === AccreditationStatus.form_pending) {
      window.open(link, '_blank');
    }
  }
}
