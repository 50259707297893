import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { IContentLocal, IContentState } from './content-state.interface';
import { IContent } from '@services/learn';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'content', resettable: true })
export class ContentStore extends EntityStore<IContentState> {
  constructor() {
    super();
  }

  public readContent({ id }: Partial<IContent>): void {
    this.setActive(id);
    this.updateActive((content: IContentLocal) => ({ ...content, seen: true }));
  }
}
