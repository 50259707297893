import { BasePaginationDTO } from '@base/index';

class GetCategoriesDto extends BasePaginationDTO {
  search?: string;

  constructor({ search, page, page_size }: GetCategoriesDto) {
    super({ page, page_size });
    this.search = search;
  }
}

export { GetCategoriesDto };
