import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { BackendConfig } from './config';

@NgModule({ declarations: [],
    exports: [], imports: [], providers: [provideHttpClient(withInterceptorsFromDi(), withXsrfConfiguration({
            cookieName: 'csrftoken',
            headerName: 'X-CSRFTOKEN'
        }))] })
export class BackendModule {
  static forRoot(config: BackendConfig): ModuleWithProviders<BackendModule> {
    return {
      ngModule: BackendModule,
      providers: [{ provide: BackendConfig, useValue: config }],
    };
  }
}
