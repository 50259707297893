import { ID } from '@datorama/akita';
import { WithName } from '@base/interfaces/with-name';

interface WithID {
  id: ID;
}

interface IBase extends WithID, WithName {}

export { WithID, IBase };
