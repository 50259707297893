import { Component, ChangeDetectionStrategy, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorPalette } from '@core/branding';

@Component({
  selector: 'button-group',
  template: `
    <div class="btn-group" role="group" aria-label="Basic example">
      <button
        [class.active]="value === label"
        type="button"
        class="btn btn-primary"
        *ngFor="let label of labels"
        (click)="onModelChange(label)"
      >
        {{ label }}
      </button>
    </div>

    <!--    <div-->
    <!--      class="btn-group btn-group-toggle"-->
    <!--      ngbRadioGroup-->
    <!--      name="radioBasic"-->
    <!--      [ngModel]="value"-->
    <!--      (ngModelChange)="onModelChange($event)"-->
    <!--    >-->
    <!--      <label ngbButtonLabel class="btn-primary" *ngFor="let label of labels">-->
    <!--        <input ngbButton type="radio" [value]="label" /> {{ label }}-->
    <!--      </label>-->
    <!--    </div>-->
  `,
  styleUrls: ['./button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonGroupComponent),
      multi: true,
    },
  ],
})
export class ButtonGroupComponent implements ControlValueAccessor {
  @Input() labels!: string[];

  @Input() color!: ColorPalette;

  value!: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }

  onModelChange(value: string) {
    this.value = value;

    this.onChange(value);
  }
}
