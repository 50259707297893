import { ID } from '@datorama/akita';

class AddDeviceDto {
  registration_id: ID;

  constructor({ registration_id }: AddDeviceDto) {
    this.registration_id = registration_id;
  }
}

export { AddDeviceDto };
