import { Injectable } from '@angular/core';

import { GetContentDto } from '@services/content';

type RecentResolver = Pick<GetContentDto, 'most_recent'>;

@Injectable({
  providedIn: 'root',
})
export class RecentUpdatesResolver  {
  resolve(): RecentResolver {
    return { most_recent: true };
  }
}
