/* eslint-disable @typescript-eslint/member-ordering */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItems } from '../types';
import { MENU_ITEMS } from '../const';
import { IMenuItem } from '@core/menu';
import { MenuItemLabel, MenuTriggerState } from '../enums';

@Injectable()
export class MenuService {
  private menuItemsBeh$: BehaviorSubject<MenuItems> = new BehaviorSubject<MenuItems>(MENU_ITEMS);

  public menuItems$: Observable<MenuItems> = this.menuItemsBeh$.asObservable();

  private subHeaderItemsBeh$: BehaviorSubject<MenuItems> = new BehaviorSubject<MenuItems>([]);

  public subHeaderItems$: Observable<MenuItems> = this.subHeaderItemsBeh$.asObservable();

  public subHeaderActiveBeh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public subHeaderActive$: Observable<boolean> = this.subHeaderActiveBeh$.asObservable();

  private triggerStateBeh$: BehaviorSubject<MenuTriggerState> =
    new BehaviorSubject<MenuTriggerState>(MenuTriggerState.closed);

  public triggerState$: Observable<MenuTriggerState> = this.triggerStateBeh$.asObservable();

  public handleMenuTriggerClick(): void {
    const currentState: MenuTriggerState = this.triggerStateBeh$.getValue();
    switch (currentState) {
      case MenuTriggerState.closed:
        this.triggerStateBeh$.next(MenuTriggerState.opened);
        break;
      case MenuTriggerState.opened:
        this.triggerStateBeh$.next(MenuTriggerState.closed);
        break;
    }
  }

  public setActiveParent(label: MenuItemLabel): void {
    const menuItems: MenuItems = this.menuItemsBeh$.getValue();
    const activeItem = menuItems.find((menuItem: IMenuItem) => menuItem.label === label);

    this.subHeaderItemsBeh$.next(activeItem?.child ?? []);
    this.setSubheaderState(true);
  }

  public setHeaderState(state: boolean): void {
    this.setSubheaderState(state);
  }

  private setSubheaderState(state: boolean): void {
    this.subHeaderActiveBeh$.next(state);
  }
}
