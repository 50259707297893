import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownModule } from 'ngx-countdown';

import { CustomCountdownComponent } from './components/countdown/countdown.component';

@NgModule({
  declarations: [CustomCountdownComponent],
  imports: [CommonModule, CountdownModule],
  exports: [CustomCountdownComponent],
})
export class TimerModule {}
