const ROUTES = {
  login: 'login',
  register: 'register',
  logout: 'logout',
  mfaVerification: 'mfa',
  resetMfa: 'reset-mfa',
  accountVerification: 'account-verification',
  verificationSent: 'verification-sent',
  forgotPassword: 'forgot-password',
  changePassword: 'change-password',
  customMessage: 'custom-message',
  approvalScreen: 'approval-screen',
  impersonate: 'impersonate',
};

export { ROUTES };
