import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ec-page-field',
  template: `
    <div class="field-wrapper touch-{{ touchPosition }}">
      <div class="field-label">
        {{ label }}
      </div>
      <div class="field-value">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./page-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFieldComponent {
  @Input() label!: string;

  @Input() touchPosition: 'column' | 'row' = 'column';
}
