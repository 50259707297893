import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[loader-styles]',
})
export class LoaderStyleDirective implements OnChanges {
  @Input() loadingState!: boolean;

  constructor(private readonly element: ElementRef<HTMLDivElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadingState && !changes.loadingState.currentValue) {
      this.element.nativeElement.classList.add('d-none');
    }
  }
}
