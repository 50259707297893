import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'statistic-card',
  template: `
    <div
      class="wrapper"
      [ngClass]="{
        'is-active': isActive,
        'padding-full': paddingFull
      }"
    >
      <div class="row">
        <ng-content></ng-content>
      </div>
    </div>

    <ng-content select="[expansionPanel]"></ng-content>

    <div *ngIf="detailsDropdown" class="row details">
      <div class="col">
        <div class="d-flex justify-content-center pointer" (click)="dropdownStateChange()">
          <a ec-link>
            {{ isDropdownOpen ? 'Hide' : 'Show' }} Details
          </a>
          <span
            class="ms-1"
            ec-icon-wrapper
            color="blue"
            [icon]="isDropdownOpen ? 'arrow-up_active' : 'arrow-down'"
          ></span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./statistic-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticCardComponent {
  @Input() isActive!: boolean;

  @Input() paddingFull: boolean;

  @Input() detailsDropdown: boolean;

  @Output() dropdownChangesEmit: EventEmitter<void> = new EventEmitter<void>();

  isDropdownOpen = false;

  public dropdownStateChange(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
    this.dropdownChangesEmit.emit();
  }
}
