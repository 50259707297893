import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbsComponentWithLearnSection } from '../abs-learn-section';
import { FeaturedValue, LearnLogicService } from '@services/learn';
import { IContentSectionProps, SectionContentType } from '@widgets/content-sections';
import { WithSectionPropsWithoutAction } from '@base/interfaces';
import { LEARN_SECTION_TEMPLATE } from '../../const';

@Component({
  selector: 'ec-learn-featured-stories',
  template: LEARN_SECTION_TEMPLATE,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LearnFeaturedStoriesComponent
  extends AbsComponentWithLearnSection
  implements WithSectionPropsWithoutAction
{
  @Input() sectionProps!: IContentSectionProps;

  constructor(learnLogicService: LearnLogicService) {
    super(learnLogicService, SectionContentType.featuredStories, {
      featured: FeaturedValue.featured,
    });
  }
}
