import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth';

@Injectable({
  providedIn: 'root',
})
export class OnlyUnauthorizedGuard  {
  constructor(private authService: AuthService, private readonly router: Router) {}

  canActivate(): // route: ActivatedRouteSnapshot,
  // state: RouterStateSnapshot

  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateAccess();
  }

  canActivateChild(): // component: unknown,
  // currentRoute: ActivatedRouteSnapshot,
  // currentState: RouterStateSnapshot,
  // nextState?: RouterStateSnapshot

  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateAccess();
  }

  canLoad(): // route: Route,
  // segments: UrlSegment[]

  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.validateAccess();
  }

  private validateAccess(): boolean {
    const { access } = this.authService.getAccess();
    if (!access) {
      this.router.navigate(['/']).then();
    }
    return !access;
  }
}
