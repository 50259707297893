import * as moment from 'moment';

export const noDataValue = '—';

export const systemDateFormat = 'MM-DD-YYYY';
export const dtoDateFormat = 'YYYY-MM-DD';

export const MONTH = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const MONTH_FULL = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export class DateConverter {
  static standard(date: string | undefined) {
    if (!date) {
      return '';
    }
    return moment(date, dtoDateFormat).format(systemDateFormat);
  }
}

export const fromDtoDateHelper = (date: string, format = dtoDateFormat) => {
  if (!date) {
    return '';
  }

  if (moment(date, systemDateFormat, true).isValid()) {
    return date;
  }

  return moment(date, format).format(systemDateFormat);
};

export const toDtoDateHelper = (date: string, format = systemDateFormat) => {
  if (!date || date === 'null') {
    return '';
  }
  if (moment(date, dtoDateFormat, true).isValid()) {
    return date;
  }

  return moment(date, format).format(dtoDateFormat);
};
