import { WithName, WithUrl } from '@base/interfaces';

interface ISliderButton extends WithName, WithUrl {
  link_to: LinkToE;
}

export { ISliderButton };

export enum LinkToE {
  SIGN_UP_FORM = 'Sign up form',
  LOG_IN_FORM = 'Log in form',
  MEDIA_CATEGORY = 'Media category',
  MEDIA = 'Media',
  URL = 'Url',
}
