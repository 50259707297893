import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Accepts Observable, and allows us to execute function inside pipe-tap on success and error. Used With loadingState mostly.
 *
 * @template T
 * @param fn
 */
const loadingStatePipe =
  <T>(fn: () => void) =>
  (source: Observable<T>) =>
    source?.pipe(
      tap({
        next: () => fn(),
        error: () => fn(),
      }),
    );

export { loadingStatePipe };
