<ec-content-section
  [props]="$any(sectionProps$() | async)"
  (actionClick)="handleActionClick()"
  [isMain]="isMain"
>
  <div *ngIf="withVirtualScroll; then withVirtualScrollContent; else noVirtualScrollContent"></div>
</ec-content-section>

<ng-template #noVirtualScrollContent>
  <ec-spinner-wrapper [loadingState]="loadingState"></ec-spinner-wrapper>
  <div class="categories-section">
    <div
      class="categories-section-element"
      *ngFor="let category of categories$ | async; trackBy: trackByCategory"
    >
      <div ec-card view="minimal" class="h-100">
        <div ec-category-card-content [category]="category" [withImage]="withImage"></div>
      </div>
    </div>
    <div class="categories-section-element" *ngIf="isTouch$ | async">
      <ec-view-all-card (clicked)="handleActionClick()"></ec-view-all-card>
    </div>
  </div>
</ng-template>

<ng-template #withVirtualScrollContent>
  <div
    class="mt-3"
    infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="50"
    (scrolled)="handleScroll()"
  >
    <div class="categories-section--virtual">
      <div
        class="categories-section--virtual-element"
        *ngFor="let category of categories$ | async; trackBy: trackByCategory"
      >
        <div ec-card view="minimal" [withImage]="withImage" class="h-100">
          <div ec-category-card-content [category]="category" [withImage]="withImage"></div>
        </div>
      </div>
    </div>
  </div>

  <ec-spinner-wrapper [loadingState]="loadingState"></ec-spinner-wrapper>
</ng-template>
