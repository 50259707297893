import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DividerColor } from '@widgets/dividers/types';
import { Sizes } from '@core/branding';

@Component({
  selector: 'div[ec-divider]',
  template: ' <div [ngClass]="colorClass"></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent {
  @Input() color: DividerColor = 'primary';

  @Input() size: Sizes = 'md';

  @HostBinding('class') classes = 'ec-divider';

  get colorClass(): string {
    return `${this.classes}--${this.color} ${this.classes}--${this.size}`;
  }
}
